import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'twin.macro';
import SilverRecord from 'static/images/records/DTC/silver-small.png'
import GoldRecord from 'static/images/records/DTC/gold-small.png'
import PlatinumRecord from 'static/images/records/DTC/platinum-small.png'

const S = {
    BorderGradient: css`
        width: 40px;
        border: 2px solid;
        border-image-slice: 1;
        border-width: 2px;
        border-image-source: linear-gradient(91.31deg, #B15AFE 0.89%, #5dc2ef 98.12%);
        padding: 0px 4px;
        background-color: #281757;
        max-height: 629px;

        @media only screen and (max-width: 1200px) {
            max-height: 520px;
        }
        @media only screen and (max-width: 1000px) {
            max-height: 400px;
        }
    `,
    HeightCss: css`
        max-height: 629px;

        @media only screen and (max-width: 1200px) {
            max-height: 520px;
        }
        @media only screen and (max-width: 1000px) {
            max-height: 400px;
        }
        @media only screen and (max-height: 740px) {
            max-height: 460px;
        }
    `,
    BorderGradientSmall: css`
        max-width: 82px;
        border-left: 2px solid #B15AFE;
        border-right: 2px solid #5dc2ef;
        background-image: linear-gradient(90deg, #B15AFE, #5dc2ef), linear-gradient(90deg, #B15AFE, #5dc2ef);
        background-size: 100% 2px;
        background-position: 0 0, 0 100%;
        background-repeat: no-repeat;
        border-radius: 5px;
        left: -4rem;
    `
};

const VideoPlayerProgress = ({ quiz_level_goal, quiz_score_goal, stats, ParentBorderGradient, ParentHeightCss }) => {
    let silverScore = stats?.quiz_levels[1]?.score ?? 0;
    let goldScore = stats?.quiz_levels[2]?.score ?? 0;
    let platScore = stats?.quiz_levels[3]?.score ?? 0;
    let goal100 = quiz_score_goal === 100;
    // TODO
    // refactor the goal box and line to be one component to be reused over the individual levels silver/gold/plat
    // passing down custom parent style props to make this work with the new quizzing intro and finished design
    return (
        <div css={[ParentBorderGradient ? ParentBorderGradient : S.BorderGradient]}>
            <div tw='flex-col py-2 bg-white bg-opacity-0 h-screen relative font-sans' css={[ParentHeightCss ? ParentHeightCss : S.HeightCss]}>
                <div tw=' h-1/3 flex items-end justify-center bg-DarkPurple relative'>
                    <div
                        tw='w-full'
                        style={{
                            background: 'linear-gradient(90deg, #46A6F8 0%, #B15AFE 100%)',
                            height: `${platScore}%`
                        }}
                    />
                    <div tw='max-h-6 w-6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>
                        <img src={PlatinumRecord} alt={`Platinum ${platScore}`} />
                    </div>
                    {/* because of positioning i created individual goal markers for each level so their parent could be what they were being relative too */}
                    {quiz_level_goal === 3 && (
                        <>
                            <div
                                tw='absolute bg-white bg-opacity-0 font-black text-center'
                                css={[S.BorderGradientSmall]}
                                style={{ bottom: `${quiz_score_goal - 20}%` }}
                            >
                                <div tw='p-2' style={{ backgroundColor: 'rgba(42, 38, 77, 0.6)', borderRadius: '10px', margin: '2px 0' }}>
                                    <div tw='text-white text-sm'>Goal</div>
                                    <div tw=' text-white' style={goal100 ? { fontSize: '.8rem' } : { fontSize: '1.125rem' }}>
                                        {quiz_score_goal}%
                                    </div>
                                    <div tw='h-6 w-6 ml-1'>
                                        <img src={PlatinumRecord} alt={`Level ${quiz_level_goal}`} />
                                    </div>
                                </div>
                            </div>
                            <div
                                tw='bg-[#5dc2ef] absolute w-8 h-[3px]'
                                style={{ width: '38px', left: '-4px', bottom: `${quiz_score_goal}%` }}
                            />
                        </>
                    )}
                </div>
                <div tw='h-1/3 flex items-end justify-center bg-DarkPurple mt-2 relative'>
                    <div
                        tw='w-full'
                        style={{
                            background: 'linear-gradient(270deg, #ECB83D 0%, #866923 100%)',
                            height: `${goldScore}%`
                        }}
                    />
                    <div tw='max-h-6 w-6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <img src={GoldRecord} alt={`Gold ${goldScore}`} />
                    </div>
                    {/* because of positioning i created individual goal markers for each level so their parent could be what they where being relative too */}
                    {quiz_level_goal === 2 && (
                        <>
                            <div
                                tw='absolute bg-white bg-opacity-0 font-black text-center'
                                css={[S.BorderGradientSmall]}
                                style={{ bottom: `${quiz_score_goal - 20}%` }}
                            >
                                <div tw='p-2' style={{ backgroundColor: 'rgba(42, 38, 77, 0.6)', borderRadius: '10px', margin: '2px 0' }}>
                                    <div tw='text-white text-sm'>Goal</div>
                                    <div tw=' text-white' style={goal100 ? { fontSize: '.8rem' } : { fontSize: '1.125rem' }}>
                                        {quiz_score_goal}%
                                    </div>
                                    <div tw='h-6 w-6 ml-1'>
                                        <img src={GoldRecord} alt={`Level ${quiz_level_goal}`} />
                                    </div>
                                </div>
                            </div>
                            <div
                                tw='bg-[#5dc2ef] absolute w-8 h-[3px]'
                                style={{ width: '38px', left: '-4px', bottom: `${quiz_score_goal + 1.25}%` }}
                            />
                        </>
                    )}
                </div>
                <div tw='flex items-end justify-center bg-DarkPurple mt-2 relative' style={{ height: '30.33%' }}>
                    <div
                        tw='w-full'
                        style={{
                            background: 'linear-gradient(270deg, #C0C4C8 0%, #5E6062 100%)',
                            height: `${silverScore}%`
                        }}
                    />
                    <div tw='max-h-6 w-6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                        <img src={SilverRecord} alt={`Silver ${silverScore}`} />
                    </div>
                    {/* because of positioning i created individual goal markers for each level so their parent could be what they where being relative too */}
                    {quiz_level_goal === 1 && (
                        <>
                            <div
                                tw='absolute bg-white bg-opacity-0 font-black text-center'
                                css={[S.BorderGradientSmall]}
                                style={{ bottom: `${quiz_score_goal - 20}%` }}
                            >
                                <div tw='p-2' style={{ backgroundColor: 'rgba(42, 38, 77, 0.6)', borderRadius: '10px', margin: '2px 0' }}>
                                    <div tw='text-white text-sm'>Goal</div>
                                    <div tw=' text-white' style={goal100 ? { fontSize: '.8rem' } : { fontSize: '1.125rem' }}>
                                        {quiz_score_goal}%
                                    </div>
                                    <div tw='h-6 w-6 ml-1'>
                                        <img src={SilverRecord} alt={`Level ${quiz_level_goal}`} />
                                    </div>
                                </div>
                            </div>
                            <div
                                tw='bg-[#5dc2ef] absolute w-8 h-[3px]'
                                style={{ width: '38px', left: '-4px', bottom: `${quiz_score_goal}%` }}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

VideoPlayerProgress.propTypes = {
    stats: PropTypes.object,
    quiz_level_goal: PropTypes.number,
    quiz_score_goal: PropTypes.number
};

export default VideoPlayerProgress;
