import React from 'react';
import tw, { css } from 'twin.macro';
import { useOnboardState, useProduct } from 'state/User/UserState';
import { useAccount } from 'state/User/UserState';
const TrailBannerContainerWrapper = ({ children }) => {
    const onboard = useOnboardState();
    const account = useAccount();
    const { show_trial_banner } = onboard;
    const timesTableProduct = useProduct('times-tables');

    let style = tw``;
    if (!timesTableProduct) style = tw`mt-[100px] `;
    if (!show_trial_banner) style = tw``;
    if (account.trial_days_remaining <= 0) style = tw``
    return (
        <div tw='h-full' css={[timesTableProduct ? tw`` : style]}>
            {children}
        </div>
    );
};

export default TrailBannerContainerWrapper;