import React from 'react';
import tw, { styled, css } from 'twin.macro';
import RecordIndicator from 'components/RecordIndicator';
import { useNavigate } from 'react-router-dom';
import { Button, CompletedAssignments } from 'components'

import BalanceText from 'react-balance-text';
import { level_titles } from 'app/constants/constants';

const CardContainer = styled.div(({ $level }) => [
    tw`p-4 cursor-pointer flex flex-col rounded-lg relative overflow-hidden`,
    $level === 1 && tw`hover:[box-shadow:0 0 10px 10px #ffffff22] active:[box-shadow:0 0 10px 10px #ffffff44]`,
    $level === 2 && tw`hover:[box-shadow:0 0 10px 10px #F2B845] active:[box-shadow:0 0 10px 10px #F2B845]`,
    $level === 3 && tw`hover:[box-shadow:0 0 10px 10px #24A6D3] active:[box-shadow:0 0 10px 10px #24A6D3]`,
    $level === 'complete' && tw`cursor-auto`
]);

const quizLevel = ['', 'Silver', 'Gold', 'Platinum']

const ActiveAssignmentContents = ({ level, handleClick, quizLevel, buttonText, assignmentLengthVerb, assignment, goalVerb, verb, video }) => {
    return (
        <>
            <CardContainer
                tw='hidden xl:flex pl-6 p-5 justify-start h-[320px] w-[550px] xl:w-[68%]'
                $level={level}
                css={[
                    css`background: linear-gradient(315deg, #2E066A 0%, #4D40C2 68.91%);`
                ]}
                onClick={handleClick}
            >
                <div tw='absolute bg-gradient-to-r from-[#1CC8E1] to-[#887BE3] h-[68px] w-[68px] flex items-center justify-center top-[32px] right-[40px] z-20'>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 53' width='47' height='53' fill='none'>
                        <path
                            fill='#fff' fillRule='evenodd'
                            d='M0 7.543v19.373c0 .86.712 1.556 1.59 1.556.877 0 1.589-.697 1.589-1.556V7.543c0-.86-.712-1.557-1.59-1.557C.712 5.986 0 6.683 0 7.543ZM8.812 0c-.878 0-1.59.697-1.59 1.557V34.94c0 .86.712 1.557 1.59 1.557.878 0 1.59-.697 1.59-1.557V1.557C10.402.697 9.69 0 8.812 0ZM14.126 9.856v33.383c0 .86.711 1.557 1.589 1.557s1.59-.697 1.59-1.557V9.856c0-.86-.712-1.557-1.59-1.557-.878 0-1.59.697-1.59 1.557ZM43.439 7.543v19.373c0 .86.711 1.556 1.59 1.556.877 0 1.588-.697 1.588-1.556V7.543c0-.86-.711-1.557-1.589-1.557s-1.59.697-1.59 1.557ZM37.785 0c-.878 0-1.59.697-1.59 1.557V34.94c0 .86.712 1.557 1.59 1.557.878 0 1.589-.697 1.589-1.557V1.557c0-.86-.712-1.557-1.59-1.557ZM29.323 9.856v33.383c0 .86.712 1.557 1.59 1.557.877 0 1.589-.697 1.589-1.557V9.856c0-.86-.712-1.557-1.59-1.557-.877 0-1.589.697-1.589 1.557ZM21.73 17.815V51.2c0 .854.702 1.547 1.572 1.556h.034c.87-.009 1.572-.702 1.572-1.556V17.815c0-.86-.711-1.556-1.59-1.556-.877 0-1.588.697-1.588 1.556Z'
                            clipRule='evenodd' />
                    </svg>
                </div>
                <div tw='h-[60px] w-[60px] z-10 absolute top-[36px] right-[80px]'>
                    <RecordIndicator level={level} dtc={true} />
                </div>
                <div tw='text-white text-left font-semibold text-xl max-w-[500px] mt-3'>
                    To Complete Your Assignment
                </div>
                <div tw='  text-white font-normal max-w-[100%] mt-9 pl-5'>
                    <ul tw='list-disc'>
                        <li tw='text-[1.15rem]'> Watch {assignmentLengthVerb} in the playlist.</li>
                    </ul>

                </div>
                <div tw='text-base  text-white font-normal max-w-[100%] pl-5'>(The more you watch, the easier it is to remember!)</div>
                <div tw='text-[1.15rem] text-white font-normal max-w-[100%] mt-6 pl-5'>
                    <ul tw='list-disc'>
                        <li tw='text-[1.15rem]'>Get {assignment.quiz_score_goal}% on the {quizLevel[assignment.quiz_level_goal]} Challenge for {goalVerb} video.</li>
                    </ul>

                </div>
                <div tw='flex justify-center items-end w-full h-[58px] mt-6'>
                    <div>
                        <Button
                            tw=' min-w-[150px]'
                            variant={'white-dtc'}
                            onClick={handleClick}

                        >
                            <div tw='text-sm md:text-base font-medium pl-2 lg:pl-1'>
                                {buttonText}
                            </div>
                        </Button>
                    </div>

                </div>
            </CardContainer>

            <CardContainer
                tw='md:hidden justify-center h-[240px]'
                css={[css`background: linear-gradient(315deg, #2E066A 0%, #4D40C2 68.91%);`]}
                onClick={handleClick}
            >
                <div tw='absolute  bg-gradient-to-r from-[#1CC8E1] to-[#887BE3] h-[54px] w-[54px] flex  items-center justify-center top-[12px] left-[15px] z-20 rounded'>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47 53' width='34' height='40' fill='none'>
                        <path
                            fill='#fff' fillRule='evenodd'
                            d='M0 7.543v19.373c0 .86.712 1.556 1.59 1.556.877 0 1.589-.697 1.589-1.556V7.543c0-.86-.712-1.557-1.59-1.557C.712 5.986 0 6.683 0 7.543ZM8.812 0c-.878 0-1.59.697-1.59 1.557V34.94c0 .86.712 1.557 1.59 1.557.878 0 1.59-.697 1.59-1.557V1.557C10.402.697 9.69 0 8.812 0ZM14.126 9.856v33.383c0 .86.711 1.557 1.589 1.557s1.59-.697 1.59-1.557V9.856c0-.86-.712-1.557-1.59-1.557-.878 0-1.59.697-1.59 1.557ZM43.439 7.543v19.373c0 .86.711 1.556 1.59 1.556.877 0 1.588-.697 1.588-1.556V7.543c0-.86-.711-1.557-1.589-1.557s-1.59.697-1.59 1.557ZM37.785 0c-.878 0-1.59.697-1.59 1.557V34.94c0 .86.712 1.557 1.59 1.557.878 0 1.589-.697 1.589-1.557V1.557c0-.86-.712-1.557-1.59-1.557ZM29.323 9.856v33.383c0 .86.712 1.557 1.59 1.557.877 0 1.589-.697 1.589-1.557V9.856c0-.86-.712-1.557-1.59-1.557-.877 0-1.589.697-1.589 1.557ZM21.73 17.815V51.2c0 .854.702 1.547 1.572 1.556h.034c.87-.009 1.572-.702 1.572-1.556V17.815c0-.86-.711-1.556-1.59-1.556-.877 0-1.588.697-1.588 1.556Z'
                            clipRule='evenodd' />
                    </svg>
                </div>
                <div tw='h-[53px] w-[53px] z-10 absolute top-[12px] left-[45px] '>
                    <RecordIndicator level={level} dtc={true} />
                </div>
                <div tw='text-[1.15rem] text-white font-normal max-w-[100%]'>
                    <BalanceText>{verb} {level_titles[level]} Challenge for “{video?.title}”  </BalanceText>
                </div>
                <div tw='flex justify-between items-end w-full mt-4'>
                    <div>
                        <Button
                            tw='min-w-[120px] h-[40px] pt-2.5 font-semibold'
                            variant={'white-dtc'}
                            onClick={handleClick}
                        >
                            <div tw='text-sm md:text-base font-medium'>
                                {buttonText}
                            </div>
                        </Button>
                    </div>
                </div>
            </CardContainer>
        </>
    )
}

const CompleteAssignmentContents = ({ level }) => {
    return (
        <CardContainer
            tw='hidden xl:flex pl-6 p-5 items-center justify-center h-[320px] w-[550px] xl:w-[68%]'
            $level={level}
            css={[
                css`background: linear-gradient(315deg, #2E066A 0%, #4D40C2 68.91%);`
            ]}
        >
            <div tw='text-3xl font-medium text-center text-white'>Great Job!</div>
            <div tw='text-base font-medium text-center text-white mt-4'>You completed your assignment</div>
            <div tw='absolute top-3 right-8'>
                <svg width="84" height="109" viewBox="0 0 84 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M38.7309 88.3824L17.0997 73.8521C16.8041 72.3469 17.7173 69.0363 19.8446 63.9241L0.87621 62.9544L7.25374 47.6185L26.2221 48.5883C38.9772 17.9166 64.3232 3.55047 82.4039 -1.0068e-05C85.9544 18.0808 82.2398 46.9772 58.6691 70.3837L66.7397 87.5769L54.9544 99.2801L46.8837 82.087C42.9556 85.9896 40.2362 88.0868 38.7309 88.3824ZM60.1236 41.9311C61.9219 41.578 63.5062 40.525 64.528 39.0037C65.5499 37.4825 65.9256 35.6176 65.5725 33.8194C65.2194 32.0211 64.1664 30.4368 62.6451 29.4149C61.1239 28.3931 59.259 28.0174 57.4608 28.3705C55.6625 28.7236 54.0782 29.7766 53.0563 31.2979C52.0345 32.8191 51.6588 34.684 52.0119 36.4822C52.365 38.2805 53.418 39.8648 54.9393 40.8867C56.4605 41.9085 58.3254 42.2842 60.1236 41.9311ZM13.4671 79.2599L20.2269 83.8006C17.8039 87.4077 16.7366 91.9174 17.0176 97.3407C22.1444 95.55 25.9157 92.8565 28.3386 89.2495L35.0984 93.7902C30.2561 100.999 21.8078 105.789 9.75242 108.156C7.38514 96.101 8.62487 86.4685 13.4671 79.2599Z" fill="url(#paint0_linear_300_6099)" />
                    <defs>
                        <linearGradient id="paint0_linear_300_6099" x1="15.7346" y1="102.13" x2="82.8111" y2="-0.123221" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FF2E9F" />
                            <stop offset="0.253876" stop-color="#A67CFF" />
                            <stop offset="0.592431" stop-color="white" />
                            <stop offset="0.779939" stop-color="#F6FCFF" />
                            <stop offset="1" stop-color="#00D1FF" />
                        </linearGradient>
                    </defs>
                </svg>

            </div>
        </CardContainer>
    )
}


const CompleteAssignmentCard = ({ video, assignment }) => {
    console.log("🚀 ~ CompleteAssignmentCard ~ assignment:", assignment)
    const navigate = useNavigate();
    let progressStarted = Object.keys(assignment.progress.progress).length > 0;
    const firstVideoInAssignment = assignment?.videos[0];
    let link = '/assignments/what-to-do-now';
    let level = assignment.quiz_level_goal;
    let verb = 'Take the';
    let assignmentLength = assignment.items.length
    let assignmentLengthVerb = 'all videos'

    if (assignmentLength === 1) {
        assignmentLengthVerb = 'the video'
    } else {
        assignmentLengthVerb = 'all videos'
    }

    let goalVerb = assignmentLength > 1 ? 'each' : 'the';
    let buttonText = progressStarted ? 'Continue' : 'START';

    let CompletedAssignment = false;

    if (assignment?.progress?.completed === true) {
        CompletedAssignment = true;
        level = 'complete';
    }

    const handleClick = () => navigate(link);


    return (
        <>
            {CompletedAssignment ? <CompleteAssignmentContents />
                :
                <ActiveAssignmentContents level={level} handleClick={handleClick} quizLevel={quizLevel} buttonText={buttonText} assignmentLengthVerb={assignmentLengthVerb} assignment={assignment} goalVerb={goalVerb} verb={verb} video={video} />}
        </>
    );
};;

export default CompleteAssignmentCard;