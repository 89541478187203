import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import RecordImg from 'components/RecordImg';
import icon_video from 'static/images/badges/MuzologyHeart.png';
// import AchievementConfig from '../../app/constants/AchievementConstants';
import AchievementConfig from 'app/containers/child/Progress/AchievementConstants'

const findVideoTitle = (videos, video_id) => {
    if (videos) {
        const video = videos.find((video) => video.id === video_id);
        if (video) {
            return video.title;
        }
    }
    return null;
};

const RecentAchievement = (props) => {
    const MuzologyHeart = AchievementConfig.single_video_watched_7_times.non_ribbon_image;
    const filterAchievement = (item) => {
        switch (item.name) {
            case 'video:watched': {
                const videoTitle = findVideoTitle(props.videos, item.data.video_id ?? item.data.videoId);
                const time = moment.utc(item.date).local().fromNow();
                return (
                    <div tw='flex py-6' aria-label={`You watched ${videoTitle}, ${time}`}>
                        <img src={MuzologyHeart} alt='video' tw='w-auto h-auto max-h-24' />
                        <div tw='flex flex-col justify-center pl-4 sm:pl-10'>
                            <div tw='text-white text-lg font-semibold'>
                                You watched <span tw='text-SkyBlueCrayola'>{videoTitle}</span>
                            </div>
                            <div tw='text-base mt-1 text-GainsboroGrey'>{time}</div>
                        </div>
                    </div>
                );
            }
            case 'quiz:taken': {
                const videoTitle = findVideoTitle(props.videos, item.data.video_id ?? item.data.videoId);
                const level = ['Silver Record', 'Gold Record', 'Platinum Record'];
                const time = moment.utc(item.date).local().fromNow();
                const quizLevel = level[item.data.quizLevel - 1];
                return (
                    <div tw='flex py-6' aria-label={`You earned the ${quizLevel} on ${videoTitle}, ${time}`}>
                        <RecordImg aria-hidden={true} level={item.data.quizLevel} tw='h-auto maxHeight[5.3rem]' />
                        <div aria-hidden={true} tw='flex flex-col justify-center pl-4 sm:pl-10'>
                            <div tw='text-white text-lg font-semibold'>
                                You earned the <span>{quizLevel}</span> on <span tw='text-SkyBlueCrayola'>{videoTitle}</span>
                            </div>
                            <div tw='text-base mt-1 text-GainsboroGrey'> {time} </div>
                        </div>
                    </div>
                );
            }
            case 'achievement:created': {
                const videoTitle = findVideoTitle(props.videos, item.data.video_id ?? item.data.videoId);
                const achievement = AchievementConfig[item.data.achievement];
                const time = moment.utc(item.date).local().fromNow();
                return (
                    <div tw='flex py-6' aria-label={`You earned the ${achievement.header} on ${videoTitle}, ${time}`}>
                        <img className='image' src={achievement.non_ribbon_image} alt='Achievement' tw='w-auto h-auto max-h-24' />
                        <div aria-hidden={true} tw='flex flex-col justify-center pl-4 sm:pl-10'>
                            <div tw='text-white text-lg font-semibold'>
                                You earned the <span>{achievement.header}</span> on <span tw='text-SkyBlueCrayola'>{videoTitle}</span>
                            </div>
                            <div tw='text-base mt-1 text-GainsboroGrey'> {time} </div>
                        </div>
                    </div>
                );
            }
            default:
                // null
                return item.name;
        }
    };

    const { item } = props;
    let achievement = filterAchievement(item);

    return (
        <div tw='w-full font-Poppins'>
            {achievement}
        </div>
    );
};

RecentAchievement.propTypes = {
    item: PropTypes.object
};

export default RecentAchievement;
