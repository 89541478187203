import React from 'react';
import RecordImageSilvern from 'static/images/records/DTC/CD_SILVER.png';
import RecordImageGoldn from 'static/images/records/DTC/CD_GOLD.png';
import RecordImagePlatinumn from 'static/images/records/DTC/CD_PLATINUM.png';
import RecordImageSilver2xn from 'static/images/records/DTC/CD_SILVER.png';
import RecordImageGold2xn from 'static/images/records/DTC/CD_GOLD.png';
import RecordImagePlatinum2xn from 'static/images/records/DTC/CD_PLATINUM.png';

import RecordImageSilver from 'static/images/records/silver.png';
import RecordImageGold from 'static/images/records/gold.png';
import RecordImagePlatinum from 'static/images/records/platinum.png';
import RecordImageSilver2x from 'static/images/records/silver@2x.png';
import RecordImageGold2x from 'static/images/records/gold@2x.png';
import RecordImagePlatinum2x from 'static/images/records/platinum@2x.png';
import { styled } from 'twin.macro';
import { useAccount } from 'state/User/UserState';

const Value = styled.div`
	font-family: OpenSans, serif;
	font-size: 10px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	color: #ffffff;

	position: absolute;

	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	// TODO: fix this
	line-height: 48px;
	text-align: center;
`;

const RecordIconContainer = styled.div`
	position: relative;
	width: 100%;

	img {
		width: 100%;
		height: auto;
	}
`;

const RECORD_IMAGESNEW = [
    [RecordImageSilvern, RecordImageSilver2xn],
    [RecordImageSilvern, RecordImageSilver2xn],
    [RecordImageGoldn, RecordImageGold2xn],
    [RecordImagePlatinumn, RecordImagePlatinum2xn]
];

const RECORD_IMAGES = [
    [RecordImageSilver, RecordImageSilver2x],
    [RecordImageSilver, RecordImageSilver2x],
    [RecordImageGold, RecordImageGold2x],
    [RecordImagePlatinum, RecordImagePlatinum2x]
];

const RecordIcon = (props = { level: 1, value: null, brightness: '100%',  }) => {
    const { is_parent_account } = useAccount();
    const value = props.value || null;
    const level = props.level % RECORD_IMAGES.length;
    const imageSet = RECORD_IMAGESNEW[level];
    if( !imageSet ) {
        console.log('RecordIcon: invalid level', level);
        return null;
    }
    const image = imageSet[1];
    const valueNode = value !== null ? <Value>{value}</Value> : null;
    return (
        <RecordIconContainer className='record-icon' aria-hidden={true}>
            <img
                className='record-icon-img'
                src={image}
                width='auto'
                height='auto'
                alt='record'
                style={{ filter: `brightness(${props.brightness})` }}
            />
            {valueNode}
        </RecordIconContainer>
    );
};

export default RecordIcon;
