import React, { useState, useRef } from 'react';
import 'twin.macro';
import BackgroundImage from 'lib/components/BackgroundImage';
import CreateMathPlaylistBackground from 'static/images/background_images/CreateMathPlaylist.png';
import { ONBOARD_STAGE_AGREE_TO_TERMS } from 'app/AccountOnboard/OnboardStages';
import { useForm } from 'react-hook-form';
import { useUserAction } from 'state/User/UserState';
import { useUser } from 'state/User/UserState';
import { useMedia } from 'react-use';
import { useOnboardStageFinished } from 'state/OnboardState';
import { BoxShadowCss } from '../Helpers';
import { PrivacyTos, GradeChoices, OnBoardingInput, Header, Button, SpinningRecordImage } from '../Components';
import { LoadingOverlay } from '../../../lib/components/loading';
import SchoolAutoSuggest from '../../SchoolAutoSuggest/SchoolAutoSuggest';

const TeacherOnboard = () => {
    // school state
    const [school, setSchool] = useState('');
    // check if school is selected
    const [schoolNotSelected, setSchoolNotSelected] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(null);
    // set onboard stage finished
    const stageFinished = useOnboardStageFinished();
    // get the user
    const user = useUser();
    //  update user state
    const { updateUser } = useUserAction();

    //  default form values
    const defaultValues = {
        terms: false,
        user_name: user.username ?? user.email
    };
    // useForm setup for onboard form with react-hook-form
    const { register, handleSubmit, watch, errors: formErrors, setError, clearErrors, trigger, control, setValue } = useForm({ defaultValues });
    console.log('🚀 ~ TeacherOnboard ~ formErrors:', formErrors);
    // grade selection state
    const [selectedGrade, setGrade] = useState(null);
    // useMedia query to determine if on small device. If so get grade from data form instead of selectedGrade state
    const isSmall = useMedia('(max-width: 639px)');
    // loading state while saving the form
    const [saving, setSaving] = useState(false);
    //header string
    const header = 'Let\'s Customize Your Muzology Experience';

    // helper to handle grade selection on mobile
    // TODO: add comments for why its shouldDirty: true
    const selectGradeMobile = (data) => {
        setValue('grade', data, { shouldDirty: true });
        setGrade(data);
        clearErrors('grade');
    };

    // store grade choices in references
    const gradeChoice = useRef({});
    gradeChoice.current = watch('grade', '');

    // teacher submits onboard form
    const teacherOnSubmit = async (data) => {
        if (!selectedSchool) {
            // setError('school', { type: 'manual', message: 'This field is required' });
            setSchoolNotSelected(true);
            return;
        }
        console.log('selected school:', selectedSchool);
        setSchoolNotSelected(false);
        const payload = {
            ...data,
            school: selectedSchool?.pk
        };
        try {
            console.log('Payload:', payload);
            await stageFinished(ONBOARD_STAGE_AGREE_TO_TERMS, payload);
            // update the user's state
            updateUser();
        } catch (error) {
            console.log('Error:', error);
        }
    };

    return (
        <div tw='relative w-full mx-auto max-w-[380px] sm:max-w-[600px] md:max-w-[660px] lg:max-w-full overflow-auto'>
            <div tw='bg-white rounded-lg py-5 px-5 sm:px-6 xl:px-8 font-Poppins relative overflow-hidden'>
                <BackgroundImage image={CreateMathPlaylistBackground} tw=' hidden sm:block absolute inset-0 rounded-lg'/>
                <LoadingOverlay show={saving} fixed={false} background='#35107688'/>

                <div tw='w-full  flex flex-col lg:flex-row items-center lg:items-stretch gap-4 lg:gap-0 pt-0 lg:pt-5'>

                    {/* Right Column - Contains Image Elements and Header Copy */}
                    <div tw='w-full lg:w-[44%] relative'>
                        <Header timesTableProduct={false} teacher={true} header={header}/>
                        <SpinningRecordImage/>
                    </div>

                    <div tw='w-full lg:w-[56%] relative  '>
                        <div
                            tw='rounded-lg py-0 sm:py-9 bg-white font-["Poppins"] w-full px-0 sm:px-4 lg:px-6 xl:px-9 max-w-[600px] lg:max-w-[700px] overflow-clip z-20 lg:-mt-5 mx-auto'
                            css={[BoxShadowCss]}
                        >
                            <form onSubmit={handleSubmit(teacherOnSubmit)}>

                                {/*
                                <div tw='mb-4 md:mb-5'>
                                    <OnBoardingInput label='Name' register={register} name='full_name' teacher={false} required={true}/>
                                </div>
                                */}

                                {/* TeacherName Input */}
                                <div tw='mb-4 md:mb-5'>
                                    <OnBoardingInput label='(This is the name your students will see on Muzology.)' register={register} name='name' teacher={true} required={false}/>
                                </div>
                                {/* Grade Choices for Parents and Teacher - Desktop and Mobile DropDown */}
                                <GradeChoices
                                    timesTableProduct={false}
                                    gradeChoice={gradeChoice}
                                    teacher={true}
                                    register={register}
                                    clearErrors={clearErrors}
                                    formErrors={formErrors}
                                    selectGradeMobile={selectGradeMobile}
                                    selectedGrade={selectedGrade}
                                />
                                {/* School Selection */}
                                <div tw='relative w-full'>
                                    <div tw='mb-3 text-base font-normal text-[#303030] text-justify'>Your School</div>
                                    <SchoolAutoSuggest
                                        value={school}
                                        onChange={setSchool}
                                        onSelect={(s) => {
                                            console.log('School selected:', s);
                                            setSelectedSchool(s);
                                            if (s) {
                                                setSchoolNotSelected(false);
                                            }
                                        }}
                                        selected={selectedSchool}
                                    />
                                    {schoolNotSelected && <div tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-0'>Please select your school.</div>}
                                </div>
                                {/* Privacy Policy and TOS */}

                                {/*
                                <div tw='pt-4'>
                                    <PrivacyTos register={register} formErrors={formErrors}/>
                                </div>
                                */}

                                <div tw='w-full flex justify-center pt-7 md:pt-[13px]'>
                                    <Button buttonCopy='Create Account' clearErrors={clearErrors} trigger={trigger}/>
                                </div>

                                {/*
                                                        <div tw='mb-3 text-base font-normal text-[#303030] text-justify'>
                                    By clicking "Create Account" you agree to our{' '}
                                    <a tw='text-black underline' href='https://muzology.com/tos' target='_blank' rel='noopener noreferrer'>
                                        Terms of Use
                                    </a>{' '}
                                    and{' '}
                                    <a tw='text-black underline' href='https://muzology.com/privacy' target='_blank' rel='noopener noreferrer'>
                                        Privacy Policy
                                    </a>
                                </div>
*/}
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default TeacherOnboard;