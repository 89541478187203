import React from 'react';
import { css } from 'twin.macro';
import PlatinumRecord from 'static/images/records/DTC/platinum-createmath.png';
import SymbolsBackground from 'static/images/textures/create-math-playlist-symbols.png';
import SymbolsRecordMedium from 'static/images/textures/SymbolsRecordMedium.png';

const rotateCss = css`
    -webkit-animation: spin 10s linear infinite;
    -moz-animation: spin 10s linear infinite;
    animation: spin 10s linear infinite;
    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }
    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
`;


const SpinningRecordImage = () => {
    return (
        <>
            <div tw=' hidden lg:block absolute top-[46%] left-1/4  z-20'>
                <img src={PlatinumRecord} tw='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' css={[rotateCss]} alt='Platinum Record' />
            </div>
            <div tw='hidden lg:block  absolute top-[37%] left-[3.5%] z-10 '>
                <img src={SymbolsBackground} tw='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' alt='Platinum Record' />
            </div>
        </>
    );
};

export default SpinningRecordImage;