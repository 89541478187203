import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import HomePageVideoRows from 'components/HomePageVideoRows/HomePageVideoRows';
import VideoSlider from 'components/VideoSlider';
import { useCourseAndVideos, useLessons } from 'state/CatalogState';
import { useUser, useTeacher } from 'state/User/UserState';
import { useVideosWithStats } from 'state/Student/VideoStats';
import { useTeacherAssignments } from 'state/Teacher/TeacherProvider';
import RecommendedPlaylist from 'components/RecommendedPlaylist';
import RenderOnViewPortEntry from 'components/RenderOnViewPortEntry/RenderOnViewPortEntry';
import { Tooltip } from 'react-tooltip';
import NewVideosRow from 'components/NewVideosRow/NewVideosRow';

const TeacherVideoRowsContainer = ({ favorites, recent_videos, assignedVideos }) => {
    const user = useUser();
    const teacher = useTeacher();
    const nullCourse = useCourseAndVideos('d240f7d3-5189-41fa-9a10-1192a5962da1');
    const newVideosCourse = useCourseAndVideos('new-videos');
    console.log("🚀 ~ TeacherVideoRowsContainer ~ newVideosCourse:", newVideosCourse)
    const coursesNotLoaded = Object.entries(nullCourse ?? {}).length === 0;
    const lessons = useLessons();
    const videoLibraryWithStats = useVideosWithStats();
    const showRecentVideos = recent_videos.length > 0;
    const showFavoritesVideos = favorites.length > 0;
    const assignments = useTeacherAssignments();
    const showAssignedVideos = assignments.length > 0;
    const TeachersLowestGrade = parseInt(user?.instructor?.grades_data?.length > 0 ? user?.instructor.grades_data[0] : '6');


    const CreateGradeVideoRows = useMemo(() => {
        const gradeKey = {
            0: 0,
            1: 0,
            2: 0,
            3: 1,
            4: 2,
            5: 3,
            6: 4,
            7: 5,
            8: 6,
            9: 7,
            10: 7,
            11: 7,
            12: 7
        };
        const findLesson = (lessons, lessonId) => Object.values(lessons).find(l => l.id === lessonId);

        if (coursesNotLoaded)
            return null;
        console.log('🚀 ~ TeacherVideoRowsContainer ~ nullCourse:', nullCourse);

        const rootGroups = nullCourse?.groups?.filter(item => item.parent === null) ?? [];
        const groupIndex = gradeKey[TeachersLowestGrade];
        const classGroup = rootGroups[groupIndex];
        const group_id = classGroup?.id;


        // if group has sub groups create a new arr
        const reduceCourseGroups = nullCourse?.groups?.reduce((acc, group) => {
            if (group.parent === group_id) {
                acc.push(group);
            }
            return acc;
        }, []);

        const what_to_do_next_group_id = reduceCourseGroups?.length > 0 ? reduceCourseGroups[0]?.id : null;
        const what_to_do_next_key = {
            below: 0,
            at: 1,
            above: 2
        };
        const reduceWhatToDoNextGroups = nullCourse?.groups?.reduce((acc, group) => {
            if (group.parent === what_to_do_next_group_id) {
                acc.push(group);
            }
            return acc;
        }, []);

        // find what to do next groups by proficiency_level group
        const WhatToDoNextGroupIndex = what_to_do_next_key[user?.student.proficiency_level ?? 'at'];
        const WhatToDoNextGroup = reduceWhatToDoNextGroups?.length > WhatToDoNextGroupIndex ? reduceWhatToDoNextGroups[WhatToDoNextGroupIndex] : null;
        const WhatToDoNextGroupGroupVideos = WhatToDoNextGroup?.lessons.map(lessonId => findLesson(lessons, lessonId)).filter(l => l);
        const nullWhatToDoNextVideoList = videoLibraryWithStats.reduce((acc, video) => {
            WhatToDoNextGroupGroupVideos?.map((group) => {
                if (group.video === video.id) acc.push(video);
            });
            return acc;
        }, []);
        // setNullWhatToDoNextVideoList(nullWhatToDoNextVideoList);

        return reduceCourseGroups?.map((group, index) => {
            if (index === 0)
                return null;
            // find videos once they are populated in the playlist
            let groupLessons = group?.lessons.map(lessonId => findLesson(lessons, lessonId)).filter(l => l);

            let groupVideos = groupLessons.map(lesson => {
                const v = videoLibraryWithStats?.find(video => video.id === lesson.video);
                if (!v) {
                    console.log('video not found', lesson.video);
                }
                return v;
            });
            let videoRowVideos = groupVideos.filter(v => v);

            return (
                <>
                    <RenderOnViewPortEntry key={`${group.id}-1`} threshold={0.25} >
                        <div tw='mt-14 hidden md:block' key={group?.id}>
                            <HomePageVideoRows title={group?.title} videos={videoRowVideos} teacher={teacher} videosLoaded={coursesNotLoaded} />
                        </div>
                    </RenderOnViewPortEntry>
                    <div key={`${group.id}-2`} tw='mt-14 ml-[-22vw] mr-[-7vw] md:hidden'>
                        <div tw='text-white text-base tiny:text-xl font-semibold ml-[34vw] max-w-[320px] xsm:max-w-[360px]'>{group?.title}</div>
                        <VideoSlider videos={videoRowVideos} noSideArrow={true} teacher={teacher} />
                    </div>

                </>
            );
        });
    }, [coursesNotLoaded, nullCourse?.groups, TeachersLowestGrade, user?.student.proficiency_level, videoLibraryWithStats, lessons, teacher]);

    return (
        <div>
            <NewVideosRow teacher={true} />
            
            {/* if no recent videos show recommended list */}
            {!showRecentVideos &&
                <RecommendedPlaylist
                    grade={TeachersLowestGrade ?? 6}
                    teacher={teacher}
                />
            }

            {showRecentVideos &&
                <>
                    <RenderOnViewPortEntry threshold={0.25} >
                        <div tw='mt-14 hidden md:block'>
                            <HomePageVideoRows title='Recently Watched' videos={recent_videos} teacher={teacher} recently_watched={true} />
                        </div>
                    </RenderOnViewPortEntry>
                    <div tw='mt-14 ml-[-22vw] mr-[-7vw] md:hidden'>
                        <div tw='text-white text-base tiny:text-xl font-semibold ml-[34vw] max-w-[320px] xsm:max-w-[360px]'>Recently Watched</div>
                        <VideoSlider videos={recent_videos} noSideArrow={true} teacher={teacher} />
                    </div>
                </>
            }
            {showAssignedVideos &&
                <>
                    <RenderOnViewPortEntry threshold={0.25} >
                        <div tw='mt-14 hidden md:block'>
                            <HomePageVideoRows title='Assigned Videos' videos={assignedVideos} teacher={teacher} />
                        </div>
                    </RenderOnViewPortEntry>

                    <div tw='mt-14 ml-[-22vw] mr-[-7vw] md:hidden'>
                        <div tw='text-white text-base tiny:text-xl font-semibold ml-[34vw] max-w-[320px] xsm:max-w-[360px]'>Assigned Videos</div>
                        <VideoSlider videos={assignedVideos} noSideArrow={true} teacher={teacher} />
                    </div>
                </>
            }
            {showFavoritesVideos &&
                <>
                    <RenderOnViewPortEntry threshold={0.25} >
                        <div tw='mt-14 hidden md:block'>
                            <HomePageVideoRows title='Favorited Videos' videos={favorites} teacher={teacher} />
                        </div>
                    </RenderOnViewPortEntry>

                    <div tw='mt-14 ml-[-22vw] mr-[-7vw] md:hidden'>
                        <h2 tw='text-white text-base tiny:text-xl font-semibold ml-[28vw] max-w-[320px] xsm:max-w-[360px]'>Favorited Videos</h2>
                        <VideoSlider videos={favorites} noSideArrow={true} teacher={teacher} />
                    </div>
                </>
            }
            <RenderOnViewPortEntry threshold={0.25} >
                {CreateGradeVideoRows}
            </RenderOnViewPortEntry>
            <Tooltip
                id='my-tooltip'
                style={{
                    backgroundColor: '#6441B9',
                    color: '#fff',
                    fontSize: '12px',
                    // center the text in the tooltip
                    textAlign: 'center'


                }}
                render={({ content, activeAnchor }) => (
                    <span>
                        {content}
                    </span>
                )}
            />
        </div>
    );
};

TeacherVideoRowsContainer.propTypes = {
    anyProp: PropTypes.any
};

export default TeacherVideoRowsContainer;

