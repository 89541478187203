import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import { useVideoStats } from 'state/Student/VideoStats';
import { getActivityStates } from 'app/containers/child/utils/ChildActiveState';
import { useProduct } from 'state/User/UserState';
import { TIMES_TABLES_TUTORIAL_VIDEO_ID, CHILD_TUTORIAL_VIDEO_ID } from 'config';
import Button from 'components/Button/Button';
import VideoPlayerOverlay from 'components/VideoPlayerOverlay/VideoPlayerOverlay';

const HowItWorksButton = ({buttonClass}) => {
    const videoStats = useVideoStats();
    const timesTableProduct = useProduct('times-tables');
    const [playTutorialVideo, setPlayTutorialVideo] = useState(false);

    // get the active state of the child
    const { nullContainer, semiActiveState } = useMemo(
        () => getActivityStates(videoStats),  // Calculate all activity states
        [videoStats]  // Recalculate when videoStats changes
    );

    // Only render if user is in null or semi-active state
    if (nullContainer || semiActiveState) {
        return (
            <div tw='pt-4'>
                <Button variant={buttonClass ? buttonClass : 'how-it-works-dtc'} onClick={() => setPlayTutorialVideo(true)}>
                    <div tw='flex items-center justify-center'>
                        <div tw='hidden xl:block'>
                            <svg xmlns='http://www.w3.org/2000/svg' width='13' height='15' fill='none'>
                                <path
                                    fill='#fff'
                                    d='M11.802 6.293c.93.65.93 1.764 0 2.414L4.145 14.06C2.755 15.034.5 14.287.5 12.853V2.147C.5.714 2.754-.033 4.145.939l7.657 5.354Z' />
                            </svg>
                        </div>
                        <div tw='xl:pl-2'>How it Works</div>
                    </div>
                </Button>
                {/* play tutorial video */}
                <VideoPlayerOverlay
                    videoId={timesTableProduct ? TIMES_TABLES_TUTORIAL_VIDEO_ID : CHILD_TUTORIAL_VIDEO_ID}
                    isOpen={playTutorialVideo}
                    setIsOpen={setPlayTutorialVideo} />
            </div>
        );
    }

    return null;
};

HowItWorksButton.propTypes = {
    anyProp: PropTypes.any,
}

export default HowItWorksButton;

