import React, { useState, useRef, useEffect } from 'react';
import 'twin.macro';
import BackgroundImage from 'lib/components/BackgroundImage';
import TealToPinkAngleBackground from 'static/images/background_images/CreateMathPlaylist.png';
import PlaceholderImage from 'static/images/background_images/PlaceHolderFullMuzologyOnboard.png';
import { useForm } from 'react-hook-form';
import { useUserAction } from 'state/User/UserState';
import { useUser } from 'state/User/UserState';
import { useMedia } from 'react-use';
import { LoadingOverlay } from '../../../../lib/components/loading';
import { OnBoardingInput, Header, Button, GradeChoices, MathLevelChoices } from '../../Components';
import { onSubmitDTCUser, BoxShadowCss } from '../../Helpers';
import { useAnalytics } from '../../../../analytics/AnalyticsProvider';
import BG from 'static/images/onboarding/OnboardBG.png';
import OnboardGirl from 'static/images/onboarding/OnboardGirl.png';


const SetupAccount = ({ setOnboardState, onboardFlag, isOpen }) => {

    //  update user state
    const { updateUser } = useUserAction();
    //  default form values
    const defaultValues = {
        level: 'at'
    };
    const timesTableProduct = false;
    // useForm setup for onboard form with react-hook-form
    const { register, handleSubmit, watch, errors: formErrors, setError, clearErrors, trigger, control, setValue } = useForm({ defaultValues });
    console.log('🚀 ~ SetupAccount ~ formErrors:', formErrors);
    // analytics to track the onboarding
    const { track } = useAnalytics('Setup Child Profile', 'Onboarding', { step: 'Setup Child Profile' });
    // loading state while saving the form
    const [saving, setSaving] = useState(false);
    //header string
    const header = 'We’re Customizing Your Experience';
    // grade selection state
    const [selectedGrade, setGrade] = useState(null);
    // useMedia query to determine if on small device. If so get grade from data form instead of selectedGrade state
    const isSmall = useMedia('(max-width: 639px)');

    // helper to handle grade selection on mobile
    // TODO: add comments for why its shouldDirty: true
    const selectGradeMobile = (data) => {
        setValue('grade', data, { shouldDirty: true });
        setGrade(data);
        clearErrors('grade');
    };

    useEffect(() => {
        isOpen && setError('grade', {
            type: 'manual',
            message: ''
        });
    }, [isOpen, setError]);

    // store grade choices in references
    const gradeChoice = useRef({});
    gradeChoice.current = watch('grade', '');

    const levelChoice = useRef({});
    const currentLevel = watch('level', '');
    levelChoice.current = currentLevel;

    // parent submits onboard form
    const onSubmit = (data) => {
        onSubmitDTCUser(data, {
            timesTableProduct,
            setSaving,
            setError,
            track,
            updateUser,
            setOnboardState,
            onboardFlag,
            isSmall,
            selectedGrade
        });
    };
    return (
        <div tw='relative w-full mx-auto max-w-[370px] sm:max-w-[600px] md:max-w-[660px] lg:max-w-[1200px] overflow-auto'>
            <div tw='bg-white rounded-lg py-5 px-5 sm:px-6 xl:px-8 font-Poppins relative overflow-hidden'>
                <BackgroundImage image={BG} tw=' hidden sm:block absolute inset-0 rounded-lg' />
                <LoadingOverlay show={saving} fixed={false} background='#35107688' />
                <div tw='w-full  flex flex-col lg:flex-row items-center lg:items-stretch gap-4 lg:gap-0 py-0 lg:py-10'>
                    {/* Right Column - Contains Image Elements and Header Copy */}
                    <div tw='w-full lg:w-[44%] relative'>
                        {/* placeholder for now - gif or video will be added here later */}
                        <div tw=' hidden lg:block absolute w-full bottom-[-60px] right-[10px]'>
                            <div tw=' w-[500px]'>
                                <img src={OnboardGirl} tw='w-full h-auto' />
                            </div>
                        </div>
                    </div>
                    {/* Left Column - Contains Form Elements */}
                    <div tw='w-full lg:w-[56%] relative  '>
                        <div
                            tw='rounded-lg py-0 sm:py-9 bg-white font-["Poppins"] w-full px-0 sm:px-4 lg:px-6 xl:px-9 max-w-[600px] lg:max-w-[700px] overflow-clip z-20 lg:-mt-5 mx-auto'
                            css={[BoxShadowCss]}
                        >
                            <div tw='mb-3 text-xl sm:text-2xl text-center font-semibold'>Personalize your experience for <br/> better, guided learning.</div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div tw='mb-4 md:mb-5 pt-4'>
                                    <OnBoardingInput label='Child’s Name (optional)' register={register} name='name' teacher={false} required={false} />
                                </div>
                                {/* Grade Choices for Parents and Teacher - Desktop and Mobile DropDown */}
                                <GradeChoices
                                    timesTableProduct={timesTableProduct}
                                    gradeChoice={gradeChoice}
                                    teacher={false}
                                    register={register}
                                    clearErrors={clearErrors}
                                    formErrors={formErrors}
                                    selectGradeMobile={selectGradeMobile}
                                    selectedGrade={selectedGrade}
                                />
                                <div tw='pt-4 pb-4'>
                                    <MathLevelChoices register={register} currentLevel={currentLevel} />
                                </div>
                                <div tw='w-full flex justify-center  pt-7 md:pt-[43px]'>
                                    <Button buttonCopy='Start Learning!' clearErrors={clearErrors} trigger={trigger} />
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default SetupAccount;