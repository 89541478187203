import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AssignmentsContainer from 'app/containers/student/AssignmentsContainer/AssignmentsContainer';
import HomeContainer from 'app/containers/student/StudentHomeContainer/HomeContainer';
import VideoSessionContainer from 'app/containers/shared/VideoSessionContainer/VideoSessionContainer';
import { withScrollLayout } from 'app/layout/Layouts';
import Stats from '../containers/student/StatsContainer/Stats';
import { Error404Page } from '../../components';
import { ProgressContainer } from 'app/containers/child';
import { useAssignments } from 'state/Student/AssignmentsState';
import QuizSession from '../containers/shared/QuizSessionContainer/QuizSession';


const StudentApp = () => {

    return (
        <Routes>
            <Route path='*' element={<Error404Page />} />
            <Route path='/' element={withScrollLayout(HomeContainer)} />
            <Route path='/home' element={<Navigate to='/' />} />
            <Route path='/video/:video_id' element={withScrollLayout(VideoSessionContainer)} />
            <Route path='/quiz/:quiz_id' element={withScrollLayout(QuizSession)} />
            <Route path='/playlists/*' element={withScrollLayout(AssignmentsContainer)} />
            <Route path="/assignments/*" element={withScrollLayout(AssignmentsContainer)} />
            <Route path='/stats/*' element={withScrollLayout(ProgressContainer)} />
        </Routes>
    );
};
export default StudentApp;


