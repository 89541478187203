import React, { Component } from 'react';
import tw from 'twin.macro';
import { styled } from 'twin.macro';
import HoverImg from 'static/images/textures/hover-assignments.png';
import { StyledButton } from './StyledButton';

const CategoryItem = styled.button`
    width: 100%;
    color: white;
    /* height: 2.5rem; */
    /* padding: 1rem; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #3E257A;
    cursor: pointer;
    user-select: none;

    &:focus-visible {
        outline-offset: -2px;
    }

    
    &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: white;
    }

    &.active {
        color: white;
        background-color: #1abdd7;
        box-shadow: none;
    }

    &.selected {
        background: linear-gradient(266deg, #C050FC 8.05%, #6B57DC 86.07%);
        background-size: cover;
        color: white;
    }

    .category-item-content-container {
        /* padding: 1.5rem 0 1.5rem 0; */

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .category-icon {
            margin: 0 0.5rem 0 2rem;
            margin-left: 1.25rem;
            min-width: 26px;
            text-align: center;
            font-size: 22px;
        }
    }
`;

const CategoryName = styled.div`
    font-family: Avenir, serif;
    font-size: 16px;
    padding: 0.5rem 1rem 0.5rem 0.1rem;
    text-align: left;
    
    &.assignments {
        padding: 0.5rem 1rem 0.5rem 2.1rem;
    }

    @media only screen and (max-width: 1024px) {
        font-size: 12px;
    }
`;

const ActionsContainer = styled.div`
    display: flex;
    justify-content: stretch;
    flex-direction: column;
    margin: 10px;

    .action-button {
        margin: 10px;
    }
`;

export default class TreeList extends Component {
    static defaultProps = {
        title: null,
        items: null,
        icon: null,
        hoverImg: HoverImg,
        playlists: false,

        selectable: null,
        selected: null,
        onSelect: null,

        collapsed: null
    };

    constructor(props) {
        super(props);
        this.state = {
            categories: props.categories,
            selected: props.selected,
            editMode: false
        };
    }

    handleClickTitle = () => {
        if (this.props.collapsed !== null) {
            this.setState({ collapsed: !this.state.collapsed });
        }
    };

    render() {
        let childNodes = null;
        if (this.props.items) {
            // render the items unless this node is collapsed
            if (!this.state.collapsed) {
                const items = this.props.items.filter((item, index) => {
                    // skip hidden items, unless they are selected
                    const isSelected = this.props.selected && this.props.selected === item.id;
                    return !(item.hidden && !isSelected);
                });

                childNodes = items.map((item, index) => {
                    // check for selection
                    const isSelected = this.props.selected && this.props.selected === item.id;

                    // create an onClick handler
                    const handleClick = this.props.onSelect ? () => this.props.onSelect(item.id) : null;
                    // console.log(`key: item-${index}-${item.id}`);
                    return (
                        <CategoryItem
                            tabIndex={0}
                            key={`item-${index}-${item.id}`}
                            index={item.id}
                            selected={isSelected}
                            className={`category-item ${isSelected ? 'selected' : ''}`}
                            onClick={handleClick}
                        >
                            <div className='category-item-content-container'>
                                {item.category === 'assignments' ? '' : <div className='category-icon'>{item.icon ? item.icon : ''}</div>}
                                <CategoryName className={`category-name ${item.category ? 'assignments' : ''}`}>
                                    {item.name || '(untitled)'}
                                </CategoryName>
                            </div>
                        </CategoryItem>
                    );
                });
            }
        }

        let actions = null;

        if (this.props.actions) {
            actions = (
                <ActionsContainer>
                    {this.props.actions.map((action, index) => (
                        <StyledButton
                            key={`action-${index}-${action.name}`}
                            className='action-button'
                            onClick={action.onClick}>
                            {action.name}
                        </StyledButton>
                    ))}
                </ActionsContainer>
            );
        }

        return (
            <div css={[!this.props.title && tw`mt-12`]}>
                {this.props.title && (
                    <div tw='px-5'>
                        <div
                            className='category-title'
                            onClick={this.handleClickTitle}>
                            {this.props.title}
                        </div>
                    </div>
                )}

                {childNodes}

                {actions}
            </div>
        );
    }
}
