import React from 'react';
import 'twin.macro';

const PrivacyTos = ({ register, formErrors }) => {
    return (
        <div>
            <div tw='flex justify-start items-start'>
                <div tw='flex items-center mt-1'>
                    <input
                        id='checkbox-terms'
                        type='checkbox'
                        name='terms'
                        ref={register({ required: true })}
                        tw='w-5 h-5 text-black bg-gray-100 border-gray-300 rounded  focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                    />
                </div>

                <div tw='font-Poppins block text-sm font-normal text-[#303030] ml-2'>
                    Before creating your account, certify you're 18+ and agree to our{' '}
                    <a tw='text-black underline' href='https://muzology.com/tos' target='_blank' rel='noopener noreferrer'>
                        Terms of Use
                    </a>{' '}
                    and{' '}
                    <a tw='text-black underline' href='https://muzology.com/privacy' target='_blank' rel='noopener noreferrer'>
                        Privacy Policy.
                    </a>
                </div>
            </div>
            {formErrors.terms && <div tw='pt-2 pb-3 md:pb-7 text-red-500 font-medium sm:font-bold text-sm sm:text-base'>* This field is required</div>}
        </div>
    );
};

export default PrivacyTos;