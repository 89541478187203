import React, { useMemo } from 'react';
import tw, { css, styled } from 'twin.macro';

const MathLevelContainer = styled.label(({ active, index }) => [
    tw`relative w-full rounded-lg bg-white text-[15px] text-center text-[#303030] px-2.5 py-1.5 sm:py-3.5 cursor-pointer sm:hover:scale-105 transform transition-all duration-300 ease-in-out  max-w-[100%] md:max-w-[170px] lg:max-w-[150px] xl:max-w-[170px] max-h-[44px] sm:max-h-[47px]`,
    index === 0 && tw`max-w-[100%] md:max-w-[185px] px-0`,
    css`
        /* Hide the browser's default checkbox */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        position: relative;
        box-shadow: 0px 0px 10px -4px rgba(138, 138, 138, 1);
        box-sizing: border-box;
        background-clip: padding-box;
        border: solid 3px transparent;
    `,

    active && css`
        background: linear-gradient(white, white) padding-box,
        linear-gradient(
            180deg,
            hsl(269deg 60% 46%) 0%,
            hsl(265deg 50% 52%) 11%,
            hsl(259deg 48% 58%) 21%,
            hsl(250deg 47% 63%) 31%,
            hsl(238deg 46% 68%) 41%,
            hsl(223deg 50% 69%) 52%,
            hsl(209deg 55% 71%) 63%,
            hsl(197deg 61% 71%) 75%,
            hsl(185deg 67% 72%) 87%,
            hsl(176deg 84% 73%) 100%
        ) border-box;
        border-radius: 0.5rem;
        border: 3px solid transparent;
        box-shadow: 0px 0px 5.66px 0px #AD63E9;

    `

]);


const MathLevelChoices = ({ currentLevel, register }) => {

    // create math level check boxes
    const mathLevelChoices = useMemo(() => {
        const levels = ['below', 'at', 'above'];
        return levels.map((value, index) => {
            let label;
            if (index === 0) {
                label = (<div>&#128338; Need to catch up</div>);
            } else if (index === 1) {
                label = (<div>&#9989; On track</div>);
            } else if (index === 2) {
                label = (<div>&#128640; Advanced</div>);
            }
            return (
                <MathLevelContainer active={currentLevel === value} index={index} key={value}>
                    <div tw='h-full flex justify-center items-center select-none'>
                        {label}
                    </div>
                    <input
                        name='level'
                        type='radio'
                        value={value}
                        ref={register({ required: true })}
                    />
                </MathLevelContainer>
            );
        });
    }, [currentLevel, register]);

    return (
        <div>
            <div tw='text-base font-normal text-[#303030]'>Math Level</div>
            <div tw='flex flex-col md:flex-row gap-4 md:gap-0 justify-between mt-2 sm:mt-3 px-2 sm:px-0'>{mathLevelChoices}</div>
        </div>
    );
};

export default MathLevelChoices;