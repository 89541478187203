import React, { useMemo } from 'react';
import BackgroundImage from 'lib/components/BackgroundImage';
import { css } from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import SlideWrapper from '../../Components/SlideWrapper';
import ActionBox from '../../Components/ActionBoxThird';
import WaveBackground from '../../imgs/WaveBackground.png';

import SmallDots from '../../imgs/SmallDots.png';

const ContainerStyle = css`
    background: rgba(81, 35, 195, 0.66);
    backdrop-filter: blur(10.521739959716797px);
    min-height: 147px;
    padding: 16px 0; 
    /* max-width: 425px; */
`;




const WhatToDo = ({ studentsAdded, classCreated, teacherName, nonNullState, setPlayTutorialVideo, assignmentCreated }) => {

    const navigate = useNavigate();

    // select which buttons to show
    const buttons = [];


    if (!classCreated) {
        buttons.push(
            <div key='add-class' tw='z-10'>
                <button tw='bg-white text-[#1C0057] text-base text-center font-medium py-3 w-[200px] rounded-lg cursor-pointer hover:bg-gray-200' onClick={() => navigate('/classes')}>Add a Class </button>
            </div>
        );
    } else if (!studentsAdded) {
        buttons.push(
            <div key='invite-student' tw='z-10'>
                <button tw='bg-white text-[#1C0057] text-base text-center font-medium py-3 w-[200px] rounded-lg cursor-pointer hover:bg-gray-200' onClick={() => navigate('/classes')}> Invite Students </button>
            </div>
        );
    } else {
        buttons.push(
            <div key='create-playlist' tw='z-10'>
                <button tw='bg-white text-[#1C0057] text-base text-center font-medium py-3 w-[200px] rounded-lg cursor-pointer hover:bg-gray-200' onClick={() => navigate('/assignments')}>Create a Playlist </button>
            </div>
        );
    }

    if (!assignmentCreated) {
        buttons.push(
            <div key='watch-tutorial' tw='z-10'>
                <button tw='bg-[#FADF58] text-[#1C0057] text-base text-center font-medium py-3 w-[200px] rounded-lg cursor-pointer mt-3 hover:bg-[#FEC32C] active:bg-[#EEB31C]' onClick={setPlayTutorialVideo}>Watch Tutorial </button>
            </div>
        );
    } else {
        buttons.push(
            <div key='see-class-stats' tw='z-10'>
                <button tw='bg-[#FADF58] text-[#1C0057] text-base text-center font-medium py-3 w-[200px] rounded-lg cursor-pointer hover:bg-[#FEC32C] active:bg-[#EEB31C]' onClick={() => navigate('/classes')}> See Class Stats </button>
            </div>
        );
    }

    return (
        <SlideWrapper slide='green'>
            <BackgroundImage image={WaveBackground} tw='mix-blend-soft-light' />
            <div tw='bg-white h-[123px] w-[3px] absolute top-1/2 transform -translate-y-1/2' />
            <div tw='absolute top-5 lg:top-[210px] banner_xl:top-44  right-10 lg:right-0 lg:left-10'>
                <img src={SmallDots} />
            </div>
            <div tw='font-Poppins font-semibold flex flex-wrap lg:flex-nowrap  min-h-[400px] lg:min-h-[280px] banner_xl:min-h-[250px] px-6 pt-9'>
                {nonNullState ?
                    <div tw='w-[300px] mt-2'>
                        <div tw='text-white text-3xl  max-w-full md:max-w-[240px] mb-8 md:mb-0  text-left '>Welcome back, {teacherName}</div>
                    </div>
                    :
                    <div tw='w-[300px] mt-2'>
                        <div tw='text-white text-3xl  max-w-full md:max-w-[300px] mb-8 md:mb-0  text-left '>What to Do Next</div>
                        <div tw='text-white text-xl w-[290px] mt-2'>Here’s how to start using Muzology.</div>
                    </div>
                }


                <div tw='flex flex-col mt-4 mr-10 gap-3'>
                    {buttons}
                </div>
                <div tw='grow'>
                    <div tw='relative w-full h-full'>
                        <ActionBox containerStyle={ContainerStyle}>
                            <div tw='text-left text-white pl-12'>
                                <div tw='font-semibold text-3xl text-left'>  How to Select Videos?</div>
                                <div tw='pl-5'>
                                    <div>
                                        <ul tw='text-left text-base w-full mr-auto list-disc font-normal'>
                                            <li tw='mt-1'>
                                                We’ll suggest videos in the  “Recommended Videos” section.
                                            </li>
                                            <li tw='mt-1'>
                                                We'll suggest popular videos by grade and topic (on the homepage).
                                            </li>
                                            <li tw='mt-1'>
                                                Check out the Videos section for the full catalog and to search.
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </ActionBox>
                    </div>

                </div>
            </div>
        </SlideWrapper>
    );
};

export default WhatToDo;