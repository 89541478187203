import React from 'react';
import 'twin.macro'

const Button = ({ buttonCopy, clearErrors, trigger }) => {
    return (
        <button
            tw='w-full max-w-[280px] m-auto h-[62px] p-0 py-1 bg-[#6F5FE6] font-semibold text-xl text-white rounded-2xl border-solid border-2 sm:border-white justify-center items-center gap-4 inline-flex hover:bg-indigo-600 hover:border-indigo-600 hover:shadow-lg hover:scale-100 transform transition-all duration-100 ease-in-out active:bg-indigo-700 active:border-indigo-700 active:shadow-none active:scale-100'
            type='submit'
            onClick={(event) => {
                clearErrors();
                trigger();
            }}
        >
            {buttonCopy}
        </button>
    );
};

export default Button;