import React from 'react';
import tw, { styled, css } from 'twin.macro';
import * as PropTypes from 'prop-types';
import { Modal, Button } from '../../../../../components';

const Container = styled.div(({ level }) => [
    tw`flex flex-col rounded-[27px] w-full h-full p-2 sm:p-3 min-w-full sm:min-w-[630px]`,
    level === 0 && css`background: white;`,
    level === 1 && css`background: linear-gradient(0deg, #5EC4DE, #847EDD);`,
    level === 2 && css`background: linear-gradient(0deg, #1CC8E1, #D5A04D);`,
    level === 3 && css`background: linear-gradient(0deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);`
]);

export default function ConfirmQuitDialog({ isOpen, onYes, onNo, open, level }) {
    const buttonHover = ['silver', ' #C2D7D7', '#D5A04D', '#5EC4DE'];

    return (
        <Modal isOpen={open} setIsOpen={isOpen} modalChildrenStyle={tw`w-full max-w-[330px]  sm:max-w-[630px] max-h-[330px] m-auto`} modalChildrenStyleOpacity={tw`opacity-50 blur-3xl`}>

            <Container
                level={level}
            >
                <div
                    tw='relative rounded-[27px] w-full h-full min-w-full md:min-w-[593px] min-h-[331px] bg-[#211542] px-3 sm:px-0 pt-10 font-Poppins'
                    css={[level === 0 && tw`bg-black`]}
                >
                    <h1 tw='text-white text-lg sm:text-2xl font-extrabold text-center'>Are you sure you want to quit?</h1>
                    <button
                        onClick={() => isOpen(false)}
                        tw='bg-white rounded-full cursor-pointer absolute -right-3 sm:-right-4 lg:-right-5 -top-3 sm:-top-5 lg:-top-7 w-6 h-6 sm:w-8 sm:h-8  lg:w-12 lg:h-12 flex justify-center items-center '
                    >
                        <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'>
                            <g clip-path='url(#clip0_3019_79126)'>
                                <path fill-rule='evenodd' clipRule='evenodd' d='M23.9999 0C37.2548 0 48 10.7452 48 23.9999C48 37.2548 37.2548 48 23.9999 48C10.7452 48 0 37.2548 0 23.9999C0 10.7452 10.7452 0 23.9999 0ZM23.9999 23.4071C24.3275 23.4071 24.5929 23.6725 24.5929 23.9999C24.5929 24.3275 24.3275 24.5929 23.9999 24.5929C23.6725 24.5929 23.4071 24.3275 23.4071 23.9999C23.4071 23.6725 23.6725 23.4071 23.9999 23.4071Z' fill='#D6D4D4' />
                                <path fill-rule='evenodd' clipRule='evenodd' d='M23.998 0.837891C36.7901 0.837891 47.1602 11.2079 47.1602 23.9999C47.1602 36.792 36.7901 47.1621 23.998 47.1621C11.2059 47.1621 0.835938 36.792 0.835938 23.9999C0.835938 11.2079 11.2059 0.837891 23.998 0.837891ZM23.998 23.4071C24.3255 23.4071 24.591 23.6726 24.591 23.9999C24.591 24.3275 24.3255 24.5929 23.998 24.5929C23.6706 24.5929 23.4051 24.3275 23.4051 23.9999C23.4051 23.6726 23.6706 23.4071 23.998 23.4071Z' fill='url(#paint0_angular_3019_79126)' />
                                <circle cx='24' cy='24' r='1' fill='#B6A9F0' />
                                <path d='M13 36L35 14' stroke='white' strokeWidth='4' strokeLinecap='round' />
                                <g filter='url(#filter0_d_3019_79126)'>
                                    <path d='M13 14L35 36' stroke='white' strokeWidth='4' strokeLinecap='round' />
                                </g>
                            </g>
                            <defs>
                                <filter
                                    id='filter0_d_3019_79126' x='7' y='12' width='34' height='34' filterUnits='userSpaceOnUse'
                                    color-interpolation-filters='sRGB'>
                                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                                    <feOffset dy='4' />
                                    <feGaussianBlur stdDeviation='2' />
                                    <feComposite in2='hardAlpha' operator='out' />
                                    <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                                    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3019_79126' />
                                    <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_3019_79126' result='shape' />
                                </filter>
                                <radialGradient id='paint0_angular_3019_79126' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(23.9981 24) rotate(114.401) scale(23.683)'>
                                    <stop offset='0.255208' stop-color='#C2B5FF' />
                                    <stop offset='0.790282' stop-color='#3B20B8' />
                                </radialGradient>
                                <clipPath id='clip0_3019_79126'>
                                    <rect width='48' height='48' fill='white' />
                                </clipPath>
                            </defs>
                        </svg>
                    </button>


                    <div tw='pt-10 text-center focus-visible:outlineColor[#7675e7]'>
                        <div tw='space-y-6 flex justify-center'>
                            <p tw='font-semibold text-base sm:text-lg text-white w-[426px] text-center'>Your progress will be saved.</p>
                        </div>
                        <div tw='flex justify-around sm:justify-center sm:gap-8  mt-14'>
                            <Button tabIndex={0} onClick={onNo} variant='white-dtc' tw='min-w-[120px] sm:min-w-[147px]' css={[css`  &:hover {background: ${buttonHover[level]}}`]}>
                                Don't Quit
                            </Button>
                            <Button tabIndex={0} onClick={onYes} variant='dark-blue-dtc' tw='min-w-[120px] sm:min-w-[147px]'>Quit</Button>
                        </div>
                    </div>
                </div>
            </Container>


        </Modal>
    );
}

ConfirmQuitDialog.propTypes = {
    open: PropTypes.bool,
    isOpen: PropTypes.func,
    onYes: PropTypes.func,
    onNo: PropTypes.func
};
