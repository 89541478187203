import React, { useState } from 'react';
import { styled } from 'twin.macro';
import TreeList from './TreeList';

const CategoryListContainer = styled.div`
    font-family: Avenir, serif;
    font-weight: 500;

    background-color: #3E257A;
    max-width: 300px;
    min-width: 260px;
    min-height: 100%;
    width: 260px;

    overflow-y: auto;
    height: 100%;

    user-select: none;

    @media only screen and (max-width: 1024px) {
        min-width: 190px;
        width: 190px;
    }

    //::-webkit-scrollbar {
    //    width: 0px; /* remove scrollbar space */
    //    background: transparent; /* optional: just make scrollbar invisible */
    //}

    .category-title {
        margin-top: 2rem;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;

        font-weight: 500;
        font-size: 19px;
        /* text-transform: uppercase; */
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        color: white;
        border-bottom: 1px solid;
        border-image-slice: 1;
        border-bottom-width: 1px;
        border-image-source: #fff;

        @media only screen and (max-width: 768px) {
            font-size: 16px;
        }
    }

    ::-webkit-scrollbar {
            background: none;
  
        }

    @media only screen and (max-height: 620px) {
        max-height: 550px;

        ::-webkit-scrollbar {
            background: none;
            width: 18px;
        }
        ::-webkit-scrollbar-thumb {
            background-color: #a5a5a5;
            border-radius: 20px;
            max-height: 60px;
            border: 6px solid rgba(0, 0, 0, 0);
            background-clip: padding-box;
        }
        ::-webkit-scrollbar-track {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    .positioning-div {
        display: flex;
        flex-direction: row;
        height: 2rem;
        padding: 1.5rem 0 1.5rem 0;

        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .icon-div {
        margin: 0 0.5rem 0 2rem;
        width: 30px;
    }
`;

const SideNav = (props) => {
    // static defaultProps = {
    //     categories: null,
    //     categoryNames: null,
    //     selected: null,
    //     onSelect: null,
    //     hoverImg: HoverImg,
    //     sortable: false,
    //     header: null,
    //     playlists: null
    // };
    // console.log('SideNav.props = ', props);
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         categories: props.categories,
    //         selected: props.selected,
    //         editMode: false
    //     };
    // }
    const [selected, setSelected] = useState(props.selected);

    const handleChange = (index) => {
        // console.log("SELECT", index);
        setSelected(index);
        if (props.onSelect) props.onSelect(index);
    };

    let sectionNodes = null;

    // if there are no sections, create a top level section
    let sections = props.sections;
    if (!sections) {
        sections = [
            {
                id: 1,
                title: props.title,
                items: props.categories
            }
        ];
    }

    // create the section nodes
    if (sections) {
        sectionNodes = sections.map((section, index) => {
            return (
                <TreeList
                    teacher={true}
                    key={section.title ?? index}
                    onSelect={handleChange}
                    selected={props.selected || selected}
                    section={section}
                    collapsed={section.collapsed}
                    selectable={section.selectable}
                    title={section.title}
                    items={section.items}
                    actions={section.actions}
                />
            );
        });
    } else {
        // append the category names
        // let cats = categories;
        // if (cats && props.categoryNames) {
        //     cats = props.categories.map((category) => {
        //         let name = category.name;
        //         let suffix = props.categoryNames[category.id];
        //         if (suffix) name = `${name} ${suffix}`;
        //         return {
        //             name,
        //             id: category.id
        //         };
        //     });
        // }
    }

    return (
        <CategoryListContainer className='category-list-container'>
            {props.header}
            {sectionNodes}
        </CategoryListContainer>
    );
};

export default SideNav;
