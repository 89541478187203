import React from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import { useQuizDefinition } from 'hooks/quiz';
import Modal from '../Modal/Modal';
import QuizAnswer from './QuizAnswer';
import CloseButton from './CloseButton';

const ScrollBarStyle = css`
    ::-webkit-scrollbar {
        width: 14px;
    }

    ::-webkit-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: #887be3;
    }
`;

const ScrollBarStyleParent = css`
  &::-webkit-scrollbar {
    width: 12px;
    background-color: #4D3095;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6441B9;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 6px;
  }
`;

const levelStrings = ['WARMUP', 'SILVER CHALLENGE', 'GOLD CHALLENGE', 'PLATINUM CHALLENGE'];

const AnswerKey = ({ quiz, level, parent }) => {
    /*
            level: should be 1, 2, or 3 - 1 is silver, 2 is gold, 3 is platinum
         */

    // find the questions for the level
    const questions = quiz?.questions.filter((q) => q.level === level - 1);

    return (
        <div tw='flex relative bg-white rounded flex-col justify-center font-sans' style={{ minWidth: '800px' }} css={[parent && tw`bg-[#3d257a] font-Poppins`]}>
            <div tw='bg-[#3B2A7E] py-2.5 text-center text-white text-lg font-extrabold ' css={[parent && tw`bg-gradient-to-r to-[#3d257a] from-[#3d257a] font-semibold text-xl`]}>
                {`${levelStrings[level]}: ${quiz.title}`}
            </div>

            <div tw='bg-white px-6 pb-5 overflow-y-scroll' style={{ maxHeight: '800px' }} css={[parent ? ScrollBarStyleParent : ScrollBarStyle, parent && tw`bg-[#3d257a]`]}>
                {questions?.map((question) => <QuizAnswer key={question.id} question={question} parent={parent} />)}
            </div>
        </div>
    );
}
    ;

AnswerKey.propTypes = {
    anyProp: PropTypes.any
};



export const AnswerKeyModal = ({ level, video, isOpen, setIsOpen, parent }) => {
    const quiz = useQuizDefinition(video.quiz_id);
    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            modalChildrenStyle={tw`max-w-[929px] mx-auto relative`}
        >
            <AnswerKey quiz={quiz} level={level} setShowModal={setIsOpen} parent={parent} />
            {parent ?
                <div
                    tw='absolute top-3 right-5 shadow-md cursor-pointer z-20'
                    onClick={() => setIsOpen(false)}>
                    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path fillRule='evenodd' clipRule='evenodd' d='M0.425404 0.444056C0.697873 0.171371 1.06737 0.0181844 1.45264 0.0181844C1.83791 0.0181844 2.20741 0.171371 2.47988 0.444056L7.99092 5.96115L13.502 0.444056C13.636 0.305132 13.7963 0.194321 13.9736 0.11809C14.1508 0.0418587 14.3415 0.0017332 14.5344 5.49193e-05C14.7273 -0.00162336 14.9187 0.0351788 15.0972 0.108315C15.2758 0.18145 15.438 0.289455 15.5744 0.426026C15.7109 0.562597 15.8187 0.725 15.8918 0.903759C15.9649 1.08252 16.0016 1.27405 15.9999 1.46718C15.9983 1.66032 15.9582 1.85118 15.882 2.02864C15.8059 2.2061 15.6952 2.36661 15.5564 2.50078L10.0454 8.01787L15.5564 13.535C15.8211 13.8093 15.9675 14.1767 15.9642 14.5581C15.9609 14.9395 15.8081 15.3043 15.5387 15.574C15.2693 15.8437 14.9049 15.9966 14.524 15.9999C14.143 16.0033 13.776 15.8566 13.502 15.5917L7.99092 10.0746L2.47988 15.5917C2.20585 15.8566 1.83883 16.0033 1.45787 15.9999C1.07691 15.9966 0.712492 15.8437 0.443103 15.574C0.173714 15.3043 0.0209088 14.9395 0.0175983 14.5581C0.0142879 14.1767 0.160738 13.8093 0.425404 13.535L5.93645 8.01787L0.425404 2.50078C0.153018 2.22801 0 1.85811 0 1.47242C0 1.08673 0.153018 0.716823 0.425404 0.444056Z' fill='#9D91BB' />
                    </svg>
                </div> :
                <CloseButton
                    tw='absolute -top-4 -right-4'
                    onClick={() => setIsOpen(false)}
                />
            }
        </Modal>
    );
};

export default AnswerKey;
