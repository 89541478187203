import React, { useMemo } from 'react';
import tw, { css } from 'twin.macro';
import { VideoCard, VideoViewProgress } from 'components';
import PropTypes from 'prop-types';
import { useLessons, useVideos } from 'state/CatalogState';
import { useFavoriteVideos, useChildFavoriteVideos } from 'state/Student/VideoStats';
import VideoSlider from 'components/VideoSlider';
import { useStudentStats, useVideosWithStats } from 'state/Student/VideoStats';
import { StandardVideoGrid } from '../../../../styles';
import { useMedia } from 'react-use';

const PlaylistVideoRow = ({ group, title, groupTitle, favorites, allVideos, student_id }) => {
    // create videos list
    const findLesson = (lessons, lessonId) => Object.values(lessons).find(l => l.id === lessonId);
    const lessons = useLessons();
    let groupVideos = group?.lessons.map(lessonId => findLesson(lessons, lessonId)).filter(l => l);
    const videos = useVideos();
    const favoriteVideos = useFavoriteVideos();
    const childFavoriteVideos = useChildFavoriteVideos(student_id);
    const videoLibraryWithStats = useVideosWithStats();
    //let header = `${groupTitle} - ${title}`;
    const hideProgress = allVideos || favorites;
    let header = `${title}`;
    let nullFavorite = false;
    const ipad = useMedia('(max-width: 1025px)');
    // if the selected playlist is favorites change the videos to favorite videos 
    // update header
    if (favorites) {
        groupVideos = favoriteVideos;
        header = title;
        if (student_id) groupVideos = childFavoriteVideos;
        if (groupVideos.length === 0) {
            header = `There’s a lot to love about math! ${String.fromCodePoint('0x1f603')} Favorite videos and easily access them here. `;
            nullFavorite = true;
        }
    }
    if (allVideos) {
        groupVideos = videoLibraryWithStats.sort((a, b) => a.title.localeCompare(b.title));
        // if (student_id) groupVideos = videos;
        header = '';
    }

    const childStats = useStudentStats(student_id);
    // filter videos that are unlocked and add the stats that match that video
    const childVideosWithStats = useMemo(() => {
        return videoLibraryWithStats.reduce((acc, video) => {
            if (childStats)
                childStats.forEach((stat) => {
                    if (stat.video_id === video.id)
                        acc.push({
                            ...video,
                            stats: childStats ? childStats.find((s) => s.video_id === video.id) ?? null : null
                        });
                });
            return acc;
        }, []);
    }, [childStats, videoLibraryWithStats, groupVideos]);

    const videoCards = groupVideos?.map((video, index) => {
        // for different playlist types (eg favorites) and users pass down a different video prop 
        let propVideo = videos?.find(v => v.id === video.video);
        if (favorites || allVideos) propVideo = video;
        if (student_id) propVideo = childVideosWithStats.find(v => v.id === video.video) ?? videos.find(v => v.id === video.video);
        if (favorites && student_id) propVideo = video;
        if (allVideos && student_id) propVideo = video;
        if (!video) return null;
        if (!propVideo) return null;
        return (
            <div
                key={video.id}
                id={index === 1 ? 'video-page-tour-video-thumbnail' : null}
                className='video-card-container'
                data-video-index={index}
            >
                <VideoCard
                    data-video-id={video.video}
                    data-video-title={video.title}
                    style={{ marginBottom: 30 }}
                    index={index}
                    video={propVideo}
                    showTitle={true}
                    libraryView
                    showViewCount={true}
                    showRecords={true}
                    disableHover={ipad ? true : false}
                />
            </div>
        );
    });

    return (
        <>
            <div tw='px-10 hidden md:block'>
                <div
                    tw='text-white text-xl font-semibold flex flex-col xl:flex-row gap-4 justify-between items-start xl:items-center'
                    css={[nullFavorite && tw`justify-center w-full`]}>
                    <div>{header} </div>
                    {!hideProgress && <VideoViewProgress videos={groupVideos} student_id={student_id} favorites={favorites} />}
                </div>
                <StandardVideoGrid tw='mt-6'>
                    {videoCards}
                </StandardVideoGrid>
            </div>

            <div tw='px-5 md:hidden'>
                <div
                    tw='text-white text-xl font-semibold flex flex-col xl:flex-row gap-2 justify-between items-start xl:items-center'
                    css={[nullFavorite && tw`justify-center w-full`]}>
                    <div>{header}</div>
                    {!hideProgress && <VideoViewProgress videos={groupVideos} student_id={student_id} favorites={favorites} />}
                </div>


                <div tw='mt-5'>
                    <StandardVideoGrid tw='mt-6'>
                        {videoCards}
                    </StandardVideoGrid>
                </div>
            </div>
        </>

    );
};

PlaylistVideoRow.propType = {
    title: PropTypes.string,
    videos: PropTypes.array
};

export default PlaylistVideoRow;