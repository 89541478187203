import React from 'react';
import BalanceText from 'react-balance-text';
import tw, { css } from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { wrapTitleParens } from 'lib/string';
const CardContainer = tw.div`cursor-pointer bg-contain bg-no-repeat rounded-lg relative overflow-hidden`;

const LargeVideoCard = ({ video, action }) => {
    const navigate = useNavigate();
    const newVideo = action === 'new-video'
    if (!video)
        return null;
    const { url_thumbnail } = video;
    let verb = action === 'unlock-video' ? 'Unlock' : 'Watch';
    if (newVideo) verb = '';

    const handleClick = () => navigate(`/video/${video.id}`);

    return (
        <>
            <CardContainer
                tw='hidden md:flex '
                css={[
                    css`
                        background-image: url(${url_thumbnail});
                        background-position: center;
                        background-size: cover;

                        &:hover {
                            .play-button {
                                background: #FEEA50;
                            }
                        }
                    `,
                    tw`hover:[box-shadow:0 0 20px 20px #ffffff22] active:[box-shadow:0 0 20px 20px #ffffff44]`,
                    newVideo ? tw`min-h-[280px] w-[55%]` : tw`min-h-[342px]  w-full`
                ]}
                onClick={handleClick}
            >
                <div tw='flex items-end w-full lg:relative relative'>
                    <div tw='flex items-end w-full min-h-[60%] bg-gradient-to-b from-[#1C005700] to-[#3E257AE5]'>
                        <div tw='w-full flex items-center gap-4 p-2 lg:p-5'>
                            <div
                                tw='cursor-pointer min-w-[46px] min-h-[46px] bg-white rounded-full flex items-center justify-center mr-1 pl-1'
                                className='play-button'
                                onClick={handleClick}
                            >
                                <div>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='17' fill='none'>
                                        <path
                                            fill='#7959FA'
                                            d='M14.5 7.634a1 1 0 0 1 0 1.732L1.75 16.727a1 1 0 0 1-1.5-.866V1.14a1 1 0 0 1 1.5-.866L14.5 7.634Z' />
                                    </svg>
                                </div>
                            </div>

                            <div
                                tw='text-[1.35rem] lg:text-[1.15rem] xl:text-[1.35rem] text-white font-semibold w-full ml-0'
                            >
                                <BalanceText>{verb} "{video.title}"</BalanceText>
                            </div>
                        </div>

                    </div>
                    {newVideo &&
                        <div tw='absolute top-3.5 left-5'>
                            <div tw='bg-[#1C0057] border-white border-solid border-[1px] w-[110px] h-[30px] flex justify-center items-center rounded-lg text-white text-center'>
                                New Video
                            </div>
                        </div>
                    }
                </div>
            </CardContainer>

            <div
                tw='flex md:hidden w-full min-h-[230px]'
                css={[
                    css`
                        background-image: url(${url_thumbnail});
                        background-position: center;
                        background-size: cover;
                    `
                ]}
                onClick={handleClick}
            >
                <div tw='flex items-end w-full relative'>
                    <div tw='flex items-end w-full min-h-[60%] bg-gradient-to-b from-[#1C005700] to-[#3E257AE5]'>
                        <div tw='flex flex-wrap items-center p-2'>

                            <div
                                tw='cursor-pointer w-[46px] h-[46px] bg-white rounded-full flex items-center justify-center '
                                onClick={handleClick}
                            >
                                <div>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='15' height='17' fill='none'>
                                        <path
                                            fill='#7959FA'
                                            d='M14.5 7.634a1 1 0 0 1 0 1.732L1.75 16.727a1 1 0 0 1-1.5-.866V1.14a1 1 0 0 1 1.5-.866L14.5 7.634Z' />
                                    </svg>
                                </div>
                            </div>

                            <div tw='text-[1.2rem] text-white font-normal max-w-[270px] ml-2'>
                                <BalanceText>{verb} "{wrapTitleParens(video?.title)}"</BalanceText>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LargeVideoCard;