import React from 'react';
import { styled } from 'twin.macro';
import { useVideoAchievements, useVideoStats, useVideosWithStats } from '../../../../../state/Student/VideoStats';
import GeneralStats from './GeneralStats';
import RecentAwards from './RecentAwards';

//<editor-fold desc="Style">
const Container = styled.div`
    display: flex;
`;

const MaxWidthContainer = styled.div`
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 40px 0px;
    @media only screen and (max-width: 500px) {
        padding: 0;
    }
`;

const MarginAndFlexContainer = styled.div`
    /* margin: 0 32px; */
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 1100px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
    }
`;

const AchievementsContainer = () => {
    const allVideos = useVideosWithStats();
    const videoStats = useVideoStats();
    const achievements = useVideoAchievements();
    // console.log('achievements', achievements);

    return (
        <Container >
            <MaxWidthContainer>
                <MarginAndFlexContainer>
                    <GeneralStats achievements={achievements} videoStats={videoStats} />
                    <RecentAwards allVideos={allVideos} />
                </MarginAndFlexContainer>
            </MaxWidthContainer>
        </Container>
    );
};

export default AchievementsContainer;
