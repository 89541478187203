// utils/activityState.js

// Constants for different activity states
export const ACTIVE_STATE_LOADING = -1;  // Loading/initial state
export const ACTIVE_STATE_NONE = 0;      // No activity
export const ACTIVE_STATE_SEMI = 1;      // Some activity but not fully active
export const ACTIVE_STATE_FULL = 2;      // Fully active state

/**
* Calculates the activity state based on video statistics
* @param {Object} videoStats - Object containing video viewing and quiz statistics
* @returns {number} - Returns activity state (-1, 0, 1, or 2)
*/
export const calculateActivityState = (videoStats) => {
    // Return loading state if no stats are provided
    if (!videoStats) {
        return ACTIVE_STATE_LOADING;
    }

    // Initialize counters for total views and quiz attempts
    let totalViewCount = 0;
    let totalAttempts = 0;

    // Iterate through each video entry
    for (const key in videoStats) {
        if (videoStats.hasOwnProperty(key)) {
            // Add up total views for this video
            totalViewCount += videoStats[key].view_count;
            const quizLevels = videoStats[key].quiz_levels;

            // Count attempts for each quiz level (excluding level 0)
            for (const levelKey in quizLevels) {
                if (quizLevels.hasOwnProperty(levelKey) && parseInt(levelKey) > 0) {
                    totalAttempts += quizLevels[levelKey].attempts;
                }
            }
        }
    }

    // Determine activity state based on total views and attempts
    if (totalViewCount >= 3 || totalAttempts >= 2) {
        return ACTIVE_STATE_FULL;      // Fully active if 3+ views or 2+ attempts
    } else if (totalViewCount >= 1 || totalAttempts >= 1) {
        return ACTIVE_STATE_SEMI;      // Semi-active if 1-2 views or 1 attempt
    }
    return ACTIVE_STATE_NONE;          // No activity if no views or attempts
};

/**
* Gets all activity-related states for a video
* @param {Object} videoStats - Object containing video viewing and quiz statistics
* @returns {Object} Object containing all activity states
*/
export const getActivityStates = (videoStats) => {
    const activeState = calculateActivityState(videoStats);
    return {
        activeState,                                        // Raw activity state
        isLoading: activeState === ACTIVE_STATE_LOADING,    // Whether in loading state
        nullContainer: activeState === ACTIVE_STATE_NONE,   // Whether no activity
        semiActiveState: activeState === ACTIVE_STATE_SEMI  // Whether in semi-active state
    };
};