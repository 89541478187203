import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';
import { useLoginStreak, useUser } from 'state/User/UserState';
import { useVideos } from 'state/CatalogState';
import { LargeVideoCard } from 'components';
import { useNotifications } from '../../../../../context/NotificationProvider';
import { maybePlural } from 'lib/string';
import GettingStartedSlider from 'components/GettingStartedSlider/Student/GettingStartedSlider';
import CurrentAssignment from './CurrentAssignment';
import CompleteAssignmentCard from 'components/LargeQuizCard/Student/CompleteAssignmentCard';

const HeaderContainer = ({ assignment, children, nullState }) => {
    const login_streak = useLoginStreak();
    const navigate = useNavigate();
    const videos = useVideos();
    const newVideo = videos.find((v) => v.id === 'f028e2e5-af95-4aba-99f8-971eb3129e75');
    const user = useUser();

    if (!videos) return null;

    return (
        <div tw='pt-8'>
            {login_streak && <div tw='w-full text-white text-sm flex '>
                <div tw='bg-gradient-to-b from-[#0CC6E1] to-[#4C3FC1] font-medium rounded-xl py-0.5 px-2.5 mr-2'>
                    🔥 Best Streak: {login_streak?.longest} {maybePlural('DAY', login_streak?.longest).toUpperCase()}
                </div>
                <div tw='bg-gradient-to-b from-[#E637A8] to-[#E68137] font-medium rounded-xl py-0.5 px-2.5 ml-2'>
                    Current Streak: {login_streak?.current} {maybePlural('DAY', login_streak?.current).toUpperCase()}
                </div>
            </div>}

            <div tw='text-white text-3xl pt-5 font-semibold'>
                {nullState ? 'Welcome' : 'Welcome Back,'} {user.first_name}!
            </div>
            {nullState &&
                <div tw='mt-7'>
                    <GettingStartedSlider />
                </div>
            }
            {assignment &&
                <div tw='flex flex-col-reverse xl:flex-row mt-5 gap-10 h-full xl:h-[320px]'>
                    <CompleteAssignmentCard assignment={assignment}/>
                    <CurrentAssignment assignment={assignment} />
                </div>
            }
        </div>
    );
};

export default HeaderContainer;
