import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import Slider from 'react-slick';
import styled from 'styled-components';
import WaysToUse from './Slides/WaysToUse';
import StudentExperience from './Slides/StudentExperience';
import WhatToDo from './Slides/WhatToDo';
import { useTeacher } from 'state/User/UserState';
import { useClasses, useStudents } from 'state/Teacher/ClassState';
import { useAssignments } from 'state/Student/AssignmentsState';

const StyledSlider = styled(Slider)`
.slick-active  {
    /* opacity: .75; */
    color: yellow !important;
}
`;


const GettingStartedSlider = ({ recent_videos, setPlayTutorialVideo }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    console.log("🚀 ~ GettingStartedSlider ~ currentSlide:", currentSlide)
    const settings = {
        dots: true,
        accessibility: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: true,
        // autoplaySpeed: 5000,
        arrows: false,
        beforeChange: (prev, next) => {
            setCurrentSlide(next);
        },
        appendDots: dots => (
            <div tw='bottom-1.5'>
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
        customPaging: index => (
            <div tw='w-4 h-4 rounded-full bg-[#D9D9D9] [box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.30)]' css={[currentSlide === index && tw`bg-[#1C0057]`]} />
        )
    };

    const teacher = useTeacher();
    const teacherName = teacher.name
    const classes = useClasses();
    const students = useStudents();
    const assignments = useAssignments();

    const classCreated = useMemo(() => classes?.length !== 0, [classes]);
    const studentsAdded = useMemo(() => students?.length !== 0, [students]);
    const assignmentCreated = useMemo(() => assignments?.length !== 0, [assignments]);
    const nonNullState = classCreated && studentsAdded && assignmentCreated;
    
    if (nonNullState) {
        return (
            <StyledSlider {...settings} >
                <WhatToDo recent_videos={recent_videos} nonNullState={nonNullState} classCreated={classCreated} studentsAdded={studentsAdded} teacherName={teacherName} assignmentCreated={assignmentCreated} setPlayTutorialVideo={setPlayTutorialVideo} />
                <WaysToUse nonNullState={nonNullState} teacherName={teacherName} />
                <StudentExperience />
            </StyledSlider>
        )
    }

    return (
        <StyledSlider {...settings} >
            <WhatToDo recent_videos={recent_videos} nonNullState={nonNullState} classCreated={classCreated} studentsAdded={studentsAdded} teacherName={teacherName} assignmentCreated={assignmentCreated} setPlayTutorialVideo={setPlayTutorialVideo} />
            <WaysToUse nonNullState={nonNullState} teacherName={teacherName} />
            <StudentExperience />
        </StyledSlider>
    )
};

GettingStartedSlider.propTypes = {
    anyProp: PropTypes.any,
}

export default GettingStartedSlider;

