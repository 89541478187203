import React, { useState, useRef } from 'react';
import tw, { css, styled } from 'twin.macro';
import BackgroundImage from 'lib/components/BackgroundImage';
import TimesTablesBackground from 'static/images/background_images/TimesTablesCreateMathPlaylistBackground.jpg';
import { LoadingOverlay } from '../../../lib/components/loading';
import { PrivacyTos, GradeChoices, OnBoardingInput, Header, Button, TimesTableImages } from '../Components'
import { onSubmitDTCUser, BoxShadowCss } from '../Helpers';
import { useProduct } from 'state/User/UserState';
import { useForm, Controller } from 'react-hook-form';
import { useUserAction } from 'state/User/UserState';
import { useAnalytics } from '../../../analytics/AnalyticsProvider';
import { useUser } from 'state/User/UserState';
import { useMedia } from 'react-use';

const TimesTable = ({setOnboardState, onboardFlag}) => {
    // get the user
    const user = useUser();
    //  update user state 
    const { updateUser } = useUserAction();
    // use product to see if times tables user
    const timesTableProduct = useProduct('times-tables');
    //  default form values 
    const defaultValues = {
        terms: false,
        user_name: user.username ?? user.email
    };
    // useForm setup for onboard form with react-hook-form
    const { register, handleSubmit, watch, errors: formErrors, setError, clearErrors, trigger, control, setValue } = useForm({ defaultValues });
    // analytics to track the onboarding 
    const { track } = useAnalytics('Setup Child Profile', 'Onboarding', { step: 'Setup Child Profile' });
    // grade selection state
    const [selectedGrade, setGrade] = useState(null);
    // useMedia query to determine if on small device. If so get grade from data form instead of selectedGrade state
    const isSmall = useMedia('(max-width: 639px)');
    // loading state while saving the form
    const [saving, setSaving] = useState(false);
    //header string
    const header = 'Create Your Child’s Account'

    // helper to handle grade selection on mobile 
    // TODO: add comments for why its shouldDirty: true
    const selectGradeMobile = (data) => {
        setValue('grade', data, { shouldDirty: true });
        setGrade(data);
        clearErrors('grade');
    };

    // store grade choices in references
    const gradeChoice = useRef({});
    gradeChoice.current = watch('grade', '');

    // parent submits onboard form
    const onSubmit = (data) => {
        onSubmitDTCUser(data, {
            timesTableProduct,
            setSaving,
            setError,
            track,
            updateUser,
            setOnboardState,
            onboardFlag,
            isSmall,
            selectedGrade
        });
    };

    return (
        <div tw='relative w-full mx-auto max-w-[380px] sm:max-w-[600px] md:max-w-[660px] lg:max-w-full overflow-auto'>
            <div tw='bg-white rounded-lg py-5 px-5 sm:px-6 xl:px-8 font-Poppins relative overflow-hidden'>
                <BackgroundImage image={TimesTablesBackground} tw=' hidden sm:block absolute inset-0 rounded-lg' />
                <LoadingOverlay show={saving} fixed={false} background='#35107688' />

                <div tw='w-full  flex flex-col lg:flex-row items-center lg:items-stretch gap-4 lg:gap-0 pt-0 lg:pt-5'>

                    {/* Right Column - Contains Image Elements and Header Copy */}
                    <div tw='w-full lg:w-[44%] relative'>
                        <Header timesTableProduct={true} teacher={false} header={header}/>
                        <TimesTableImages />
                    </div>

                    {/* Left Column - Contains Form Elements */}
                    <div tw='w-full lg:w-[56%] relative  '>
                        <div
                            tw='rounded-lg py-0 sm:py-9 bg-white font-["Poppins"] w-full px-0 sm:px-4 lg:px-6 xl:px-9 max-w-[600px] lg:max-w-[700px] overflow-clip z-20 lg:-mt-5 mx-auto'
                            css={[BoxShadowCss]}
                        >
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {/* Username Input */}
                                <div tw='mb-4 md:mb-5'>
                                    <OnBoardingInput label={'Username'} register={register} name={'user_name'} required={true} />
                                    {formErrors.username?.message &&
                                        <div tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-1'>{formErrors.username?.message}</div>}
                                    {formErrors.user_name &&
                                        <div tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-1'>Please enter a username for your child.</div>}
                                </div>
                                {/* Password Input */}
                                <div tw='mb-4 md:mb-5'>
                                    <OnBoardingInput label={'Password'} register={register} name={'password'} required={true} password={true}/>
                                    {formErrors.password && <div
                                        tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base mt-1'>{formErrors.password.message?.length ? formErrors.password.message : 'Password must have at least 6 characters'}</div>}
                                </div>
                                {/* Grade Choices for Parents and Teacher - Desktop and Mobile DropDown */}
                                <GradeChoices timesTableProduct={timesTableProduct} gradeChoice={gradeChoice} teacher={false} register={register} clearErrors={clearErrors} formErrors={formErrors} selectGradeMobile={selectGradeMobile} selectedGrade={selectedGrade} />
                                <div tw='mb-4 md:mb-5 pt-4'>
                                    <OnBoardingInput label={'Child’s Name (optional)'} register={register} name={'name'} teacher={false} required={false} />
                                </div>

                                {/* Privacy Policy and TOS */}
                                <div tw='pt-4'>
                                    <PrivacyTos register={register} formErrors={formErrors} />
                                </div>
                                <div tw='w-full flex justify-center  pt-7 md:pt-[43px]'>
                                    <Button buttonCopy={'Continue'} clearErrors={clearErrors} trigger={trigger} />
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TimesTable;