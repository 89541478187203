import React from 'react';
import RecordImage from './RecordImage';

export default {
    title: 'RecordImage',
    component: RecordImage
};

const Template = (props) => <RecordImage {...props} />;

export const Default = Template.bind({});
Default.args = {
};

export const Silver = Template.bind({});
Silver.args = {
    level: 1,
    text: 'Silver'
};


export const Gold = Template.bind({});
Gold.args = {
    level: 2,
    text: 'Gold'
};

export const Platinum = Template.bind({});
Platinum.args = {
    level: 3,
    text: 'Platinum'
};

export const Gold30 = Template.bind({});
Gold30.args = {
    level: 2,
    value: 30,
    text: '30%'
};



