import React, { useState } from 'react';
import { AnswerKey, ChallengeButton, Modal } from 'components';
import Button from 'components/Button.js';
import tw from 'twin.macro';
import { styled } from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { useLesson } from '../../../../state/CatalogState';
import { formatPlural } from '../../../../lib/string';
import { AnswerKeyModal } from '../../../../components/AnswerKey/AnswerKey';


const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    font-family: Avenir, sans-serif;
    margin: 30px 0 0;
    position: relative;

    .header {
        color: white;
        font-size: 1.5rem;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        align-items: stretch;
        width: 100%;

        .title {
            color: #ffffff;
            margin-right: 20px;
            font-family: Avenir, serif;
            font-weight: 800;
            font-size: 32px;
            line-height: 44px;
            margin: 0px;

            @media only screen and (max-width: 768px) {
                font-size: 15px;
            }
        }

        .sub-header {
            color: white;
            font-family: Avenir, serif;
            font-weight: 800;
            font-size: 18px;
            line-height: 25px;
            margin: 0px;
        }

        .description {
            font-weight: 600;
            font-size: 18px;
            line-height: 25px;
            margin: 15px 0 0;
        }
    }
`;

const ChallengeButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 23px 0 0;
    position: relative;
    width: 100%;

    @media only screen and (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .button {
        width: 313px;
        height: 64px;
        border-radius: 32px;
        background-color: #e637a8;
        border: none;
        color: #ffffff;
        font-family: Avenir, serif;
        font-size: 25px;
        font-weight: 500;

        @media only screen and (max-width: 768px) {
            width: 225px;
            height: 60px;
            font-size: 16px;
        }
    }
`;




const TeacherChallengeButtons = ({ video }) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [answerKeyLevel, setLevel] = useState(0);

    const quiz_id = video?.quiz_id;
    const lesson = useLesson(video?.lesson_id);
    if (!video) {
        console.error('VideoInfoTeacher - missing video');
        return null;
    }

    // const lastView = video.stats ? moment.utc(video.stats?.last_viewed).local() : null;

    const stats = video?.stats ?? null;
    const videoViews = stats?.view_count ?? null;

    return (
        <Container>

            {quiz_id && (
                <ChallengeButtonsContainer id='video-session-challenges'>
                    <ChallengeButton
                        level={1}
                        onChallenge={() => navigate(`/quiz/${quiz_id}/?level=1`)}
                        teacher
                        setShowAnswerKey={setShowModal}
                        setLevel={setLevel}
                    >
                        SILVER CHALLENGE
                    </ChallengeButton>

                    <ChallengeButton
                        level={2}
                        onChallenge={() => navigate(`/quiz/${quiz_id}/?level=2`)}
                        teacher
                        setShowAnswerKey={setShowModal}
                        setLevel={setLevel}
                    >
                        GOLD CHALLENGE
                    </ChallengeButton>

                    <ChallengeButton
                        level={3}
                        onChallenge={() => navigate(`/quiz/${quiz_id}/?level=3`)}
                        teacher
                        setShowAnswerKey={setShowModal}
                        setLevel={setLevel}
                    >
                        PLATINUM CHALLENGE
                    </ChallengeButton>
                </ChallengeButtonsContainer>
            )}

            <AnswerKeyModal
                isOpen={showModal}
                setIsOpen={setShowModal}
                video={video}
                level={answerKeyLevel}
            />
        </Container>
    );
};

export default TeacherChallengeButtons;
