import React, { useEffect, useRef, useMemo, useState } from 'react';
import { Route, NavLink, useNavigate, useLocation, Routes, Navigate } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';
import { createMuzologyAPIURL } from 'utils/url';
import { getQueryStringValue } from 'lib/util';
import { Modal } from 'components';
import BackgroundImage from 'lib/components/BackgroundImage';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { useSiteConfig } from 'context/SiteProvider';
import { MUZOLOGY_WEBSITE_URL } from 'config';
import LogoSVG from 'static/images/logo/logo-2025-tms.svg';
import { useQuery } from 'hooks/useQuery';
import HeartSvg from './HeartSvg';
// import LogoSVG from './TMLogo.png';
import HeartMotionContainer from './HeartMotionContainer';

import background_flare from './background_flare.png';
import LoginNew from './Tabs/Login/LoginNew';
import { ClassJoinCode, ForgotPassword, SignUp, ResetPassword } from './Modals';

const NavigationBar = ({ enableJoinCodes = true }) => {
    const location = useLocation();
    return (
        <div tw='bg-transparent w-full z-[10000] pt-2.5 h-[4.6rem]' height={location.pathname === '/login' ? '100%' : 'default'}>
            <div tw='h-full flex flex-col sm:flex-row items-center justify-between sm:justify-between px-10 sm:px-3.5 md:px-20'>
                <div tw='flex items-center justify-center'>
                    <a href={MUZOLOGY_WEBSITE_URL}>
                        <img src={LogoSVG} alt='Muzology' tw='w-full max-w-[203px]' />
                    </a>
                </div>
            </div>
        </div>
    );
};

const loginTabs = [
    {
        name: 'Log in',
        path: '/login',
        svg: (
            <svg xmlns='http://www.w3.org/2000/svg' width='38' height='38' fill='none'>
                <path
                    fill='#fff'
                    fillRule='evenodd'
                    d='M17.416 31.667c0-.875.71-1.584 1.584-1.584h5.067c1.356 0 2.278 0 2.99-.06.695-.056 1.05-.159 1.297-.285a3.166 3.166 0 0 0 1.384-1.384c.126-.247.229-.602.286-1.296.058-.713.06-1.635.06-2.991V13.933c0-1.356-.002-2.278-.06-2.99-.057-.695-.16-1.05-.286-1.297a3.166 3.166 0 0 0-1.384-1.384c-.247-.126-.602-.23-1.297-.286-.712-.058-1.634-.06-2.99-.06H19a1.583 1.583 0 0 1 0-3.166h5.132c1.274 0 2.326 0 3.183.07.89.073 1.708.229 2.477.62a6.333 6.333 0 0 1 2.767 2.768c.392.769.548 1.587.62 2.477.07.856.07 1.908.07 3.183v10.264c0 1.275 0 2.326-.07 3.183-.072.89-.228 1.708-.62 2.477a6.333 6.333 0 0 1-2.767 2.768c-.769.391-1.587.547-2.477.62-.857.07-1.909.07-3.183.07H19a1.583 1.583 0 0 1-1.584-1.583Z'
                    clipRule='evenodd'
                />
                <path
                    fill='#fff'
                    d='M6.333 20.583a1.583 1.583 0 0 1 0-3.166h13.594L17.88 15.37a1.583 1.583 0 0 1 2.24-2.24l4.75 4.75c.618.619.618 1.621 0 2.24l-4.75 4.75a1.583 1.583 0 0 1-2.24-2.24l2.047-2.047H6.333Z'
                />
            </svg>
        )
    },
    {
        name: 'Enter Class Join Code',
        path: '/join',
        svg: (
            <svg xmlns='http://www.w3.org/2000/svg' width='38' height='38' fill='none'>
                <path
                    fill='#fff'
                    fillRule='evenodd'
                    d='M9.5 4.75h19a6.333 6.333 0 0 1 6.333 6.333v15.834A6.333 6.333 0 0 1 28.5 33.25h-19a6.333 6.333 0 0 1-6.333-6.333V11.083A6.333 6.333 0 0 1 9.5 4.75Zm19 3.167h-19a3.167 3.167 0 0 0-3.167 3.166v15.834A3.167 3.167 0 0 0 9.5 30.083h19a3.167 3.167 0 0 0 3.167-3.166V11.083A3.167 3.167 0 0 0 28.5 7.917Zm-19 7.916a4.75 4.75 0 1 1 9.5 0 4.75 4.75 0 0 1-9.5 0Zm6.333 0a1.583 1.583 0 1 0-3.166 0 1.583 1.583 0 0 0 3.166 0ZM28.5 12.667c0-.875-.709-1.584-1.583-1.584H23.75l-.185.011a1.584 1.584 0 0 0 .185 3.156h3.167l.184-.01a1.583 1.583 0 0 0 1.4-1.573Zm-1.583 4.75a1.583 1.583 0 0 1 .184 3.156l-.184.01H23.75a1.583 1.583 0 0 1-.185-3.156l.185-.01h3.167Zm1.583 7.916c0-.874-.709-1.583-1.583-1.583H11.083l-.184.01a1.583 1.583 0 0 0 .184 3.157h15.834l.184-.011a1.584 1.584 0 0 0 1.4-1.573Z'
                    clipRule='evenodd'
                />
            </svg>
        )
    },
    {
        name: <div>How to <br /> Sign Up</div>,
        path: '/sign_up',
        svg: (
            <svg
                width='38' height='38' viewBox='0 0 38 38' fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.2499 25.3334C11.6266 25.3334 9.49992 27.46 9.49992 30.0834C9.49992 30.9578 10.2088 31.6667 11.0833 31.6667H26.9166C27.791 31.6667 28.4999 30.9578 28.4999 30.0834C28.4999 27.46 26.3733 25.3334 23.7499 25.3334H14.2499ZM6.33325 30.0834C6.33325 25.7111 9.87766 22.1667 14.2499 22.1667H23.7499C28.1222 22.1667 31.6666 25.7111 31.6666 30.0834C31.6666 32.7067 29.5399 34.8334 26.9166 34.8334H11.0833C8.4599 34.8334 6.33325 32.7067 6.33325 30.0834Z'
                    fill='white'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M18.9999 6.33335C16.3766 6.33335 14.2499 8.46 14.2499 11.0834C14.2499 13.7067 16.3766 15.8334 18.9999 15.8334C21.6233 15.8334 23.7499 13.7067 23.7499 11.0834C23.7499 8.46 21.6233 6.33335 18.9999 6.33335ZM11.0833 11.0834C11.0833 6.7111 14.6277 3.16669 18.9999 3.16669C23.3722 3.16669 26.9166 6.7111 26.9166 11.0834C26.9166 15.4556 23.3722 19 18.9999 19C14.6277 19 11.0833 15.4556 11.0833 11.0834Z'
                    fill='white'
                />
            </svg>
        )
    }
];

const LoginContainer = ({ props }) => {
    const [JoinCodeModal, setJoinCodeModal] = useState(false);
    const [ForgotPasswordModal, setForgotPasswordModal] = useState(false);
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const [SignUpModal, setSignUpModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const siteConfig = useSiteConfig();
    const enableJoinCodes = siteConfig?.login_page?.enable_join_codes ?? true;
    const { reset } = useQuery();
    const location = useLocation();
    const navigate = useNavigate();
    console.log('🚀 ~ LoginContainer ~ location:', location);

    useEffect(() => {
        if(location.pathname === '/join') setJoinCodeModal(true);
    }, [location]);

    useEffect(() => {
        if (reset) {
            setResetPasswordModal(true);
        }
    }, [location]);

    const handleCloseJoinCodeModal = () => {
        setJoinCodeModal(false);
        navigate('/login');
    };

    // const menu = useMemo(() => {
    //     return loginTabs.map((tab) => {
    //         return (
    //             <NavLink
    //                 replace={true}
    //                 to={tab.path}
    //                 key={tab.path}
    //                 tw='z-10 focus:z-20 cursor-pointer hover:bg-[#2A0A67] hover:text-white flex flex-col items-center justify-center bg-[#494949] h-1/3 min-h-[131px] p-6'
    //                 onClick={() => setSelectedItem(tab.path)}
    //                 css={[
    //                     tab.path === location.pathname && tw`bg-[#6E60E6] text-white hover:bg-[#6E60E6] cursor-auto`,
    //                     tab.path === '/login' && tw`rounded-tl-[20px] border-b-[1px] border-white`,
    //                     tab.path === '/join' && tw`border-b-[1px] border-white`,
    //                     tab.path === '/sign_up' && tw`rounded-bl-[20px]`
    //                 ]}
    //             >
    //                 <div tw='mt-1'>{tab.svg}</div>
    //                 <div tw='max-w-[120px] text-xl text-center font-bold'> {tab.name}</div>
    //             </NavLink>
    //         );
    //     });
    // }, [location]);

    const classJoinButton = (
        <button tw='w-[240px] bg-LoginYellowButton text-black rounded-lg py-2.5 px-6 text-base font-Poppins font-medium flex items-center hover:bg-opacity-80 focus:bg-opacity-50' onClick={() => setJoinCodeModal(true)} >
            <div tw='ml-1.5'>Enter Class Join Code</div>
        </button>
    );

    return (
        <div tw=' overflow-x-hidden overflow-y-scroll bg-[#1C0057] h-screen' >
            <LoadingOverlay show={isLoading} />
            <BackgroundImage image={background_flare} tw='hidden sm:block' />
            <div tw='absolute bg-[#B031FA]' />
            <div tw='relative mt-4'>
                <NavigationBar enableJoinCodes={enableJoinCodes} />
            </div>

            {/* <div tw='flex relative justify-center m-auto w-full h-full max-w-[1100px] mb-0 sm:mb-20 mt-20'>
                <div tw='w-1/4 lg:w-full hidden sm:flex flex-col lg:max-w-[175px] text-white '>
                    {menu}
                </div>
                <div>
                    <Routes>
                        <Route path='/login' element={<Login isLoading={isLoading} setIsLoading={setIsLoading} />} />
                        <Route path='/sign_up' element={<SignUp setIsLoading={setIsLoading} isLoading={isLoading} />} />
                        <Route path='/password_reset' element={<PasswordReset setIsLoading={setIsLoading} {...props} />} />
                        <Route path='/new_password/:token' element={<PasswordUpdate setIsLoading={setIsLoading} {...props} />} />
                        {enableJoinCodes && <Route path='/join' element={<JoinCode setIsLoading={setIsLoading} />} />}
                        <Route path='*' element={<Navigate to='/login' replace />} />
                    </Routes>
                </div>
            </div>
            450 + (450 / 2) - (210/2) = 645

            */}
            <div tw='relative mt-0 sm:mt-10 m-auto px-8 lg:px-0 w-full max-w-[900px] flex items-center justify-end'>
                <div tw='hidden sm:flex flex-col sm:flex-row gap-4 sm:gap-0 items-start sm:items-center'>
                    <div tw='flex items-center ml-0 sm:ml-8 cursor-pointer' onClick={() => setSignUpModal(true)}>
                        <div>
                            <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <circle cx='11' cy='11' r='10' stroke='white' strokeWidth='1.5' />
                                <path d='M8 8.66539C8 7.77692 8.6 6 11 6C13.4 6 14 7.57949 14 8.36923C14 9.25769 13.7723 10.0793 12.125 10.7385C10.855 11.2466 10.4532 11.7428 10.4532 12.4605' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
                                <path d='M11.4434 15C11.4434 15.5523 10.9956 16 10.4434 16C9.89107 16 9.44336 15.5523 9.44336 15C9.44336 14.4477 9.89107 14 10.4434 14C10.9956 14 11.4434 14.4477 11.4434 15Z' fill='white' />
                            </svg>
                        </div>
                        <div tw='text-base font-Poppins font-medium text-white ml-2.5'>
                            How to Sign Up
                        </div>
                    </div>
                </div>
            </div>
            <div tw='relative justify-center w-full h-full  mt-0 sm:mt-5  px-8 lg:px-0' >
                <div tw='mx-auto max-w-[900px]'>
                    <div tw='relative flex items-center bg-none sm:bg-[#6441B9] rounded-2xl'>
                        <div tw='w-full md:w-1/2 pt-10 pb-5 sm:pb-10 px-0 md:px-4 xl:px-0'>
                            <LoginNew isLoading={isLoading} setIsLoading={setIsLoading} setForgotPasswordModal={setForgotPasswordModal} />
                            <div tw='hidden sm:flex md:hidden justify-center items-center mt-4' >
                                {classJoinButton}
                            </div>
                        </div>

                        <div tw='relative w-1/2 hidden md:flex items-center justify-center flex-col'>
                            <div tw='left-0 right-0 top-[-86px] absolute flex justify-center items-center' >
                                {classJoinButton}
                            </div>
                            <HeartMotionContainer />
                        </div>
                    </div>
                    <div tw='flex sm:hidden flex-col sm:flex-row gap-4 sm:gap-0 items-center'>
                        {classJoinButton}
                        <div tw='flex items-center ml-0 sm:ml-8 cursor-pointer' onClick={() => setSignUpModal(true)}>
                            <div>
                                <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <circle cx='11' cy='11' r='10' stroke='white' strokeWidth='1.5' />
                                    <path d='M8 8.66539C8 7.77692 8.6 6 11 6C13.4 6 14 7.57949 14 8.36923C14 9.25769 13.7723 10.0793 12.125 10.7385C10.855 11.2466 10.4532 11.7428 10.4532 12.4605' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
                                    <path d='M11.4434 15C11.4434 15.5523 10.9956 16 10.4434 16C9.89107 16 9.44336 15.5523 9.44336 15C9.44336 14.4477 9.89107 14 10.4434 14C10.9956 14 11.4434 14.4477 11.4434 15Z' fill='white' />
                                </svg>
                            </div>
                            <div tw='text-base font-Poppins font-medium text-white ml-2.5'>How to Sign Up</div>
                        </div>
                    </div>
                </div>

            </div>
            <ResetPassword setIsOpen={setResetPasswordModal} isOpen={resetPasswordModal} setIsLoading={setIsLoading}/>
            <ForgotPassword setIsOpen={setForgotPasswordModal} isOpen={ForgotPasswordModal} isLoading={isLoading} setIsLoading={setIsLoading} />
            <ClassJoinCode handleCloseJoinCodeModal={handleCloseJoinCodeModal} isOpen={JoinCodeModal} setIsLoading={setIsLoading} setIsOpen={setJoinCodeModal} />
            <SignUp setIsOpen={setSignUpModal} isOpen={SignUpModal} setJoinCodeModal={setJoinCodeModal} setForgotPasswordModal={setForgotPasswordModal} />
        </div>
    );
};

export default LoginContainer;
