/**
 * Created by jasonduncan on 9/15/16.
 */
import React, { useEffect, useState } from 'react';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
import tw, { css, styled } from 'twin.macro';
import BalanceText from 'react-balance-text';
import './RichText.scss';

/**
 * RichText - A component for rendering text that can contain embedded math expressions
 * Supports both quiz questions and choices with appropriate styling
 */

const MATH_DELIMITERS = [
    { open: '<math>', close: '</math>' },
    { open: '{{', close: '}}' },
    { open: '$$', close: '$$' }
];

const RichTextContainerStyle = styled.div`
    display: flex !important;
    align-items: center !important;

    .katex {
        font-family: Lato, KaTeX_Main, sans-serif;
        ${props => props.$isQuestion && css`
            font-weight: 800 !important;
        `}
    }
`;

const TextSpanStyle = css`
    font-family: Lato, sans-serif !important;

    @media (max-width: 640px) {
        font-size: ${props => props.$isQuestion ? '18px' : '16px'};
    }
`;

const MathSpanStyle = css`
    .katex {
        line-height: 1.3;
        font-size: 1.2em;
        @media (max-width: 640px) {
            font-size: 1em;
        }
    }
`;

const RichText = ({
    text = '',
    showMath = true,
    showHashTags = true,
    className = '',
    style = null,
    allMath = false,
    isQuestion = false,
    onClick = null
}) => {
    const [chunks, setChunks] = useState([]);
    const [centered, setCentered] = useState(false);

    const checkWrapperTag = (text, tag) => {
        const openTag = `<${tag}>`;
        const closeTag = `</${tag}>`;
        if (text.startsWith(openTag) && text.endsWith(closeTag)) {
            return text.substring(openTag.length, text.length - closeTag.length);
        }
        return null;
    };

    const findNextMathTag = (text) => {
        let earliest = { index: Infinity, delimiter: null };

        for (const delimiter of MATH_DELIMITERS) {
            const index = text.indexOf(delimiter.open);
            if (index !== -1 && index < earliest.index) {
                earliest = { index, delimiter };
            }
        }

        return earliest.index === Infinity ? null : earliest;
    };

    const parseText = (source) => {
        let text = source;
        const newChunks = [];

        if (allMath) {
            newChunks.push({ type: 'math', text });
            setChunks(newChunks);
            return;
        }

        const centeredText = checkWrapperTag(text, 'center');
        if (centeredText) {
            text = centeredText;
            setCentered(true);
        }

        while (text.length > 0) {
            const nextMathTag = findNextMathTag(text);

            if (!nextMathTag) {
                newChunks.push({ type: 'text', text });
                break;
            }

            const { index, delimiter } = nextMathTag;

            // Add text before math tag
            if (index > 0) {
                newChunks.push({ type: 'text', text: text.substring(0, index) });
            }

            // Find closing tag
            const mathStart = text.substring(index + delimiter.open.length);
            const closeIndex = mathStart.indexOf(delimiter.close);

            if (closeIndex === -1) {
                newChunks.push({ type: 'error', text });
                break;
            }

            // Add math content
            const mathText = mathStart.substring(0, closeIndex);
            newChunks.push({ type: 'math', text: mathText });

            // Continue with remaining text
            text = mathStart.substring(closeIndex + delimiter.close.length);
        }

        setChunks(newChunks);
    };

    useEffect(() => {
        parseText(text);
    }, [text]);

    const generateNodes = () => {
        return chunks.map((chunk, index) => {
            switch (chunk.type) {
                case 'text':
                    return (
                        <span
                            key={index}
                            css={[TextSpanStyle, { $isQuestion: isQuestion }]}
                            className='rich-text-text'
                            dangerouslySetInnerHTML={{ __html: chunk.text }}
                        />
                    );

                case 'error':
                    return (
                        <span
                            key={index}
                            className='rich-text-error'
                        >
                            {chunk.text}
                        </span>
                    );

                case 'math':
                    return (
                        <span
                            key={index}
                            css={MathSpanStyle}
                            className='rich-text-math'
                        >
                            <InlineMath
                                math={chunk.text}
                            />
                        </span>
                    );

                default:
                    return null;
            }
        });
    };

    return (
        <RichTextContainerStyle
            $isQuestion={isQuestion}
            className={`rich-text font-Lato ${className}`}
            style={style}
            onClick={onClick}
        >
            {/* tablet+ */}
            <div
                tw='text-center w-full max-w-[500px] mx-auto'
                css={[
                    isQuestion && tw`hidden sm:block font-semibold`
                ]}
                style={{ textWrap: 'balance' }}
            >
                <BalanceText>
                    {generateNodes()}
                </BalanceText>
            </div>

            {/* mobile - only shown for questions */}
            {isQuestion && (
                <div tw='block sm:hidden text-center'>
                    {generateNodes()}
                </div>
            )}
        </RichTextContainerStyle>
    );
};

export default RichText;