import React from 'react';


const VideoInfoIcon = (props) => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5 0C5.5971 0 0 5.5971 0 12.5C0 19.4029 5.5971 25 12.5 25C19.4029 25 25 19.4029 25 12.5C25 5.5971 19.4029 0 12.5 0ZM13.3929 18.5268C13.3929 18.6496 13.2924 18.75 13.1696 18.75H11.8304C11.7076 18.75 11.6071 18.6496 11.6071 18.5268V10.9375C11.6071 10.8147 11.7076 10.7143 11.8304 10.7143H13.1696C13.2924 10.7143 13.3929 10.8147 13.3929 10.9375V18.5268ZM12.5 8.92857C12.1495 8.92142 11.8158 8.77717 11.5705 8.52678C11.3252 8.2764 11.1878 7.93983 11.1878 7.58929C11.1878 7.23875 11.3252 6.90217 11.5705 6.65179C11.8158 6.4014 12.1495 6.25715 12.5 6.25C12.8505 6.25715 13.1842 6.4014 13.4295 6.65179C13.6748 6.90217 13.8122 7.23875 13.8122 7.58929C13.8122 7.93983 13.6748 8.2764 13.4295 8.52678C13.1842 8.77717 12.8505 8.92142 12.5 8.92857Z" fill="white" />
    </svg>


);

export default VideoInfoIcon;

