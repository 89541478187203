import React, { useEffect } from 'react';
import { useAuth } from 'context/Auth/AuthProvider.js';
import { Helmet } from 'react-helmet';
import LoadingPage from '../components/LoadingPage';

const LogoutRedirect = ({ redirect = null }) => {
    const { logout } = useAuth();

    useEffect(() => {
        console.log('Logging out (Logout Redirect)');
        logout(redirect);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // return null;
    return (
        <>
            <Helmet>
                <title>Logout</title>
            </Helmet>
            <LoadingPage color='#123abc' background='#450762' message=' ' />;
        </>
    );
};

export default LogoutRedirect;
