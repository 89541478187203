import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import Modal from 'components/Modal/Modal';
import LoadingOverlay from '../LoadingOverlay';

const ReActivatedTrialPopup = ({ setOnboardState, show, parent }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const backgroundGradient = css`
        background-image: linear-gradient(
                180deg,
                hsl(273deg 100% 89%) 0%,
                hsl(269deg 100% 91%) 14%,
                hsl(264deg 100% 93%) 28%,
                hsl(257deg 100% 94%) 42%,
                hsl(245deg 100% 96%) 56%,
                hsl(226deg 100% 96%) 71%,
                hsl(207deg 100% 96%) 81%,
                hsl(186deg 100% 96%) 100%
        );
    `;

    useEffect(() => {
        if (show) setIsOpen(true);
    }, [show]);

    const onClose = useCallback(() => {
        setLoading(true);
        setOnboardState?.({ re_activated_trial: false });
    }, [setOnboardState]);

    return (
        <Modal isOpen={isOpen} modalChildrenStyle={tw`w-full max-w-[650px] lg:max-w-[720px] mx-auto px-5`}>
            <div tw='py-14 sm:py-[60px] px-5 md:px-8 rounded-xl text-center relative font-Poppins' css={[backgroundGradient]}>
                <LoadingOverlay show={loading} fixed={false} />
                <div tw='text-black text-xl sm:text-2xl md:text-3xl font-semibold text-center'>
                    Great News!
                </div>
                <div>
                    <div tw='text-black text-base sm:text-xl'>
                        <div tw='max-w-[530px] text-center mx-auto mt-6'>
                            We've reactivated your free trial for 7 days.
                        </div>
                    </div>
                </div>

                <div tw='flex justify-center mt-10'>
                    <button
                        tw='bg-[#6E60E6] w-[250px] sm:w-[360px] h-[65px] text-white text-xl sm:text-2xl font-medium rounded-[12px]'
                        onClick={onClose}
                    >
                        LET'S GO!
                    </button>
                </div>

                {/*
                <div
                    tw='absolute top-3 right-6 cursor-pointer'
                    onClick={onClose}
                >
                    <div tw='text-[rgba(178, 178, 181, 0.95)] text-4xl'>
                        X
                    </div>
                </div>
                */}

            </div>
        </Modal>
    );
};

ReActivatedTrialPopup.propTypes = {
    anyProp: PropTypes.any
};

export default ReActivatedTrialPopup;
