import React from 'react';
import tw, { styled, css } from 'twin.macro';
import RichText from 'components/RichText';

const Choice = ({ question, choice, twoColLayout }) => {
    if (!choice)
        return null;

    const largeText = question?.attributes?.large_text ?? false;

    let questionNodes = [];
    let text = choice.choice_text;
    if (text) {
        if (choice.content_image) {
            questionNodes.push(
                <div>
                    <img
                        data-choice-image={text}
                        key={choice.id}
                        // style={{ maxWidth: '100%', maxHeight: '200px', minHeight: '60px', margin: 'auto' }}
                        css={[twoColLayout ? tw`max-w-[200px] sm:max-h-[120px] min-h-[60px]  w-full m-auto` : tw`max-w-[100%] max-h-[200px] min-h-[60px] m-auto`]}
                        src={text}
                        alt='choice'
                    />
                </div>

            );
        } else {
            // check if this is centered, & grab the text from inside the center tag
            questionNodes.push(
                <RichText
                    isQuestion={false}
                    key={choice.id}
                    className='quiz-choice-rich-text'
                    tw='font-Lato text-center px-3 grow font-[500]'
                    text={choice.choice_text}
                    css={[
                        largeText && tw`text-[25px] sm:text-[44px]`,
                        !largeText && tw`text-[16px] sm:text-[18px]`
                    ]}
                />);
        }
    }
    return questionNodes;
};
export default Choice;
