import React from 'react';
import Slider from 'react-slick';
import tw, { css, styled } from 'twin.macro';

import VideoCard from 'components/VideoCard/VideoCard';
import { useAccount } from 'state/User/UserState.js';

import 'animate.css/animate.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// import PropTypes from 'prop-types';
const VideoSliderContainer = styled.div`
    .slick-list {
    }

    .video-item {
        &:focus {
            outline: none;
        }

        padding: 8px 11px;
    }

    .slick-initialized {
        padding: 0px 40px;
    }

    .slick-slider {
        .prev {
            right: 96.5% !important;
            @media only screen and (max-width: 1124px) {
                right: 95% !important;
            }
            @media only screen and (max-width: 800px) {
                right: 94% !important;
            }
        }

        .next {
            left: 96.5% !important;
            @media only screen and (max-width: 1124px) {
                left: 95% !important;
            }
            @media only screen and (max-width: 800px) {
                left: 94% !important;
            }
        }
    }
    .slick-slide.slick-cloned {
        display: none;
    }   
`;

const VideoSliderContainerDTC = styled.div(({ $B2B, $assignmentsPage }) => [
    css`    
        .slick-list {
            max-width: calc(var(--video-session-layout-width) - 100px);
            margin: 0 auto;
        }

        .video-item {
            &:focus {
                outline: none;
            }

            padding: 8px 11px;
        }

        .slick-initialized {
            padding: 0px 0px;
        }
        .slick-slider {
            .prev {
                right: calc((100vw - var(--video-session-layout-width)) / 2 + 970px) !important;
                @media only screen and (max-width: 1124px) {
                    right: 95% !important;
                }
                @media only screen and (max-width: 800px) {
                    right: 94% !important;
                }
            }

            .next {
                left: calc((100vw - var(--video-session-layout-width)) / 2 + 970px) !important;
                @media only screen and (max-width: 1124px) {
                    left: 95% !important;
                }
                @media only screen and (max-width: 800px) {
                    left: 94% !important;
                }
            }
        }
    `,
    $B2B && css`
            .slick-slider {
            .prev {
                right: calc((100vw - var(--video-session-layout-width)) / 2 + 1050px) !important;
                @media only screen and (max-width: 1124px) {
                    right: 95% !important;
                }
                @media only screen and (max-width: 800px) {
                    right: 94% !important;
                }
            }

            .next {
                left: calc((100vw - var(--video-session-layout-width)) / 2 + 1050px) !important;
                @media only screen and (max-width: 1124px) {
                    left: 95% !important;
                }
                @media only screen and (max-width: 800px) {
                    left: 94% !important;
                }
            }
        }
    `,
    $assignmentsPage && css`
        .slick-slider {
        .prev {
            right: 94% !important;
            @media only screen and (max-width: 1124px) {
                right: 95% !important;
            }
            @media only screen and (max-width: 800px) {
                right: 94% !important;
            }
        }

        .next {
            left: 94% !important;
            @media only screen and (max-width: 1124px) {
                left: 95% !important;
            }
            @media only screen and (max-width: 800px) {
                left: 94% !important;
            }
        }
    }
    .slick-slide.slick-cloned {
        display: none;
    }  
`

]);




/*
    SlickButton - custom react-slick button
 */
const SlickButtonStyle = css`
    &&& {
        position: absolute;
        width: 55px;
        height: 55px;
        @media only screen and (max-width: 1024px) {
            width: 45px;
            height: 45px;
        }
        @media only screen and (max-width: 414px) {
            width: 17px;
            height: 33px;
        }
        // center it within its parent...
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
        border: none;
        outline: none;

        &.next {
            left: 102%;
            right: 0px;
        }

        &.prev {
            right: 99%;
        }

        &.disabled {
            display: none;
        }

        &:active {
            box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
        }
    }
`;
const SlickButtonStyleDTC = css`
    &&& {
        position: absolute;
        width: 36px;
        height: 283px;
        flex-shrink: 0;
        background: #3E257A;
        border-radius: 8px;
        @media only screen and (max-width: 1300px) {
            display: none;
        }

        @media only screen and (max-width: 1024px) {
            width: 45px;
            height: 45px;
        }
        @media only screen and (max-width: 414px) {
            width: 17px;
            height: 33px;
        }
        // center it within its parent...
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
        border: none;
        outline: none;

        &.next {
            left: 102%;
            right: 0px;
        }

        &.prev {
            right: 99%;
        }

        &.disabled {
            display: none;
        }

        &:active {
            box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
        }
    }
`;

const hideClone = css`
    .slick-slide.slick-cloned {
        display: none;
    } 
`;

function NextArrow(props) {
    let { className, onClick, ArrowRight } = props;
    if (className.includes('slick-disabled')) className = 'next disabled';
    else className = 'next';
    return (
        <div onClick={onClick} className={className} css={[SlickButtonStyle]} tw='flex justify-center items-center'>
            <svg width='18' height='30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M2.75 27.5L15.25 15 2.75 2.5' stroke='#6160D9' strokeWidth='4.167' strokeLinecap='round'
                    strokeLinejoin='round' />
            </svg>
        </div>
    );
}

function PrevArrow(props) {
    let { className, onClick } = props;
    if (className.includes('slick-disabled')) className = 'prev disabled';
    else className = 'prev';
    return (
        <div onClick={onClick} className={className} css={[SlickButtonStyle]} tw='flex justify-center items-center'>
            <svg width='18' height='30' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M15.25 27.5L2.75 15l12.5-12.5' stroke='#6160D9' strokeWidth='4.167' strokeLinecap='round'
                    strokeLinejoin='round' />
            </svg>
        </div>
    );
}

function DTCNextArrow(props) {
    let { className, onClick, ArrowRight } = props;
    if (className.includes('slick-disabled')) className = 'next disabled';
    else className = 'next';
    return (
        <div onClick={onClick} className={className} css={[SlickButtonStyleDTC]} tw='flex justify-center items-center'>
            <svg
                xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'
                fill='none'>
                <path d='M8 19L15.57 12.3763C15.7976 12.1771 15.7976 11.8229 15.57 11.6237L8 5' stroke='white' strokeWidth='2' strokeLinecap='round' />
            </svg>
        </div>
    );
}

function DTCPrevArrow(props) {
    let { className, onClick } = props;
    if (className.includes('slick-disabled')) className = 'prev disabled';
    else className = 'prev';
    return (
        <div onClick={onClick} className={className} css={[SlickButtonStyleDTC]} tw='flex justify-center items-center'>
            <svg
                xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'
                fill='none'>
                <path d='M16 5L8.43004 11.6237C8.20238 11.8229 8.20238 12.1771 8.43004 12.3763L16 19' stroke='white' strokeWidth='2' strokeLinecap='round' />
            </svg>
        </div>
    );
}

const sliderResponsive = [
    {
        breakpoint: 2500,
        settings: {
            slidesToShow: 5,
            slidesToScroll: 5
        }
    },
    {
        breakpoint: 2200,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 4
        }
    },
    {
        breakpoint: 1900,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 4
        }
    },
    {
        breakpoint: 1600,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 4
        }
    },
    {
        breakpoint: 1300,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 1024,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 938,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '20%',
            infinite: false
        }
    },
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '20%',
            infinite: false
        }
    }
];

const VideoSlider = ({ B2B, assignmentsPage, noSideArrow, ...props }) => {
    const { is_parent_account } = useAccount();

    const cardStyle = {
        // height: '300px',
        // width: '300px',
        // background: 'red'
    };

    // when dragging is true, prevent clicking the video card
    let dragging = false;
    let slickClone = props.videos.length > 3;
    // generate the cards
    let videos = props.videos?.map((video, index) => {
        // if the width & col count have been specified, calculate dimensions
        // if (props.width && props.cols) {
        // }
        let stats = video?.stats || {};

        return (
            <div key={video?.id ?? index} className='video-item'>
                <VideoCard
                    routeToVideoSession
                    className='video-item-card'
                    style={cardStyle}
                    onClick={() => {
                        if (props.onClickVideo) return props.onClickVideo(video, index);
                    }}
                    index={index}
                    video={video}
                    assignment={props.assignment || props.showCreateAssignment}
                    teacher={props.teacher}
                    stats={stats}
                    showTitle={true}
                    showViewCount={true}
                    showRecords={true}
                    disableHover={true}
                    {...props.cardProps}
                />
            </div>
        );
    });

    // default settings
    let settings = {
        dots: false,
        infinite: false,
        speed: 500,
        nextArrow: noSideArrow ? null : <DTCNextArrow />,
        prevArrow: noSideArrow ? null : <DTCPrevArrow />,
        initialSlide: 0,
        slidesToShow: 3,
        slidesToScroll: 3,

        responsive: props.sliderResponsive ?? sliderResponsive,
        beforeChange: () => (dragging = true),
        afterChange: () => (dragging = false)
    };

    // get responsive section from props
    if (props.responsive) settings.responsive = props.responsive;
    if( !videos.length ) return null;
    return (
        <VideoSliderContainerDTC playlistScreen={props.playlistScreen} css={[!slickClone && hideClone]} $assignmentsPage={assignmentsPage} $B2B={B2B}>
            <Slider {...settings}>{videos}</Slider>
        </VideoSliderContainerDTC>
    )
};

// VideoSlider.propTypes = {};

export default VideoSlider;