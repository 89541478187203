import React, { useState } from 'react';
import tw, { styled, css } from 'twin.macro';
import RecordPercentage from '../RecordProgress/RecordPercentage';
import { LockSVG, ContinueSVG, RetakeSVG, StartSVG } from './DTCButtonSVGs/index';
import { useStudents } from 'state/Teacher/ClassState';
import VideoInfoIcon from 'images/icons/VideoInfoIcon';
import ClassIcon from 'components/ClassIcon/ClassIcon';
import Modal from 'components/Modal/Modal';
import Button from 'components/Button/Button';
import RecordIcon from 'components/RecordIcon';

export const BorderGradient = css`
  border: 6px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(91.31deg, #1cc8e1 0.89%, #d022ed 98.12%);
  @media only screen and (max-width: 768px) {
    max-height: 600px;
  }
  @media only screen and (max-width: 500px) {
    min-height: 332px;
  }
`;

const TitleBackground = styled.div(({ $level }) => [
    tw`rounded-2xl max-w-[109px] mx-auto mt-5 py-1`,
    $level === 1 && css`background: linear-gradient(270deg, #B1B1B1 -1.03%, #898989 59.91%);`,
    $level === 2 && css`background: linear-gradient(270deg, #F2B845 -0.61%, #A06F27 99.39%);`,
    $level === 3 && css`background: linear-gradient(90deg, #46A6F8 0%, #B15AFE 100%); `

]);

const StudentNames = ({ c, students }) => {
    return students.map((student, key) => {
        return (
            <div key={key} tw='mt-1 xsm:mt-0'>
                {student.user.first_name} {student.user.last_name}
            </div>
        );
    });
};

const studentHasRecord = (student, videoId, level) => {
    if (student?.video_stats && videoId) {
        const stats = student.video_stats[videoId];
        if (stats) {
            if (stats.current_level > level) {
                return true;
            }
        }
    }
    return false;
};


const RecordPlaqueTeacher = (props) => {
    const { level, title, score, currentLevel, currentScore, description, classes,  videoId } = props;
    const levelText = ['', 'SILVER', 'GOLD', 'PLATINUM'];
    const students = useStudents();
    const [isOpen, setIsOpen] = useState(false);

    let ScoreText = currentScore;

    // get the students for each class
    const awardedClasses = classes?.map((cls) => {
        const awardedStudents = [];
        cls.students.forEach((studentId) => {
            const student = students.find((s) => s.user.id === studentId);
            if (studentHasRecord(student, videoId, level)) awardedStudents.push(student);
        });
        return {
            cls,
            awardedStudents
        };
    });

    return (
        <div
            tw='bg-[#E6E2E6] flex-col justify-center items-center relative'
            css={[BorderGradient]}
            data-testid={`record-plaque-${level}`}
        >
            <div tw='bg-[#1C0057] p-2'>
                <div tw='bg-[#E6E2E6] p-0.5 ' >
                    <div tw='bg-[#1C0057] '>
                        <div tw='relative flex justify-center pt-6 px-6 md:px-8 lg:px-3 xl:px-14 items-center'>
                            <div tw='absolute z-10 text-2xl text-white font-bold'>
                                {ScoreText}
                            </div>
                            <div
                                tw=' max-w-[220px] max-h-[220px] md:max-w-[160px] md:max-h-[160px] lg:max-w-[180px] lg:max-h-[180px] xl:max-w-[200px] xl:max-h-[200px]'>
                                <RecordPercentage
                                    level={level}
                                    percentage={score / 100.0}
                                    locked={currentLevel < level}
                                    tw='w-full h-full relative'
                                />
                            </div>
                        </div>
                        <TitleBackground $level={level}>
                            <div tw='text-sm font-medium text-center '>
                                {levelText[level]}
                            </div>
                        </TitleBackground>

                        <div tw='pb-4 pt-7 px-5 text-center text-sm '>{description}</div>
                    </div>
                </div>
            </div>
            <div tw='absolute top-4 right-4 cursor-pointer' onClick={() => setIsOpen(true)}>
                <VideoInfoIcon />
            </div>

            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                modalChildrenStyle={tw`w-full maxWidth[729px]  lg:maxWidth[929px] mx-0 xsm:mx-auto `}
            >
                <div tw='mx-0 xsm:mx-4 relative'>
                    <div
                        tw='w-full px-6 py-3 flex justify-between items-center bg-[#3B2A7E] rounded-tl-xl rounded-tr-xl relative'>
                        <h1 tw='text-white text-2xl font-medium'>{title}</h1>
                        <div tw='flex space-x-1 items-center mr-5'>

                            <div tw='flex'>
                                <div tw='mr-1.5 text-white text-xl'>{score}%</div>
                                <div tw='h-6 w-6'>
                                    <RecordIcon level={level} newRecords={true} />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div tw='bg-white maxHeight[582px] overflow-auto pt-6'>
                        {awardedClasses &&
                            awardedClasses.map(({ cls, awardedStudents }, key) => {
                                if (awardedStudents.length > 0) {
                                    // get the students who got a record from this class
                                    return (
                                        <div key={key} tw='px-6 pb-5'>
                                            <div tw='flex items-center space-x-2 w-full bg-PicoteeBlue text-white px-4 py-2.5'>
                                                <ClassIcon
                                                    width='29px'
                                                    height='29px'
                                                    style={{ marginRight: '10px' }}
                                                    name={cls.display_icon ?? 'ClassIcon00'}
                                                />

                                                <span tw='font-medium text-lg'>{cls.title}</span>
                                            </div>
                                            <div>
                                                {awardedStudents?.length > 0 && (
                                                    <div
                                                        tw='flex flex-wrap xsm:grid grid-cols-4 gap-4 w-full bg-GhostWhite px-4 py-2.5 text-EarieBlack text-sm font-medium'>
                                                        <StudentNames c={cls} students={awardedStudents} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                    </div>
                    <div tw='bg-white flex items-center justify-between px-6 py-4 rounded-bl-xl rounded-br-xl'>
                        <div tw='text-xl font-medium'>{description}</div>
                        <Button onClick={() => setIsOpen(false)} variant={'yellow-dtc'} tw='px-3 min-w-[130px]' >Done</Button>
                    </div>
                    <button
                        tabIndex={0}
                        tw='bg-white h-12 w-12 absolute -top-6 -right-6 rounded-full hidden xsm:flex justify-center items-center shadow-md cursor-pointer '
                        onClick={() => setIsOpen(false)}
                    >
                        <svg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M16.705 1.257c-.71-.711-1.773-.711-2.482 0l-5.2 5.211-5.201-5.211c-.71-.711-1.773-.711-2.482 0-.71.71-.71 1.776 0 2.487l5.2 5.212-5.2 5.211c-.71.71-.71 1.777 0 2.487.355.356.827.474 1.3.474.473 0 .946-.118 1.3-.474l5.2-5.211 5.2 5.211c.355.356.828.474 1.301.474s.946-.118 1.3-.474c.71-.71.71-1.776 0-2.487l-5.2-5.211 5.2-5.212c.473-.71.473-1.777-.236-2.487z'
                                fill='#6A7277'
                            />
                        </svg>
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default RecordPlaqueTeacher;