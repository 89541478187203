import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import RecordImg from 'components/RecordImg';
import { useAccount } from 'state/User/UserState';
import { useSetOnboardState } from 'state/OnboardState';
import { useOnboardState } from 'state/User/UserState';
import { useUser } from 'state/User/UserState';
import { MUZOLOGY_WEBSITE_URL } from 'config';
import { useMedia } from 'react-use';
import { is } from 'immutable';

const TrialBanner = () => {
    const account = useAccount();
    const user = useUser();
    const onboard = useOnboardState();
    const { show_trial_banner } = onboard;
    const setOnboardState = useSetOnboardState();
    const daysRemaining = account.trial_days_remaining + 1;
    const pluralityCheck = daysRemaining > 1 ? 'days' : 'day';
    const isSmall = useMedia('(max-width: 767px)');
    console.log("🚀 ~ TrialBanner ~ isSmall:", isSmall)

    const handleOnClick = () => {
        window.open(
            `${MUZOLOGY_WEBSITE_URL}checkout?stage=1&email=${encodeURIComponent(user.email)}&a=${account.account_uuid}`,
            '_blank',
            'noopener,noreferrer'
        );
    };

    if (!show_trial_banner) return null;
    if (account.trial_days_remaining <= 0) return null;
    return (
        <div tw='absolute top-[0px] bg-[#E4DEF1] h-[100px] w-full z-60' onClick={isSmall ? () => handleOnClick() : null}>
            <div tw='flex py-3'>
                <div tw='max-w-[50px] md:max-w-[70px] ml-2 lg:ml-4'>
                    <RecordImg level={3} dtc={true} />
                </div>
                <div tw='ml-3 md:ml-4 mt-1.5'>
                    <div tw='text-base tiny:text-lg lg:text-xl font-semibold '>{daysRemaining} {pluralityCheck} left in free trial</div>
                    <div tw='text-sm tiny:text-base lg:text-lg'>Upgrade easily to monthly or annual plans.</div>
                </div>
                <div tw='grow hidden md:block'>
                    <div tw='flex justify-end items-center mr-3 lg:mr-5 xl:mr-[50px]  h-full'>
                        <button
                            tw='
                    w-full max-w-[150px] lg:max-w-[200px] xl:max-w-[280px] h-[47px] p-0 py-1 font-semibold text-base lg:text-xl text-black border-black border-solid border-2 bg-none rounded-2xl
                    justify-center items-center gap-4 inline-flex mr-2 lg:mr-6'
                            onClick={() => setOnboardState({ show_trial_banner: false })}>

                            Remind Me Later
                        </button>
                        <button
                            tw='
                    w-full max-w-[150px] lg:max-w-[200px] xl:max-w-[280px]  h-[47px] p-0 py-1 bg-[#6F5FE6] font-semibold text-base lg:text-xl  text-white rounded-2xl
                    justify-center items-center gap-4 inline-flex hover:bg-indigo-600 hover:border-indigo-600 hover:shadow-lg hover:scale-100
                    transform transition-all duration-100 ease-in-out active:bg-indigo-700 active:border-indigo-700 active:shadow-none active:scale-100'
                            onClick={handleOnClick}
                        >
                            Upgrade Now
                        </button>
                    </div>
                </div>
            </div>
            {/* mobile buttons - hide for now lana comment 1-7-25 */}
            <div tw='hidden justify-between items-center mx-4 mt-2.5'>
                <button
                    tw='
                    w-full max-w-[175px]  h-[38px] p-0 py-1 font-semibold text-base lg:text-xl text-black border-black border-solid border-2 bg-none rounded-2xl
                    justify-center items-center gap-4 inline-flex mr-2 lg:mr-6'
                    onClick={() => setOnboardState({ show_trial_banner: false })}
                >
                    Remind Me Later
                </button>
                <button
                    tw='
                    w-full max-w-[175px]  h-[38px] p-0 py-1 bg-[#6F5FE6] font-semibold text-base lg:text-xl  text-white rounded-2xl
                    justify-center items-center gap-4 inline-flex hover:bg-indigo-600 hover:border-indigo-600 hover:shadow-lg hover:scale-100
                    transform transition-all duration-100 ease-in-out active:bg-indigo-700 active:border-indigo-700 active:shadow-none active:scale-100'
                    onClick={handleOnClick}
                >
                    Upgrade Now
                </button>
            </div>
        </div >
    );
};

TrialBanner.propTypes = {
    anyProp: PropTypes.any
};

export default TrialBanner;

