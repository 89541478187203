import React, { useMemo } from 'react';
import tw, { css, styled } from 'twin.macro';
import GradeListBox from 'components/GradeListBox/GradeListBox';

const GradeContainer = styled.label(({ active }) => [
    tw` w-full rounded-lg bg-[#F4FBFF] text-[#6E7191] p-2 text-base font-medium font-Poppins text-center cursor-pointer sm:hover:scale-110 transform transition-all duration-300 ease-in-out`,
    css`
        /* Hide the browser's default checkbox */

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        @media (max-width: 640px) {
            &:last-child {
                width: 80px;
            }
        }

    `,
    active && tw` bg-[#6F5FE6] text-white`
]);


const GradeChoices = ({ timesTableProduct, gradeChoice, teacher, register, clearErrors, formErrors, selectGradeMobile, selectedGrade }) => {
    const GradeCopy = teacher ? 'What Grade(s) Do You Teach' : 'Grade';

    // create grade choice boxes
    const gradeChoices = useMemo(() => {
        const boxes = Array(timesTableProduct ? 10 : 9).fill(0);
        return boxes.map((x, index) => {

            let label = (index + 2).toString();
            let value = label;

            if (timesTableProduct) {
                label = (index - 1).toString();
                value = String(index - 1);
            }

            if (index === 0) {
                label = 'K-2';
                value = 'K-2';
                if (timesTableProduct) {
                    label = 'PreK';
                    value = 'PreK';
                }
            }

            if (index === 1 && timesTableProduct) {
                label = 'K';
                value = 'K';
            }
            if (index === 8 && !timesTableProduct) {
                label = '10-12+';
                value = '10-12+';
            }
            if (index === 9 && timesTableProduct) {
                label = '8+';
                value = '8+';
            }

            let active = null;
            if (gradeChoice.current)
                active = teacher ? gradeChoice.current.find(choice => choice === value) : gradeChoice.current === value;
            return (
                <GradeContainer active={active} key={index} onClick={() => clearErrors('grade')}>
                    <div className='title' tw='select-none'>{label}</div>
                    <input
                        type={teacher ? 'checkbox' : 'radio'}
                        name='grade'
                        value={label}
                        ref={register({ required: true })}
                    />
                </GradeContainer>
            );
        });
    }, [timesTableProduct, teacher, register, clearErrors, gradeChoice, gradeChoice.current]);

    return (
        <div tw='pt-2 pb-3 md:pb-7 ' css={[formErrors.grade && tw`border-2 border-solid border-red-400 px-4`]}>
            {formErrors.grade ? <div tw='text-red-500 font-medium sm:font-bold text-sm sm:text-base'>Grade - Required </div> :
                <div tw='text-base font-normal text-[#303030]'>{GradeCopy}</div>}
            <div tw='grid-cols-5 lg:grid-cols-5 gap-2.5 mt-5 ' css={[teacher ? tw`grid` : tw`hidden`]}> {gradeChoices}</div>
            <div css={[teacher ? tw`hidden` : 'block']}>
                <GradeListBox onSelectItem={selectGradeMobile} selected={selectedGrade} timesTableProduct={timesTableProduct} />
            </div>
        </div>
    );
};

export default GradeChoices;