import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'twin.macro';
import HeartFavoriteIconNew from 'images/icons/HeartFavoriteIconNew';
import BackgroundImage from 'lib/components/BackgroundImage';
import Background from '../../app/containers/shared/QuizSessionContainer/components/backgrounds/Gold.png';
import LoadingRecord from '../../app/containers/shared/QuizSessionContainer/components/backgrounds/Loading_Record.png';


const rotateCss = css`
    -webkit-animation:spin 30s linear infinite;
    -moz-animation:spin 30s linear infinite;
    animation:spin 30s linear infinite;
    @-moz-keyframes spin { 
        100% { -moz-transform: rotate(360deg); } 
    }
    @-webkit-keyframes spin { 
        100% { -webkit-transform: rotate(360deg); } 
    }
    @keyframes spin { 
        100% { 
            -webkit-transform: rotate(360deg); 
            transform:rotate(360deg); 
        } 
    }
`
const LazyLoadVideoCard = ({ title }) => {
    return (
        <div tw='flex flex-col max-w-[300px] sm:max-w-[407px] mt-10 ml-[12vw] sm:ml-0'>
            <div tw='relative h-[100px] sm:h-[310px] w-full flex justify-center items-center rounded-t-md'>
                <BackgroundImage image={Background} tw='rounded-md max-w-[280px] sm:max-w-[100%]' />
                <div tw='absolute h-full w-full flex flex-col justify-center items-center max-w-[70px] sm:max-w-[140px] '>
                    <img src={LoadingRecord} css={[rotateCss]}/>
                </div>
            </div>
        </div>
    );
};

LazyLoadVideoCard.propTypes = {
    anyProp: PropTypes.any,
}

export default LazyLoadVideoCard;

