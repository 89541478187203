import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import { styled } from 'twin.macro';
import { useTable } from 'react-table';
import moment from 'moment';
import { ThreeDotsVert } from '../../assets/icons';
import PopupMenu from '../PopupMenu/PopupMenu';
import ChangeStudentPasswordDialog from './ChangeStudentPasswordDialog';
import RemoveStudentDialog from './RemoveStudentDialog';
import RenameStudentDialog from './RenameStudentDialog';

const S = {
    PasswordReset: styled.button`
        font-family: Avenir, serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        text-decoration: underline;
    `,
    ActionButton: styled.button`
        position: relative;
        outline: none;

        &:focus {
            outline: none;
        }
    `
};
const Styles = styled.div`
    .wrapper {
        margin: 0 auto;
        max-width: 1300px;
    }

    table {
        width: 100%;
        border-spacing: 0;
        user-select: auto;
    }

    thead {
        tr {
            th {
                font-family: Avenir, serif;
                font-style: normal;
                font-weight: 900;
                font-size: 14px;
                line-height: 19px;

                text-align: left;
                color: white;
                padding-bottom: 15px;
                padding-left: 1.15rem;
            }
        }
    }

    tbody {
        tr {
            background: #ebebff;

            &:nth-child(2n) {
                background: #d7d7ff;
            }

            td {
                padding: 1.15rem;
                text-align: start;

                &:nth-child(1) {
                    /* text-decoration: underline; */
                }

                &:nth-child(3) {
                    padding: 1.15rem 5rem;
                    text-align: center;
                }
            }
        }
    }
`;


const RosterTable = ({ currentClass, students, removeStudent, changeStudentName }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [studentId, setStudentId] = useState(null);
    const [deleteStudentModal, setDeleteStudentModal] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [editNameModal, setEditNameModal] = useState(false);

    const handleDeleteStudent = () => {
        setShowPopup(false);
        setDeleteStudentModal(true);
    };

    const handleEditStudentName = () => {
        setShowPopup(false);
        setEditNameModal(true);
    };

    const columns = React.useMemo(
        () => [
            {
                id: 'name',
                Header: 'Students',
                // accessor: 'user.fullName'
                accessor: (row, rowIndex) => {
                    // console.log('Column:', row, rowIndex);
                    return `${row.user?.last_name}, ${row.user?.first_name}`;
                }
            },
            {
                id: 'username',
                Header: 'Username',
                accessor: 'user.username'
            },
            {
                id: 'password_reset',
                accessor: (row, rowIndex) => {
                    return (
                        <S.PasswordReset
                            onClick={() => {
                                setResetPassword(true);
                                setStudentId(row.user.id);
                            }}
                        >
                            Reset Password
                        </S.PasswordReset>
                    );
                }
            },
            {
                id: 'last_login',
                accessor: (row, rowIndex) => {
                    let last_login = row.user.last_login ? `Last Login: ${moment(row.user.last_login).format('MMM Do YYYY')}` : '';
                    return last_login;
                }
            },
            {
                id: 'actions',
                accessor: (row, rowIndex) => {
                    const actions = [
                        {
                            name: 'Edit Name',
                            onAction: () => handleEditStudentName()
                        },
                        {
                            separator: true
                        },
                        {
                            name: 'Remove Student',
                            onAction: () => handleDeleteStudent(),
                            color: '#E04E4E'
                        }
                    ];

                    return (
                        <S.ActionButton
                            onClick={() => {
                                if (showPopup !== rowIndex)
                                    setShowPopup(rowIndex);
                                setStudentId(row.user.id);
                            }}
                            // onBlur={() => setShowPopup(false)}
                        >
                            <ThreeDotsVert />
                            {showPopup === rowIndex && <PopupMenu top='0px' actions={actions} setShowPopup={setShowPopup} rowIndex={rowIndex} />}
                        </S.ActionButton>
                    );
                }
            }
        ],
        [showPopup]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: students
    });

    return (
        <>
            <Styles id='classes-tour-roster'>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Styles>

            <ChangeStudentPasswordDialog
                studentId={studentId}
                open={resetPassword}
                onClose={() => setResetPassword(false)}
            />

            <RemoveStudentDialog
                studentId={studentId}
                currentClass={currentClass}
                removeStudent={removeStudent}
                open={deleteStudentModal}
                onClose={() => setDeleteStudentModal(false)}
            />

            <RenameStudentDialog
                studentId={studentId}
                currentClass={currentClass}
                changeStudentName={changeStudentName}
                open={editNameModal}
                onClose={() => setEditNameModal(false)}
            />

        </>
    );
};

RosterTable.propTypes = {
    anyProp: PropTypes.any
};

export default RosterTable;
