import React, { useMemo } from 'react';
import tw, { css } from 'twin.macro';
import { useNavigate, useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import { useUser } from 'state/User/UserState.js';
import { useAssignmentsForVideo } from 'state/Student/AssignmentsState';
import { useMainCourseAndVideos } from 'state/CatalogState';
import { useVideoStats, useVideoWithStats } from 'state/Student/VideoStats';
import { useLockedVideoQuiz } from 'app/containers/shared/QuizSessionContainer/QuizModal';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import VideoSession from './VideoSession';
import './Session.css';
import { TrailBannerContainerWrapper } from 'components';
export const VideoLoading = () => <div className=''>
    <LoadingScreen />
</div>;

// find the playlist for a given course & video
export function usePlaylist(course, video_id) {
    // find the playlist
    if (course && course.playlists) {
        return course.playlists.find((playlist) => playlist.videos && !!playlist.videos.find((video) => video && video.id === video_id));
    }
    return null;
}

const VideoSessionContainer = (props) => {
    // console.log('VideoSessionContainer props', props);

    const navigate = useNavigate();

    // get the video id from the url params
    const { video_id } = useParams();

    // get the user
    const user = useUser();

    // get the course & playlist for this video
    const course = useMainCourseAndVideos();

    const stats = useVideoStats();

    // get the video itself
    const video = useVideoWithStats(video_id);

    // find the course playlist for this video
    const playlist = usePlaylist(course, video);

    // get assignments related to the video
    // sort assignment by most recent
    const assignments = useAssignmentsForVideo(video_id);
    const videoAssignmentByDate = assignments.sort((a, b) => moment(b.created).diff(moment(a.created)));

    // unlock quiz modal
    const [WarmupQuizModal, closeQuiz, isWarmupOpen] = useLockedVideoQuiz({ videoId: video_id, onCancel: () => navigate(-1) });

    // ensure the video is loaded
    if (!video?.id) {
        console.log('VideoSessionContainer: video not loaded', video);
        // this should not happen - the video should be loaded before this component is mounted
        return <VideoLoading />;
    }
    // console.log('VideoSessionContainer: video loaded', video);

    return (
        <React.Fragment key={video.id}>
            <Helmet>
                <title>{video?.title ?? 'Video'}</title>
            </Helmet>

            {!user.instructor && <WarmupQuizModal />}
            <TrailBannerContainerWrapper>
                <VideoSession
                    css={isWarmupOpen && css`visibility: hidden; display:none;`}
                    aria-hidden={isWarmupOpen ? 'true' : 'false'}
                    user={user}
                    video={video}
                    stats={stats}
                    playlist={playlist}
                    assignment={videoAssignmentByDate[0] ?? null}
                />
            </TrailBannerContainerWrapper>

        </React.Fragment>
    );
};

export default VideoSessionContainer;
