import React, { useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { styled, css } from 'twin.macro';
import Page from 'components/Page';
import { useAssignedVideos, useAssignments, useCurrentAssignment } from 'state/Student/AssignmentsState';
import { useVideoStats, useRecentlyWatchedVideos, useFavoriteVideos } from 'state/Student/VideoStats';
import { useStudent, useTeacher } from 'state/User/UserState';
import { HomePageVideoRows, NewVideosRow, MobileVideoCardRow } from 'components';
import RecommendedPlaylist from 'components/RecommendedPlaylist';
import RenderOnViewPortEntry from 'components/RenderOnViewPortEntry/RenderOnViewPortEntry';
import HeaderContainer from './HeaderContainer/HeaderContainer';
import AchievementsContainer from './AchievementsContainer/AchievementsContainer';
import './HomeContainer.css';
import { useAssignmentActions } from 'state/Student/AssignmentsState';
import VideoSlider from 'components/VideoSlider';

// DO we still need to use this Page wrapper?
const S = {
    Container: styled(Page)`
        background: linear-gradient(127.46deg, #2d0569 13.37%, #4c3fc1 71.65%);
        display: flex;
        flex-direction: column;
        /* justify-content: stretch; */
        align-items: stretch;
    `
};

const ScrollBarStyle = css`

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }
`;

const HomeContainer = (props) => {
    // assignments
    const assignments = useAssignments();
    const assignedVideos = useAssignedVideos();
    const current_assignment_header = useCurrentAssignment();
    const videoStats = useVideoStats();
    const student = useStudent();
    const { fetchAssignments } = useAssignmentActions();

    // get videos specific to each video row
    const recentlyWatchedVideos = useRecentlyWatchedVideos();
    const favoriteVideos = useFavoriteVideos();

    // re grab assignments on load
    useEffect(() => {
        fetchAssignments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const nullState = useMemo(() => {
        // count total views and attempts
        let totalViewCount = 0;
        let totalAttempts = 0;
        for (const key in videoStats) {
            if (videoStats.hasOwnProperty(key)) {
                totalViewCount += videoStats[key].view_count;
                const quizLevels = videoStats[key].quiz_levels;
                // Iterate through quiz_levels
                for (const levelKey in quizLevels) {
                    if (quizLevels.hasOwnProperty(levelKey) && parseInt(levelKey) > 0) {
                        totalAttempts += quizLevels[levelKey].attempts;
                    }
                }
            }
        }

        // determine the active state of the child
        if (totalViewCount >= 3 || totalAttempts >= 3) {
            return false;
        }
        else {
            return true;
        }
    }, [videoStats]);

    const showRecommendedVideos = !nullState && recentlyWatchedVideos.length === 0;
    const showRecentlyWatchedVideos = recentlyWatchedVideos.length > 0;
    const showFavoriteVideos = favoriteVideos.length > 0;

    return (
        <div tw='bg-[#1C0057] flex flex-col items-stretch font-Poppins '>
            <Helmet>
                <title>Home</title>
            </Helmet>


            <div
                tw='[max-width:var(--layout-width)] max-h-full block mx-auto'
                css={[
                    css`
                        @media only screen and (max-width: 500px) {
                            padding: 0;
                        }
                    `
                ]}
            >
                <header tw='hidden md:block'>
                    <HeaderContainer assignment={current_assignment_header} nullState={nullState} />
                </header>


                <NewVideosRow teacher={false} />

                {showRecommendedVideos &&
                    <div tw='mt-14'>
                        <RecommendedPlaylist key='recommended_videos' grade={student.grade ?? 6} />
                    </div>
                }
                {showRecentlyWatchedVideos &&
                    <>
                        <RenderOnViewPortEntry threshold={0.25} >
                            <div tw='mt-6 md:mt-14 hidden md:block'>
                                <HomePageVideoRows title='Recently Watched' videos={recentlyWatchedVideos} recently_watched={true} />
                            </div>
                        </RenderOnViewPortEntry>
                        <div key={`recently_watched`} tw='mt-14 md:hidden'>
                            <h2 tw='text-white text-base tiny:text-xl font-semibold ml-[6vw] max-w-[320px] xsm:max-w-[360px]'>Recently Watched</h2>
                            <div tw='flex flex-nowrap overflow-x-auto justify-between items-center mt-2 pl-2' css={[ScrollBarStyle]}> <MobileVideoCardRow videos={recentlyWatchedVideos} /> </div>
                        </div>
                    </>

                }
                {showFavoriteVideos &&
                    <>
                        <RenderOnViewPortEntry threshold={0.25} >
                            <div tw='mt-14 hidden md:block'>
                                <HomePageVideoRows title='Your Favorites Playlist' videos={favoriteVideos} favorite={true} />
                            </div>
                        </RenderOnViewPortEntry>
                        <div key={`favorite_videos`} tw='mt-14 pb-8 md:pb-0 md:hidden'>
                            <h2 tw='text-white text-base tiny:text-xl font-semibold ml-[6vw] max-w-[320px] xsm:max-w-[360px]'>Your Favorites Playlist</h2>
                            <div tw='flex flex-nowrap overflow-x-auto justify-between items-center mt-2 pl-2' css={[ScrollBarStyle]}> <MobileVideoCardRow videos={favoriteVideos} /> </div>
                        </div>
                    </>
                }

                <div tw='hidden md:block'>
                    <AchievementsContainer />

                </div>

            </div>
        </div>
    );
};

export default HomeContainer;
