import React from 'react';
import 'twin.macro'
import TimesTablesChild from 'static/images/TimesTableCreateAccount/TimesTablesChild.png';
import PurpleStar from 'static/images/TimesTableCreateAccount/PurpleStar.png';
import MultiplicationSign from 'static/images/TimesTableCreateAccount/MultiplicationSign.png';
import GoldMusicNote from 'static/images/TimesTableCreateAccount/GoldMusicNote.png';

const TimesTableImages = () => {
    return (
        <>
            <div tw=' hidden lg:block absolute top-[32.66%] left-[30px]  z-20'>
                <img src={TimesTablesChild} tw='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' alt='Platinum Record' />
            </div>
            <div tw=' hidden lg:block absolute bottom-[12.76%] left-[-50px]  z-10'>
                <img src={PurpleStar} tw='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' alt='Platinum Record' />
            </div>
            <div tw=' hidden lg:block absolute top-[36.76%] left-[-10px]  z-20'>
                <img src={MultiplicationSign} tw='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' alt='Platinum Record' />
            </div>
            <div tw=' hidden lg:block absolute top-[26.76%] right-[50px] xl:right-[90px]  z-20'>
                <img src={GoldMusicNote} tw='h-auto sm:h-full sm:max-h-[100%] w-full max-w-none  sm:max-w-[100%]' alt='Platinum Record' />
            </div>
        </>
    );
};

export default TimesTableImages;