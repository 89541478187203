import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import Slider from 'react-slick';
import styled from 'styled-components';
import StudentExperience from './Slides/StudentExperience';
import HowMathChallengesWork from './Slides/HowMathChallengesWork';

const StyledSlider = styled(Slider)`
.slick-active  {
    /* opacity: .75; */
    color: yellow !important;
}
`;


const GettingStartedSlider = ({ recent_videos, setPlayTutorialVideo }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    console.log("🚀 ~ GettingStartedSlider ~ currentSlide:", currentSlide)
    const settings = {
        dots: true,
        accessibility: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: true,
        // autoplaySpeed: 5000,
        arrows: false,
        beforeChange: (prev, next) => {
            setCurrentSlide(next);
        },
        appendDots: dots => (
            <div tw='bottom-1.5'>
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
        customPaging: index => (
            <div tw='w-4 h-4 rounded-full bg-[#1C0057] [box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.30)]' css={[currentSlide === index && tw`bg-[#D9D9D9]`]} />
        )
    };

    
    return (
        <StyledSlider {...settings} >
            <StudentExperience />
            <HowMathChallengesWork/>
        </StyledSlider>
    );
};

GettingStartedSlider.propTypes = {
    anyProp: PropTypes.any,
}

export default GettingStartedSlider;

