import React, { useMemo } from 'react';
import tw, { styled, css } from 'twin.macro';
import WhiteXButton from 'images/icons/WhiteXButton';

const ScrollBarStyle = css`
    ::-webkit-scrollbar {
        background: none;
        width: 18px;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #a5a5a5;
        border-radius: 20px;
        max-height: 60px;
        border: 6px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }
    ::-webkit-scrollbar-track {
        margin-top: 10px;
        margin-bottom: 10px;
    }
`;

const CloseButtonCss = css`
    background: white;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    color: #6a7277;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 0px 2.5px rgba(0, 0, 0, 0.25));
    cursor: pointer;
    position: absolute;
    z-index: 1000000;

    &:hover {
        background: #dddddd;
    }

    &:active {
        background: #bbbbbb;
        filter: none;
    }
    :nth-last-child(1n) {
        padding-top: 0;
    }
`;

const Container = styled.div(({ level, border }) => [
    tw`flex flex-col rounded-[27px] w-full h-full p-3 min-w-[330px] sm:min-w-[630px]`,
    level === 0 && css`background: white;`,
    level === 1 && css`background: linear-gradient(0deg, #5EC4DE, #847EDD);`,
    level === 2 && css`background: linear-gradient(0deg, #1CC8E1, #D5A04D);`,
    level === 3 && css`background: linear-gradient(0deg, #19D9F5, #C2D7D7, #96ADB2, #CFF9DB);`
]);

const LevelText = styled.h1(({ level }) => [
    tw`flex justify-center uppercase font-black text-center text-2xl sm:text-3xl md:text-[40px] font-Poppins`,
    css`text-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);`,
    level === 1 && tw`text-[#C6C6C6]`,
    level === 2 && tw`text-[#FFC96E]`,
    level === 3 && css`
    background: linear-gradient(90deg, #867CC0 2.88%, #C6EDF2 54.9%, #82F4A2 98%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;`
]);

const QuizIntroAttempts = ({ previousScores, level, videoTitle, setIsOpen }) => {
    let levels = ['', 'Silver', 'Gold', 'Platinum'];

    const previousScoreList = useMemo(() => {
        if (!previousScores) return null;
        const scores = [...previousScores];
        // scores.reverse();
        return scores.map((score, index) => {
            // eslint-disable-next-line no-sparse-arrays
            let ordinalNum = (n) => (n += [, 'st', 'nd', 'rd'][(n % 100 >> 3) ^ 1 && n % 10] || 'th');
            return (
                <div tw='text-2xl font-normal pt-4 text-white' key={index}>
                    {ordinalNum(index + 1)} Attempt: <span tw='font-normal ml-3'>{score}%</span>
                </div>
            );
        });
    }, [previousScores]);
    return (
        <Container
            level={level}
        >
            <div
                tw='relative rounded-[27px] w-full h-full min-w-full sm:min-w-[593px] min-h-[331px] bg-[#211542] pt-10 pb-6 font-Poppins'

            >
                <LevelText level={level}>  {levels[level]} challenge</LevelText>

                <div tw='text-lg sm:text-xl font-bold text-white text-center mt-3 sm:mt-7 mx-3'>{videoTitle}</div>
                <div tw='relative maxHeight[188px] overflow-y-auto mt-5 '>
                    <div tw='flex justify-center items-center flex-col h-full  ' css={[ScrollBarStyle]}>
                        {previousScoreList}
                    </div>
                </div>

                <div
                    tw='absolute right-[-15px] top-[-15px] sm:top-[-30px] sm:right-[-25px]' onClick={() => setIsOpen(false)}
                >
                    <svg xmlns='http://www.w3.org/2000/svg' tw='w-[36px] sm:w-[48px] h-[36px] sm:h-[48px]' viewBox='0 0 48 48' fill='none'>
                        <g clip-path='url(#clip0_3019_79126)'>
                            <path fill-rule='evenodd' clipRule='evenodd' d='M23.9999 0C37.2548 0 48 10.7452 48 23.9999C48 37.2548 37.2548 48 23.9999 48C10.7452 48 0 37.2548 0 23.9999C0 10.7452 10.7452 0 23.9999 0ZM23.9999 23.4071C24.3275 23.4071 24.5929 23.6725 24.5929 23.9999C24.5929 24.3275 24.3275 24.5929 23.9999 24.5929C23.6725 24.5929 23.4071 24.3275 23.4071 23.9999C23.4071 23.6725 23.6725 23.4071 23.9999 23.4071Z' fill='#D6D4D4' />
                            <path fill-rule='evenodd' clipRule='evenodd' d='M23.998 0.837891C36.7901 0.837891 47.1602 11.2079 47.1602 23.9999C47.1602 36.792 36.7901 47.1621 23.998 47.1621C11.2059 47.1621 0.835938 36.792 0.835938 23.9999C0.835938 11.2079 11.2059 0.837891 23.998 0.837891ZM23.998 23.4071C24.3255 23.4071 24.591 23.6726 24.591 23.9999C24.591 24.3275 24.3255 24.5929 23.998 24.5929C23.6706 24.5929 23.4051 24.3275 23.4051 23.9999C23.4051 23.6726 23.6706 23.4071 23.998 23.4071Z' fill='url(#paint0_angular_3019_79126)' />
                            <circle cx='24' cy='24' r='1' fill='#B6A9F0' />
                            <path d='M13 36L35 14' stroke='white' strokeWidth='4' strokeLinecap='round' />
                            <g filter='url(#filter0_d_3019_79126)'>
                                <path d='M13 14L35 36' stroke='white' strokeWidth='4' strokeLinecap='round' />
                            </g>
                        </g>
                        <defs>
                            <filter
                                id='filter0_d_3019_79126' x='7' y='12' width='34' height='34' filterUnits='userSpaceOnUse'
                                color-interpolation-filters='sRGB'>
                                <feFlood flood-opacity='0' result='BackgroundImageFix' />
                                <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                                <feOffset dy='4' />
                                <feGaussianBlur stdDeviation='2' />
                                <feComposite in2='hardAlpha' operator='out' />
                                <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
                                <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3019_79126' />
                                <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_3019_79126' result='shape' />
                            </filter>
                            <radialGradient id='paint0_angular_3019_79126' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(23.9981 24) rotate(114.401) scale(23.683)'>
                                <stop offset='0.255208' stop-color='#C2B5FF' />
                                <stop offset='0.790282' stop-color='#3B20B8' />
                            </radialGradient>
                            <clipPath id='clip0_3019_79126'>
                                <rect width='48' height='48' fill='white' />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </Container>
    );
};

export default QuizIntroAttempts;
