import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import tw, { css, styled } from 'twin.macro';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useMedia } from 'react-use';
import queryString from 'query-string';
import { useEvents } from 'context/EventProvider.js';
import { useToggleFavoriteVideo, useVideoStats, useVideosWithStats } from 'state/Student/VideoStats';
import { Button, DTCLyricsPopOver, DTCVideoSessionSurvey, DTCVideoSessionSurveyEnd, ListBox, MobileVideoCardRow } from 'components';
import VideoSlider from 'components/VideoSlider';
import { useVideoSession } from 'context/VideoSession/VideoSessionProvider';
import { useClassStudents } from 'state/Teacher/ClassState';
import { useMainCourseAndVideos } from '../../../../state/CatalogState';
import { useProduct } from '../../../../state/User/UserState';
import { createVideoPoster } from '../../../util';
import AssignmentProgressContainer from './assignmentProgress/AssignmentProgressContainer';
import VideoInfo from './VideoInfo';
import VideoLyrics from './videoContent/VideoLyrics';
import { createVideoSources } from './VideoSources';
import './Session.css';
import RelatedVideosConfig from './TimesTablesRelatedVideos/RelatedVideosConfig';

const ScrollBarStyle = css`

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }
`;

export const ScreenRow = (props = { background: 'white', backgroundImage: 'white' }) => (
    <ScreenRowContainer
        className={`screen-row-container ${props.className || ''}`}
        background={props.background}
        backgroundImage={props.backgroundImage}
        $paddingTop={props.paddingTop}
        $paddingBottom={props.paddingBottom}
        $teacher={props.teacher}
    >
        <div className='screen-row-container--wrapper'>{props.children}</div>
    </ScreenRowContainer>
);

const VideoSession = (props) => {
    const { user, video, assignment, postVideoSessionRanges, postVideoSession } = props;

    // Handle changing of background on video session player
    const [blackBackground, setBlackBackground] = useState(null);
    const [endCard, setEndCard] = useState(false);
    const [pauseCard, setPauseCard] = useState(false);
    const timesTablesProduct = useProduct('times-tables');
    const disableSurveyCard = timesTablesProduct;

    const onVideoPlay = (args) => {
        console.log('[VideoSession] on video end (', args, ')');
        setBlackBackground(true);
        setPauseCard(false);
        setEndCard(false);
    };

    const onVideoEnd = ({ seeking }) => {
        console.log('[VideoSession] on video end (seeking:', seeking, ')');
        if (!seeking) setBlackBackground(false);
        if (seeking) setEndCard(false);
        else setEndCard(true);
    };

    const onVideoPause = (event) => {
        const seeking = event.issuer === 'ui-seek';
        console.log('[VideoSession] on video pause (seeking:', seeking, ')');
        if (!seeking)
            setPauseCard(true);
    };

    // get the video session and player
    // console.log('[VideoSession] rendering video session', video);
    const [Player, sessionId, { play, pause }, createSession, video_id] = useVideoSession(video?.id, {
        fluid: true,
        playerType: 'bitmovin',
        events: {
            onPlay: onVideoPlay,
            onPause: onVideoPause,
            onEnd: onVideoEnd
        }
    });
    const allVideos = useVideosWithStats();
    const navigate = useNavigate();
    const postEvent = useEvents();
    const dispatch = useDispatch();
    const { students } = useClassStudents(null);
    const timesTableProduct = useProduct('times-tables');
    const listBoxItems = students.map((student) => ({ name: `${student.user.first_name}'s Progress`, id: student.user.id, item: student }));
    const [selectedStudent, setSelectedStudent] = useState(listBoxItems[0]);
    const isPhone = useMedia('(min-width: 500px)');
    // const [pinned, togglePinned] = useToggle(false);
    const hideProgressBar = useMedia('(max-width: 1300px)');
    const instructor = user ? !!user.instructor : false;
    const parent = user ? !!user.is_parent : false;

    const course = useMainCourseAndVideos();
    // find courses that are related to the video
    // const relatedPlaylist = course
    //     ? course?.playlists?.find((playlist) => playlist?.videos && !!playlist.videos.find((v) => v && v.id === video.id))?.videos
    //     : [];

    // filter out the video in the session from the playlist
    // let relatedVideos = relatedPlaylist?.filter((v) => v.id !== video.id);
    // // for the two video that do not have other videos in a playlist manually added related videos
    // if (video.id === 'd8f3000a-f506-4ca6-912b-196643b25cc6') {
    //     // for ratios and percents a related video would be operations of fractions
    //     relatedVideos = allVideos.filter((v) => v.id === '1d4e7db1-d5cc-4252-a57e-85cc3a28ca08');
    // }
    // if (video.id === '092fbe10-302a-4d0f-a867-695e6028f2e0') {
    //     // for Pythagorean a related video would be definitions
    //     relatedVideos = allVideos.filter((v) => v.id === 'ad3eaab8-8d54-4fba-9937-14f9ac8e06d3');
    // }

    // add the relatedVideos array
    // relatedVideos = useVideosWithStats();

    // favorite video
    const toggleFavoriteVideo = useToggleFavoriteVideo(video?.id);
    const videoStats = useVideoStats(video?.id);
    // if the props or sessionid or video changes post the session
    useEffect(() => {
        if (video) {
            if (postVideoSession)
                postVideoSession(video.id, sessionId);
        }
    }, [postVideoSession, props, sessionId, video, video.id]);

    // when video begins
    const onVideoStart = () => {
        // create the session
        postEvent('video:start', { videoId: video.id });
    };

    // the user clicks "take challenge"
    const handleTakeChallenge = (level = null) => {
        if (video.quiz_id) {
            const path = `/quiz/${video.quiz_id ?? video.id}`;
            const query = { video_id: video.id };
            if (level) {
                query['level'] = level;
            }
            navigate(`${path}?${queryString.stringify(query)}`);
        }
    };

    const { data: relatedVideoIDs } = useSWR(`/api/videos/${video.id}/related/`);
    let relatedVideos = relatedVideoIDs?.map((vID) => allVideos?.map((v) => v.id === vID ? v : null)).flat().filter((v) => v !== null);
    if (timesTableProduct) relatedVideos = RelatedVideosConfig[video.id]?.map((vID) => allVideos?.map((v) => v.id === vID ? v : null)).flat().filter((v) => v !== null);
    // console.log('[VideoSession] related video ids', relatedVideoIDs);

    // create the video player option
    const sources = useMemo(() => createVideoSources(video), [video]);
    if (!video) return null;

    const sliderResponsive = [
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 938,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ];

    const metaData = {
        // viewerUserId: user.id,
        videoId: video.id,
        videoTitle: video.title,
        videoSeries: 'pre-algebra',
        videoStreamType: 'on-demand'
        // accountName: user.accountName
    };

    let videoPlayer = (
        <Player
            aria-label={`video player for ${video.title}`}
            user={user}
            video={video}
            assignment={assignment}
            key={sessionId}
            className='sticky-video'
            autoplay={false}
            controls={true}
            sources={sources}
            poster={createVideoPoster(video?.url_thumbnail ?? '')}
            metaData={metaData}
            onReady={onVideoStart}
            onPlay={onVideoPlay}
            onEnd={onVideoEnd}
            postVideoSessionRanges={postVideoSessionRanges}
        />
    );

    const watchAgain = () => {
        // create a new session
        console.log('[VideoSession] Clicked "Watch Again"');

        // create the new
        createSession(video_id);

        // hide the end card
        setEndCard(false);
        setPauseCard(false);

        // play again
        play();
    };

    const showEndCard = !parent && endCard;
    const showPauseCard = (!parent && pauseCard) && !disableSurveyCard;

    // disable quiz button
    return (
        <VideoSessionContainer className='video-session-container' $teacher={instructor} tw='font-Poppins'>

            {parent &&
                <div tw='flex justify-end py-5 max-w-[1110px] mx-auto '>
                    <div tw='max-w-[400px]'>
                        <ListBox
                            items={listBoxItems} onChange={(value) => setSelectedStudent(value)}
                            selected={selectedStudent} variant='transparent-small'
                        />
                    </div>

                </div>
            }

            <ScreenRow
                className={!parent && 'info-section'}
                $background={blackBackground ? 'none' : 'none'}
                $paddingBottom={blackBackground}
                $paddingTop='0'
                $teacher={instructor}
            >

                <div
                    tw='relative'
                    css={[
                        css`
                            @media only screen and (max-height: 740px) {
                                display: flex;
                                justify-content: center;
                                margin-top: 50px;
                            }
                        `
                    ]}
                >
                    <DTCLyricsPopOver
                        lyrics={video.lyrics}
                        lyricsFile={video.lyrics_file}
                        copyrightYear={video.copyright_year}
                        title={video.title}
                    />

                    {videoPlayer}

                    {showPauseCard && <DTCVideoSessionSurvey setEndCard={setPauseCard} videoId={video.id} videoStats={videoStats} />}

                    {showEndCard &&
                        <DTCVideoSessionSurveyEnd
                            setEndCard={setEndCard}
                            videoStats={videoStats}
                            video={video}
                            onTakeChallenge={handleTakeChallenge}
                            watchAgain={watchAgain}
                            disableSurveyCard={disableSurveyCard}
                        />}

                </div>
                <VideoInfo
                    video={video}
                    onTakeChallenge={handleTakeChallenge}
                    lyrics={video.lyrics_file}
                    toggleFavorite={() => dispatch(toggleVideoFavorite(video.id))}
                    quiz_level_goal={assignment?.quiz_level_goal}
                    quiz_score_goal={assignment?.quiz_score_goal}
                    stats={video.stats}
                    hideProgressBar={hideProgressBar}
                    assignment={assignment}
                    parent={parent}
                    achievements={video.stats && video.stats.achievements ? video.stats.achievements : null}
                    toggleFavoriteVideo={toggleFavoriteVideo}
                    selectedStudent={selectedStudent}
                />
            </ScreenRow>

            <div tw='block md:hidden'>
                <ScreenRowLyrics className='lyrics-section' background='none' >
                    <div className='screen-row-container--wrapper' >
                        <VideoLyrics
                            lyricsFile={video.lyrics_file}
                            lyrics={video.lyrics}
                            copyrightYear={video.copyright_year}
                        />
                    </div>
                </ScreenRowLyrics>

                {!video.quiz_id ? null : (
                    <div tw='mt-5 sm:mt-[70px] px-2.5 sm:px-5'>
                        <AssignmentProgressContainer
                            video={video}
                            assignment={assignment}
                            handleTakeChallenge={handleTakeChallenge}
                            instructor={instructor}
                            parent={parent}
                            selectedStudent={selectedStudent}
                            setSelectedStudent={setSelectedStudent}
                            listBoxItems={listBoxItems}
                        />
                    </div>
                )}
            </div>

            <div tw='hidden md:block'>
                {!video.quiz_id ? null : (
                    <ScreenRow tw='mt-[-70px]'>
                        <AssignmentProgressContainer
                            video={video}
                            assignment={assignment}
                            handleTakeChallenge={handleTakeChallenge}
                            instructor={instructor}
                            parent={parent}
                            selectedStudent={selectedStudent}
                            setSelectedStudent={setSelectedStudent}
                            listBoxItems={listBoxItems}
                        />
                    </ScreenRow>

                )}

                <ScreenRowLyrics className='lyrics-section' background='none'>
                    <div className='screen-row-container--wrapper'>
                        <VideoLyrics
                            lyricsFile={video.lyrics_file}
                            lyrics={video.lyrics}
                            copyrightYear={video.copyright_year}
                        />
                    </div>
                </ScreenRowLyrics>
            </div>

            {relatedVideos?.length > 0 && (
                <div tw='pb-10 bg-[#1C0057]'>
                    <div tw='bg-[#1C0057] w-full p-9 xl:p-0 py-9 hidden lg:block'>
                        <div tw='text-center text-white font-semibold text-xl mb-6 '>Related Videos</div>

                        <VideoSlider
                            {...props} sliderResponsive={sliderResponsive} videos={relatedVideos} cols={4}
                            teacher={false} arrows={true} noSideArrow={false} />
                    </div>
                    <div tw='bg-[#1C0057] py-9 lg:hidden '>
                        <div tw='text-left text-white font-semibold text-xl ml-5 md:ml-12'>Related Videos</div>
                        <div tw='hidden md:grid grid-cols-2 justify-between items-center px-7' css={[ScrollBarStyle]}> <MobileVideoCardRow videos={relatedVideos} column={true} /> </div>

                        <div tw='flex flex-col md:hidden overflow-x-auto justify-between items-center' css={[ScrollBarStyle]}> <MobileVideoCardRow videos={relatedVideos} column={true} /> </div>

                    </div>
                </div>
            )}
        </VideoSessionContainer>
    );
};

// video session style container
const VideoSessionContainer = styled.div`
    position: relative;
    flex-grow: 1;
    //height: ${(props) => (props.$teacher === true ? 'none' : '100%')};
    background: #1C0057;

    .info-section {
        padding-top: 50px;
        margin-top: -50px;
        @media screen and (max-width: 500px) {
            margin-top: -90px;
        }

    }

    .video-player {
        transition: width 0.2s ease-in-out, height 0.2s ease-in-out, transform 0.38s ease-in-out;

        .sticky-video {
            position: fixed;
            top: 15px;
            left: auto;
            max-width: 280px;
            max-height: 158px;
            width: 280px;
            height: 158px;
        }

        @media screen and (min-width: 1120px) {
            .sticky-video {
                transform: translateX(-80%);
            }
        }
        @media screen and (min-width: 1300px) {
            .sticky-video {
                transform: translateX(-115%);
            }
        }
    }

    .assignment-section {
        margin: 21px auto 42px;
        @media screen and (max-width: 500px) {
            margin: 0;
            padding-left: 0px;
            padding-right: 0px;
        }

        .screen-row-container--wrapper {
            max-width: 1250px;
           
        }

        .assignment-section--title {
            font-weight: 800;
            font-size: 32px;
            line-height: 44px;
            text-align: center;
            color: #ffffff;
        }

        .assignment-section--content {
            margin: 32px 0 0;
            background: rgba(42, 38, 77, 0.5);
            box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 15px;
            @media screen and (min-width: 1024px) {
                padding: 45px 40px;
            }
            @media screen and (max-width: 500px) {
                border-radius: 0px;
            }
        }
    }

    .related-videos-section {
        margin: 21px auto 42px;

        .screen-row-container--wrapper {
            max-width: 1250px;
        }

        .related-videos-section--title {
            font-weight: 800;
            font-size: 32px;
            line-height: 44px;
            text-align: center;
            color: #ffffff;
        }

        .related-videos-section--content {
            margin: 32px 0 0;
            background: #ffffff;
            border-radius: 10px;
            padding: 27px 15px;
        }
    }
`;

const ScreenRowContainer = styled.div`
    background: ${(props) => props.background};
    background-image: ${(props) => props.backgroundImage};
    background-size: cover;
    background-position-x: 50%;
    position: relative;
    padding-top: ${(props) => props.$paddingTop || 0};
    padding-bottom: ${(props) => props.$paddingBottom || 0};
    padding-left: 40px;
    padding-right: 40px;
    transition: all 1.5s ease;
    height: ${(props) => (props.$teacher ? '100%' : 'none')};

    @media screen and (max-width: 724px) {
        padding-left: 25px;
        padding-right: 25px;
    }
    @media screen and (max-width: 640px) {
        padding-left: 10px;
        padding-right: 10px;
    }

    .screen-row-container--wrapper {
        max-width: var(--video-session-layout-width);
        margin: 0 auto;
        margin-top: 70px;
    }

    .screen-row-container.lyrics-section {
        @media screen and (max-width: 500px) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .screen-row-container.assignment-section {
        @media screen and (max-width: 500px) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
`;

const ScreenRowLyrics = styled.div`
    background: ${(props) => props.background};
    background-image: ${(props) => props.backgroundImage};
    background-size: cover;
    background-position-x: 50%;
    position: relative;
    padding-top: ${(props) => props.$paddingTop || 0};
    padding-bottom: ${(props) => props.$paddingBottom || 0};
    padding-left: 40px;
    padding-right: 40px;
    transition: all 1.5s ease;
    height: ${(props) => (props.$teacher ? '100%' : 'none')};

    /* max-width: 1100px;
    margin: 0 auto; */

    @media screen and (max-width: 1100px) {
    }

    @media screen and (max-width: 724px) {
        padding-left: 25px;
        padding-right: 25px;
    }
    @media screen and (max-width: 640px) {
        padding-left: 10px;
        padding-right: 10px;
    }
    @media screen and (max-width: 500px) {
        padding-left: 10px;
        padding-right: 10px;
    }

    .screen-row-container--wrapper {
        max-width: var(--video-session-layout-width);
        margin: 0 auto;
        @media screen and (max-width: 500px) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
`;

export default VideoSession;
