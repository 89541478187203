import React, { useMemo, createContext, useCallback, useContext, useEffect, useState } from 'react';
import 'twin.macro';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleAPIError } from 'utils/api-client';
import axios from 'axios';
import { formatNetworkError, handleNetworkError } from '../../lib/axios';
import { MUZOLOGY_API_URL } from '../../config';
import { useBrowserID } from './BrowserTracker';

export const AuthContext = createContext(null);

// configure a client specifically for login API calls
export const apiLoginClient = axios.create({
    baseURL: MUZOLOGY_API_URL,
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    headers: { 'Content-Type': 'application/json' }
});

function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
}

const AuthProvider = (props) => {
    const navigate = useNavigate();
    // const { setErrorMessage } = useError();
    const [errorMessage, setErrorMessage] = useState(null);
    const browserID = useBrowserID();
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [initialStore, setInitialStore] = useState({});
    // const [csrfToken, setCSRFToken] = useState(null);

    // get the   session on mount
    useEffect(() => {
        console.debug('<AuthProvider> initial getSession - browserID:', browserID);
        getSession()
            .then((response) => {
                console.debug('<AuthProvider> initial getSession success', response);
            })
            .catch((error) => {
                console.debug('<AuthProvider> initial getSession error', error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // clear the auth state
    const clearAuthState = useCallback(() => {
        // Clear client-side auth state (e.g., remove tokens, reset state)
        deleteCookie('sessionid');
        setIsLoggedIn(false);
        setInitialStore({});
    }, []);

    // get the current session
    const getSession = useCallback(async () => {
        // console.log('[Auth] getSession');
        return apiLoginClient
            .get('/api/session/', {
                withCredentials: true,
                headers: {
                    'X-Browser-ID': browserID
                }
            })
            .then(({ data }) => {
                if (data?.isAuthenticated) {
                    console.debug('<AuthProvider> Session: Logged in');
                    // return getCSRF().then(() => {
                    setIsLoggedIn(true);
                    return true;
                    // });
                } else {
                    console.debug('<AuthProvider> Session: Not logged in');
                    setIsLoggedIn(false);
                    return false;
                }
            })
            .catch((err) => {
                console.error('Error getting session');
                const message = formatNetworkError(err);
                console.log(message);
                setErrorMessage(message);
                handleNetworkError(err);
                throw err;
            });
    }, [browserID]);

    const reload = useCallback((redirect = null) => {
        // reload the auth
        getSession().then(r => {
            console.debug('<AuthProvider> reload:', r);
            if (r) {
                console.debug('<AuthProvider> reload: logged in');
                if (redirect) {
                    navigate(redirect);
                }
            }
        });
    }, [getSession, navigate]);

    // logout of the app

    const logout = useCallback((redirect = null) => {
        console.debug('<AuthProvider> logout');

        // Clear client-side auth state (e.g., remove tokens, reset state)
        clearAuthState();
        // window.location.href = MUZOLOGY_WEBSITE_URL;

        // Initiate the backend logout asynchronously
        // if (navigator?.sendBeacon) {
        if (false) {
            // send beacon is a POST request that is sent even if the user navigates away
            const url = `${MUZOLOGY_API_URL}/api/logout/`;
            navigator.sendBeacon(url, JSON.stringify({ browserID }));

            // clear the auth state
            clearAuthState();

            // redirect if needed
            if (redirect === false) {
                // don't redirect
                console.log('Not redirecting');
            } else {
                console.log('Redirecting:', redirect ?? '/login');
                // window.location.href = redirect ?? '/login';
                navigate(redirect ?? '/login');
            }
        } else {
            // Fallback for browsers without sendBeacon support.
            // fetch('/api/logout/', { method: 'POST', keepalive: true });
            apiLoginClient('/api/logout/', { headers: { 'X-Browser-ID': browserID } })
                .then(({ data }) => {
                    // redirecting to the login page will reload the page
                    // setIsLoggedIn(false);
                    // if (redirect === false) {
                    //     // don't redirect
                    //     console.log('Not redirecting');
                    // } else {
                    //     // // window.location.href = redirect ?? '/login';
                    //     // navigate(redirect ?? '/login');
                    // }
                    clearAuthState();

                    // redirect if needed
                    if (redirect === false) {
                        // don't redirect
                        console.log('Not redirecting');
                    } else {
                        console.log('Redirecting:', redirect ?? '/login');
                        // window.location.href = redirect ?? '/login';
                        navigate(redirect ?? '/login');
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    }, [browserID, clearAuthState, navigate]);

// old logout
//     const logout = useCallback((redirect=null) => {
//         console.debug('<AuthProvider> logout');
//         return apiLoginClient('/api/logout/', {
//             headers: {
//                 'X-Browser-ID': browserID
//             }
//         })
//             .then(({ data }) => {
//                 // redirecting to the login page will reload the page
//                 // setIsLoggedIn(false);
//                 window.location.href = redirect ?? '/login';
//             })
//             .catch((err) => {
//                 console.log(err);
//             });
//     }, [browserID]);

  
    // login to the app, async function returns the initial payload or false
    const login = useCallback(async (username, password) => {
        try {
            // console.log('Logging in', username);
            setIsLoggingIn(true);

            // clear the login flag during login,
            // to prevent the app from logging back out due to the URL '/login'
            // console.log('setLoggedIn: null');
            // setIsLoggedIn(null);

            // perform a login
            const response = await apiLoginClient.post(
                '/api/login/',
                { username, password },
                {
                    headers: {
                        'X-Browser-ID': browserID
                    }
                }
                // {
                //     withCredentials: true,
                //     headers: {
                //         'Content-Type': 'application/json'
                //         // 'X-CSRFToken': csrfToken
                //     }
                // }
            );

            // login successful, get the payload
            const { data: payload } = response;

            // store the initial payload - need to get the payload into the user provider
            setInitialStore(payload);

            // clear the login flag during login,
            // to prevent the app from logging back out due to the URL '/login'
            // console.log('setLoggedIn: null');
            setIsLoggedIn(null);

            // redirect to change the URL before changing isLoggedIn state
            // check for redirect
            let newPath = '/';
            if (window.location.search.startsWith('?next='))
                newPath = decodeURIComponent(window.location.search.slice(6));
            console.log('new path', newPath);
            navigate(newPath);
            // console.log('Redirecting to app', newPath);

            // login complete
            // console.log('setIsLoggingIn false');
            setIsLoggingIn(false);
            // console.log('setIsLoggedIn true');
            setIsLoggedIn(true);
            return true;
        } catch (error) {
            // login failure
            console.log('Login error', error);
            handleAPIError(error);

            // set not logged in
            setIsLoggingIn(false);
            setIsLoggedIn(false);

            // if (error.response) {
            //     // The request was made and the server responded with a status code that falls out of the range of 2xx
            //     console.log(error.response.data);
            //     console.log(error.response.status);
            //     console.log(error.response.headers);
            // }

            throw error;
        }
    }, [browserID, navigate]);

    // build the context
    const context = useMemo(() => {
        return {
            login,
            logout,
            reload,
            isLoggedIn,
            isLoggingIn, //: isLoggedIn === null,
            initialStore
        };
    }, [initialStore, isLoggedIn, isLoggingIn, login, logout, reload]);

    // if errorMessage is set, close it

    // first render, don't mount anything until we check any existing credentials
    if (isLoggedIn === null) {
        // still checking if our credentials are valid
        // if(errorMessage) {
        //     return (
        //         <div tw='relative'>
        //             {errorMessage && <ErrorMessage message={errorMessage} close={null}/>}
        //         </div>
        //     );
        // }
        return null;
        // return <div tw='background[green] h-full w-full' />;
    }

    return (
        <AuthContext.Provider value={context}>
            {/*{errorMessage && <ErrorMessage message={errorMessage} close={onClose}/>}*/}
            {/*<ErrorMessage message='Error' close={onClose}/>*/}
            {props.children}
        </AuthContext.Provider>
    );
};

export function useAuth() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}

// useAuth with auto login
export function useAuthLogin(username, password) {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthLogin must be used within an AuthProvider');
    }

    useEffect(() => {
        console.log('[useAuthLogin] initial login()');
        if (username && password) {
            const { login } = context;
            login(username, password);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username, password]);

    return context;
}

export default AuthProvider;
