import React, { useMemo } from 'react';

import RecordImageSilver from 'static/images/records/silver.png';
import RecordImageGold from 'static/images/records/gold.png';
import RecordImagePlatinum from 'static/images/records/platinum.png';
import RecordImageSilverDTC from 'static/images/records/DTC/silver.png';
import RecordImageGoldDTC from 'static/images/records/DTC/gold.png';
import RecordImagePlatinumDTC from 'static/images/records/DTC/platinum.png';
const record_colors = ['Warmup', 'Silver', 'Gold', 'Platinum'];

const RecordImg = (props) => {
    // static defaultProps = {
    //     constrained: null,
    //     level: 0,
    //     locked: false
    // };
    const level = useMemo(() => {
        // clip value 0 ... 3
        return Math.min(Math.max(0, props.level), 3);
    }, [props.level]);

    const src = useMemo(() => {
        switch (level) {
            default:
            case 1:
                return  RecordImageSilverDTC ;
            case 2:
                return RecordImageGoldDTC;
            case 3:
                return RecordImagePlatinumDTC;
        }
    }, [level, props.dtc]);

    let style = props.style || {};

    if (props.constrained === 'x') {
        // constrain by width
        style.width = '100%';
    }

    return <img
        className={`record-img ${props.className}`}
        style={style}
        src={src}
        alt={`${record_colors[level]} Record`}
    />;
};

export default RecordImg;
