import React, { useMemo, useState } from 'react';
import tw, { styled, css } from 'twin.macro';

const HeaderBlock = styled.div(({ $showLyrics }) => [
    tw`w-full bg-[#3E257A] text-white text-base font-medium flex justify-between py-3 px-8 rounded-lg relative cursor-pointer`,
    $showLyrics && tw`rounded-b-none bg-[#6441B9]`

]);

const LyricsContainer = styled.div`
    width: 100%;
    position: relative;
    padding: 20px 0;
    background: #1C0057;
    @media only screen and (max-width: 768px) {
        padding: 10px 0;
    }

    .lyrics-container {
        background-color:  #3E257A;
        border-radius: 0 0 8px 8px;
        display: none;
        padding: 20px 0;

        &.show-lyrics {
            display: block;

        }

        .lyrics-wrapper {
            position: relative;
            padding-left: 23px;
            padding-right: 23px;

            .lyrics-main {
                position: relative;
                width: 100%;
                height: 590px;
                overflow-y: scroll;
                z-index: 20;
                height: 100vh;
                ::-webkit-scrollbar {
                    background: transparent;
                    width: 11px;
                    padding: 0 4px;
                }

                ::-webkit-scrollbar-thumb {
                    background-color: #6441B9;
                    border-radius: 20px;
                    max-height: 20px;
                }
            }

            .lyrics {
                font-weight: 500;
                font-size: 16px;
                line-height: 25px;
                color: #fff;
                //width: 40%;

                p {
                    margin-bottom: 12px;
                }

                @media only screen and (max-width: 768px) {
                    font-size: 18px;
                    width: 85%;
                }
            }

            .copyright {
                color: #fff;
                font-weight: 500;
                font-size: 16px;
                margin: 40px 23px 10px 0;
                text-align: center;

                @media only screen and (max-width: 768px) {
                    font-size: 15px;
                }
            }

            .drawings {
                width: 55%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                position: absolute;
                top: 0;
                right: 0;
                height: calc(100% - 70px);
                padding: 30px 0;
                z-index: 10;
            }
        }
    }
`;

const VideoLyrics = ({ hidePrint, lyrics, lyricsFile, copyrightYear }) => {
    const [showLyrics, setShowLyrics] = useState(false);
    const toggleShowLyrics = () => {
        setShowLyrics(!showLyrics);
    };

    const printLyrics = (lyricsFile) => {
        console.log('print lyrics', lyricsFile);
        if (lyricsFile) {
            if( lyricsFile.startsWith('http') )
                window.open(lyricsFile);
            else
                window.open(`https://public.muzology.com/lyrics/${lyricsFile}`);
        }
    };

    const lyricsNode = useMemo(() => {
        if (!showLyrics) {
            return null;
        }

        // FIXME: this is a hack for markdown
        // const newLyrics = props.lyrics?.replaceAll('\r\n', '\r\n\r\n') ?? '';
        const newLyrics = lyrics ?? '';

        const copyright = `Copyright © ${copyrightYear ?? '2017'} Muzology, LLC. All rights reserved.`;
        return (
            <div className='lyrics-wrapper'>
                <div className='lyrics-main'>
                    <div className='lyrics' tw='whitespace-pre-wrap'>
                        {newLyrics}
                        {/*<ReactMarkdown source={newLyrics} />*/}
                    </div>
                    <div className='copyright'>{copyright}</div>
                </div>
            </div>
        );
    }, [showLyrics, lyrics, copyrightYear]);

    return (
        <LyricsContainer>
            <HeaderBlock $showLyrics={showLyrics} onClick={toggleShowLyrics}>
                <div tw='flex'>
                    <div tw='font-medium text-base  mr-2' >
                        Lyrics
                    </div>
                    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='none'>
                        <g clipPath='url(#a)'>
                            <path fill='#fff' d='M1.834 20.166v-16.5c0-.504.18-.936.539-1.295.36-.36.79-.539 1.294-.538h10.084c.504 0 .936.18 1.295.539.36.359.539.79.538 1.294v.39a4.821 4.821 0 0 0-1.008.618c-.306.245-.58.52-.825.825V3.666H3.667v11h10.084v-3.667c.244.306.52.58.825.825.305.245.641.451 1.008.62v2.222c0 .504-.18.936-.539 1.295-.36.36-.79.539-1.294.538H5.5l-3.667 3.667Zm3.667-7.333h3.666v-1.834H5.501v1.834Zm11.916-1.834a2.652 2.652 0 0 1-1.948-.802 2.652 2.652 0 0 1-.802-1.948c0-.764.268-1.413.802-1.948a2.652 2.652 0 0 1 1.948-.802c.168 0 .329.016.482.046.152.03.297.069.435.115V.916h3.667v1.833h-1.834v5.5c0 .764-.267 1.414-.802 1.948a2.652 2.652 0 0 1-1.948.802Zm-11.916-.916h6.416V8.249H5.501v1.834Zm0-2.75h6.416V5.499H5.501v1.834Z' />
                        </g>
                        <defs>
                            <clipPath id='a'>
                                <path fill='#fff' d='M0 0h22v22H0z' />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div tw='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer'>
                    {showLyrics === true ?

                        <svg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M15 9L8.37629 1.43004C8.17708 1.20238 7.82292 1.20238 7.62371 1.43004L1 9' stroke='white' strokeWidth='2' strokeLinecap='round' />
                        </svg>
                        :
                        <svg width='16' height='10' viewBox='0 0 16 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M1 1L7.62371 8.56996C7.82292 8.79762 8.17708 8.79762 8.37629 8.56996L15 1' stroke='white' strokeWidth='2' strokeLinecap='round' />
                        </svg>
                    }
                </div>

                {showLyrics === true && (
                    <div tw='flex '>
                        <button tw='font-medium text-base mr-2' onClick={() => printLyrics(lyricsFile)}>
                            Print Lyrics
                        </button>
                        <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='none'>
                            <path fill='#fff' d='M16.5 15.355h-1.834a.687.687 0 1 1 0-1.375h1.833a1.146 1.146 0 0 0 1.146-1.146V9.167A1.146 1.146 0 0 0 16.5 8.021h-11a1.146 1.146 0 0 0-1.145 1.146v3.667a1.146 1.146 0 0 0 1.145 1.146h1.834a.688.688 0 0 1 0 1.375H5.499a2.521 2.521 0 0 1-2.52-2.521V9.167a2.52 2.52 0 0 1 2.52-2.52h11a2.52 2.52 0 0 1 2.521 2.52v3.667a2.52 2.52 0 0 1-2.52 2.52Z' />
                            <path fill='#fff' d='M14.667 8.02a.697.697 0 0 1-.687-.687v-2.98H8.02v2.98a.688.688 0 1 1-1.375 0V4.124A1.146 1.146 0 0 1 7.792 2.98h6.417a1.146 1.146 0 0 1 1.146 1.145v3.209a.697.697 0 0 1-.688.687Zm-.458 11H7.792a1.146 1.146 0 0 1-1.146-1.146v-6.416a1.146 1.146 0 0 1 1.146-1.146h6.417a1.146 1.146 0 0 1 1.146 1.146v6.416a1.146 1.146 0 0 1-1.146 1.146Zm-6.188-1.375h5.959v-5.958H8.02v5.958Z' />
                        </svg>
                    </div>


                )}
            </HeaderBlock>
            <div className={`lyrics-container ${showLyrics ? 'show-lyrics' : ''}`} >
                {lyricsNode}
            </div>
        </LyricsContainer>
    );
};
export default VideoLyrics;
