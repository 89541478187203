import React, { useState } from 'react';
import PropTypes from 'prop-types';
import tw, { css } from 'twin.macro';
import Modal from 'components/Modal/Modal';


const AnnouncementDialog = ({ setOnboardState }) => {
    const [isOpen, setIsOpen] = useState(true);

    const backgroundGradient = css`
    background-image: linear-gradient(
        180deg,
        hsl(273deg 100% 89%) 0%,
        hsl(269deg 100% 91%) 14%,
        hsl(264deg 100% 93%) 28%,
        hsl(257deg 100% 94%) 42%,
        hsl(245deg 100% 96%) 56%,
        hsl(226deg 100% 96%) 71%,
        hsl(207deg 100% 96%) 81%,
        hsl(186deg 100% 96%) 100%
        );
    `;

    const handleOnClick = () => {
        setOnboardState({ show_new_ui_message: false })
        setIsOpen(false)
    }

    return (
        <Modal isOpen={isOpen} modalChildrenStyle={tw`w-full max-w-[650px] lg:max-w-[720px] mx-auto px-5`}>
            <div tw='py-14 sm:py-[76px] px-5 md:px-8 rounded-xl text-center relative font-Poppins' css={[backgroundGradient]}>
                <div tw='text-black text-xl sm:text-2xl md:text-3xl mt-5 font-semibold text-center'>We've Leveled Up!</div>
                <div tw='text-black text-base sm:text-xl mt-1'>Hope you enjoy our new look.</div>
                <div tw='flex justify-center mt-10'>
                    <button tw='bg-[#6E60E6] w-[250px] sm:w-[360px] h-[65px] text-white text-xl sm:text-2xl font-medium rounded-[12px]' onClick={() => handleOnClick()}>Continue</button>
                </div>
            </div>
        </Modal>
    );
};

AnnouncementDialog.propTypes = {
    anyProp: PropTypes.any,
}

export default AnnouncementDialog;

