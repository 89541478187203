import React, { useMemo, useRef, useState } from 'react';
import useOnClickOutside from 'hooks/userOnClickOutside';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import VideoPlayerOverlay from 'components/VideoPlayerOverlay/VideoPlayerOverlay';
import Subscription from 'app/containers/parent/ParentSettings/Settings/Modal/Subscription';
import { useSearch } from 'context/SearchProvider';
import { useAuth } from '../../context/Auth/AuthProvider';
import ModalDialog from '../ModalDialog/ModalDialog';
import { useOnboardState } from '../../state/User/UserState';
import { useSetOnboardState } from '../../state/OnboardState';
import JoinClassDialog from '../JoinClassDialog/JoinClassDialog';
import SettingsDialog from '../SettingsDialog/SettingsDialog';

const DropdownMenu = ({ user, children }) => {
    const ref = useRef();
    const [isOpen, setOpen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const { search, setDropDown } = useSearch();
    const [hideBanner, setHideBanner] = useState(false);
    const onboard = useOnboardState();
    const setOnboardState = useSetOnboardState();
    const { logout } = useAuth();

    const handleCloseSearch = () => {
        search(null);
        setDropDown(false);
    };

    const handleRemoveTrailBanner = () => {
        setOnboardState({ show_trial_banner: false });
    };

    const items = useMemo(
        () => {
            const DropdownItems = {
                teacher: [
                    {
                        title: 'Settings',
                        action: true,
                        modal_id: 'settings'
                    },
                    {
                        // path: '/settings/subscription',
                        title: 'Manage Subscription',
                        action: true,
                        modal_id: 'subscription'
                    },
                    {
                        separator: true
                    },
                    {
                        action: 'logout',
                        title: 'Log Out'
                    }
                ],
                parent: [
                    {
                        path: '/settings',
                        title: 'Your Settings'
                    },
                    {
                        path: '/settings/child_settings',
                        title: 'Child Settings'
                    },
                    {
                        path: '/settings/subscription',
                        title: 'Manage Subscription'
                    },
                    // {
                    //     title: 'Tutorial Video',
                    //     action: true,
                    //     modal_id: 'tutorial_video'
                    // },
                    {
                        separator: true
                    },
                    {
                        action: 'logout',
                        title: 'Log Out'
                    }
                ],
                student: [
                    {
                        title: `${user?.username}`,
                        action: false
                    },
                    {
                        separator: true
                    },
                    {
                        title: 'Join Class',
                        action: true,
                        modal_id: 'join_class'
                    },
                    {
                        separator: true
                    },
                    {
                        title: 'Log Out',
                        action: 'logout'
                    }
                ],
                child: [
                    {
                        title: `${user?.username}`,
                        action: false
                    },
                    {
                        separator: true
                    },
                    {
                        path: '/settings/settings',
                        title: 'Settings'
                    },
                    {
                        path: '/settings/subscription',
                        title: 'Manage Subscription'
                    },

                    {
                        path: '/logout',
                        title: 'Log Out',
                        action: 'logout'
                    }
                ],
                admin: [
                    {
                        path: '/admin',
                        title: 'Admin Dashboard'
                    }
                ]
            };

            let items = user?.is_parent
                ? DropdownItems.parent
                : user?.instructor
                    ? DropdownItems.teacher
                    : user?.is_parent_account
                        ? DropdownItems.child
                        : DropdownItems.student;
            const { is_admin, is_hijacked } = user ?? {};
            if (is_admin) {
                // concat the admin items
                items.push({ separator: true });
                items.push(DropdownItems.admin[0]);
            }
            return items;
        },
        [user?.instructor, user?.is_parent_account, user?.is_parent, user?.username]
    );

    const handleClickOutside = () => {
        setOpen(false);
    };

    useOnClickOutside(ref, handleClickOutside);

    return (
        <div tw='relative' ref={ref} id='settings'>
            <div onClick={() => setOpen(!isOpen)} tw='flex'>
                {children}
            </div>
            {isOpen && (
                <div
                    tw='xl:origin-top-right absolute right-0 mt-3 py-2 w-52 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-[500]'
                    role='menu'
                    aria-orientation='vertical'
                    aria-labelledby='user-menu'
                >
                    {/*
                    <div tw='mx-4 py-2'>
                        <span
                            tw='block py-0.5 px-2 text-sm rounded-md text-DarkLiver hover:bg-LavenderWeb cursor-pointer'
                            onClick={() => {
                                setOnboardState({ enable_demo_mode: true });
                            }}
                        >
                            Start Tour
                        </span>
                    </div>
                    */}

                    {/* Build the dropdown menu */}
                    {items.map(({ separator, path, title, action, modal_id }, index) => (
                        <div tw='mx-4 py-2' key={index}>
                            {separator ? (
                                <span tw='bg-GainsboroGrey h-0.5 block'/>
                            ) : action ? (
                                <span
                                    tabIndex='0'
                                    tw='block py-0.5 px-2 text-sm rounded-md text-DarkLiver hover:bg-LavenderWeb cursor-pointer truncate max-w-[170px]'
                                    onClick={() => {
                                        if (action === 'logout') {
                                            logout(path ?? '/login');
                                        } else if (modal_id) {
                                            setModalOpen(true);
                                            setOpen(false);
                                            setModalType(modal_id);
                                        }
                                    }}
                                >
                                    {title}
                                </span>
                            ) : (
                                <Link
                                    onClick={() => {
                                        handleCloseSearch();
                                        setOpen(false);
                                    }}
                                    key={path}
                                    to={path}
                                    aria-current='page'
                                    tw='block py-0.5 px-2 text-sm rounded-md text-DarkLiver hover:bg-LavenderWeb truncate max-w-[170px]'
                                >
                                    {title}
                                </Link>
                            )}
                        </div>
                    ))}
                </div>
            )}

            {modalType === 'tutorial_video' ? (
                <VideoPlayerOverlay videoId='670115629' isOpen={isModalOpen} setIsOpen={setModalOpen}/>
            ) : (
                <ModalDialog
                    isOpen={isModalOpen}
                    setIsOpen={() => setModalOpen(false)}
                    modalChildrenStyle={tw`w-full maxWidth[650px] mx-auto`}
                >
                    {modalType === 'join_class' && <JoinClassDialog setIsOpen={setModalOpen}/>}
                    {modalType === 'settings' && <SettingsDialog setIsOpen={setModalOpen}/>}
                    {modalType === 'subscription' && <Subscription setIsOpen={setModalOpen}/>}
                </ModalDialog>
            )}

        </div>
    );
};

export default DropdownMenu;
