import React, { useState } from 'react';
import tw, { css } from 'twin.macro';
import { styled } from 'twin.macro';
import BackgroundImage from 'lib/components/BackgroundImage';
import { Button } from 'components'
import LinesBackground from 'components/GettingStartedSlider/imgs/LinesBackground.png';
import WaveBackground from 'components/GettingStartedSlider/imgs/WaveBackground.png';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';



const CurrentAssignment = ({ assignment }) => {
    const background = css`background: linear-gradient(266deg, #C050FC 8.05%, #6B57DC 86.07%);`
    const firstVideoInAssignment = assignment?.videos[0];
    const Thumbnail = firstVideoInAssignment?.url_thumbnail;
    const navigate = useNavigate();
    const handleClick = () => navigate(`/video/${firstVideoInAssignment.id}`);
    let link = '/assignments/what-to-do-now';
    let progressStarted = Object.keys(assignment.progress.progress).length > 0;
    let playlistComplete = false;
    // due date logic
    let dueDate = moment.utc(assignment?.due).local();
    let daysDue = dueDate.diff(moment.now(), 'days');
    if (daysDue === 0) daysDue = 1;
    let daysNumber = Math.abs(daysDue);
    let dueDateCopy;
    if (daysDue < 0) {
        if (daysDue === -1) dueDateCopy = `${daysNumber} Day Overdue!`;
        else dueDateCopy = `${daysNumber} Days Overdue`;
    } else if (daysDue > 0) {
        if (daysDue === 1) dueDateCopy = `Due in ${daysNumber} Day`;
        else dueDateCopy = `Due in ${daysNumber} Days`;

    }


    let buttonCopy = progressStarted ? 'Continue' : 'START';
    // assignment complete 
    if (assignment?.progress?.completed === true) {
        buttonCopy = 'Review';
        playlistComplete = true;
        dueDateCopy = 'Playlist - Complete';
    }


    return (
        <div tw='rounded-lg relative flex w-full py-4' css={[background]}>
            <BackgroundImage image={LinesBackground} tw='mix-blend-soft-light' />
            <BackgroundImage image={WaveBackground} tw='mix-blend-soft-light' />
            <div tw='bg-[#76E6FC] h-[123px] md:h-[123px] w-[3px] absolute top-1/2 transform -translate-y-1/2' />
            <div tw='w-full'>
                <div tw='pt-4 pl-8'>
                    <div tw='bg-[#1C0057] border-white border-solid border-[1px] w-[165px] h-[30px] flex justify-center items-center rounded-lg' css={playlistComplete && tw`bg-[#17C8A8]`}>
                        <div tw='text-white text-center'>{dueDateCopy}</div>
                    </div>
                </div>

                <div tw='flex flex-col items-center w-full pt-10 '>
                    <div tw='text-white text-3xl text-center'>{assignment?.name}</div>
                    <Button tw='min-w-[220px] mt-14 z-50' variant={'white-dtc'}  onClick={() => navigate(link)}>{buttonCopy} Assignment</Button>
                </div>
            </div>

            <div tw='py-6 pr-6 w-full flex items-center justify-center'>
                <div
                    onClick={handleClick}
                    tw='w-full min-h-[200px] relative z-20 bg-contain xl:bg-cover bg-no-repeat rounded-none xl:rounded-lg'
                    css={[css`
                            background-image: url(${Thumbnail});
                            /* background-position: center; */
                            /* background-size: cover; */
                        `]}
                />
            </div>
        </div>
    );
};

export default CurrentAssignment;
