import React from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import { useUser } from 'state/User/UserState';
import { useAssignments } from 'state/Student/AssignmentsState';
import { useVideoAchievements } from 'state/Student/VideoStats';
import { useVideoStats } from 'state/Student/VideoStats';
import { getProfileInitial } from 'app/util';
import MXPSVGSmall from 'components/MXPSVGSmall/MXPSVGSmall';
import SilverRecord from 'static/images/records/DTC/silver.png';
import GoldRecord from 'static/images/records/DTC/gold.png';
import PlatinumRecord from 'static/images/records/DTC/platinum.png';
// place holder tell we have user creation
import UserImage from 'static/images/user.png';

const StatsOverViewCard = ({ stats, activity, childName, parent, isB2BAccount }) => {
    const user = useUser();
    const assignments = useAssignments();
    console.log("🚀 ~ StatsOverViewCard ~ assignments:", assignments)
    const achievements = useVideoAchievements();
    let achievementTotal = achievements.length;
    if (parent) achievementTotal = activity.length;
    const videoStats = useVideoStats();
    let videosWithVideoViews = Object.values(videoStats).filter(v => v.view_count > 0);
    if (parent) videosWithVideoViews = stats;
    let totalViews = 0;
    for (const video of videosWithVideoViews) {
        totalViews += video.view_count;
    }

    // find total records for each level
    let loadedActivity = activity ? (Object.keys(activity).length === 0 && activity.constructor === Object ? [] : activity) : null;
    let filterActivity = loadedActivity?.filter((a) => a.name === 'quiz:taken');
    filterActivity = filterActivity?.filter((a) => a.data.score === 100);
    filterActivity = filterActivity?.filter((a) => a.data.quizLevel !== 0);
    const silver = filterActivity.filter((stat) => stat.data.quizLevel === 1).length;
    const gold = filterActivity.filter((stat) => stat.data.quizLevel === 2).length;
    const platinum = filterActivity.filter((stat) => stat.data.quizLevel === 3).length;

    let initials = getProfileInitial(user);
    let statsTitle = 'My Stats';
    const noFirstName = user.user.first_name.length === 0;

    // find completed assignments 
    const totalCompletedAssignments = assignments.filter(assignment => assignment?.progress?.completed === true).length;
    const totalAssignments = assignments.length
    return (
        <div>
            <div tw='bg-[#3E257A] rounded-lg mx-auto pb-16 pt-20 px-4 relative flex flex-col '>
                <div tw='bg-gradient-to-b from-[#E637A8] to-[#E68137] w-20 h-1 absolute top-0 m-auto left-0 right-0 rounded-b-sm ' />
                <div
                    tw='text-white font-[300] text-2xl bg-OceanBlue border-white border-2 h-14 w-14 flex items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white mx-auto uppercase'
                    role='button'
                    tabIndex='0'
                >
                    <span tw='sr-only'>Open user menu</span>
                    {noFirstName ? <img src={UserImage} /> : initials}

                </div>
                <div tw='mt-5 text-white text-xl font-semibold text-center truncate max-w-[265px]'>{user.user.first_name}</div>
                <div tw='mt-16 text-white text-lg font-medium text-center'>{statsTitle}</div>
                <div tw='mt-5 text-[#cec2f0] text-sm text-center'>total records earned</div>
                <div tw='mt-5 flex justify-center'>
                    <div tw='max-w-[45px] flex flex-col items-center justify-center'>
                        <div tw='max-w-[45px]'>
                            <img tw='w-full h-auto' src={SilverRecord} alt='record' />
                        </div>
                        <div tw='text-base text-white font-medium mt-1'>{silver}</div>
                    </div>
                    <div tw='max-w-[45px] flex flex-col items-center justify-center ml-4'>
                        <div tw='max-w-[45px]'>
                            <img tw='w-full h-auto' src={GoldRecord} alt='record' />
                        </div>
                        <div tw='text-base text-white font-medium mt-1'>{gold}</div>

                    </div>
                    <div tw=' flex flex-col items-center justify-center ml-4'>
                        <div tw='max-w-[45px]'>
                            <img tw='w-full h-auto' src={PlatinumRecord} alt='record' />
                        </div>
                        <div tw='text-base text-white font-medium mt-1'>{platinum}</div>
                    </div>
                </div>
                <div tw='w-full flex justify-center mt-4'>
                    <div tw='bg-[#1C0057] w-3/4 h-[1px] box-shadow[0px 1px 1px rgba(255, 255, 255, 0.05)]' />
                </div>
                <div tw=' mx-auto '>

                    <div tw=' mx-auto'>
                        <div tw='mt-6 flex w-full justify-start'>
                            <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'>
                                <circle
                                    cx='24' cy='24' r='24' fill='#F9D256'
                                    opacity='.15' />
                                <g clipPath='url(#a)'>
                                    <path
                                        fill='#F9D256'
                                        d='M28.667 15h-9.334c-1.104 0-2.004.852-1.962 1.883.008.207.017.414.029.617H13c-.554 0-1 .418-1 .938 0 3.617 1.396 6.132 3.27 7.84 1.847 1.683 4.097 2.53 5.755 2.96.975.254 1.642 1.016 1.642 1.782 0 .816-.709 1.48-1.58 1.48H20c-.738 0-1.333.559-1.333 1.25S19.262 35 20 35h8c.738 0 1.333-.559 1.333-1.25S28.738 32.5 28 32.5h-1.087c-.871 0-1.58-.664-1.58-1.48 0-.766.663-1.532 1.642-1.782 1.662-.43 3.912-1.277 5.758-2.96C34.604 24.57 36 22.054 36 18.437c0-.52-.446-.938-1-.938h-4.4c.012-.203.02-.406.03-.617.04-1.031-.86-1.883-1.963-1.883Zm-14.63 4.375h3.517c.38 3.52 1.217 5.871 2.163 7.445-1.038-.43-2.117-1.035-3.05-1.886-1.334-1.215-2.417-2.97-2.625-5.559h-.005Zm17.3 5.559c-.933.851-2.012 1.457-3.05 1.886.946-1.574 1.784-3.925 2.163-7.445h3.517c-.213 2.59-1.296 4.344-2.625 5.559h-.005Z' />
                                </g>
                                <defs>
                                    <clipPath id='a'>
                                        <path fill='#fff' d='M12 15h24v20H12z' />
                                    </clipPath>
                                </defs>
                            </svg>
                            <div tw='ml-4'>
                                <div tw='text-base text-white font-medium'>{achievementTotal}</div>
                                <div tw='mt-1 text-[#cec2f0] text-sm whitespace-nowrap'>total achievements</div>
                            </div>
                        </div>
                    </div>
                    {isB2BAccount &&
                        <div tw=' mx-auto'>
                            <div tw='mt-6 flex w-full justify-start'>
                                <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle opacity="0.15" cx="25.1351" cy="25.6824" r="25.1351" fill="#E73CA2" />
                                    <rect x="23.0397" y="30.9192" width="13.6149" height="3.14189" rx="1.57095" stroke="#E73CA2" stroke-width="1.57095" />
                                    <rect x="15.709" y="23.588" width="20.9459" height="3.14189" rx="1.57095" stroke="#E73CA2" stroke-width="1.57095" />
                                    <rect x="15.709" y="16.2568" width="20.9459" height="3.14189" rx="1.57095" stroke="#E73CA2" stroke-width="1.57095" />
                                    <path d="M19.3741 31.5831C20.0723 31.9862 20.0723 32.994 19.3741 33.3971L17.0177 34.7576C16.3195 35.1607 15.4467 34.6568 15.4467 33.8506L15.4467 31.1296C15.4467 30.3234 16.3195 29.8195 17.0177 30.2226L19.3741 31.5831Z" stroke="#E73CA2" stroke-width="1.57095" />
                                </svg>

                                <div tw='ml-4'>
                                    <div tw='text-base text-white font-medium'>{totalCompletedAssignments} of {totalAssignments}</div>
                                    <div tw='mt-1 text-[#BCB5CD] text-sm whitespace-nowrap'>completed assignments</div>
                                </div>
                            </div>
                        </div>
                    }


                    <div tw='max-w-none min-w-0 mx-auto'>
                        <div tw='mt-8 flex w-full justify-start '>
                            <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' fill='none'>
                                <circle
                                    cx='24' cy='24' r='24' fill='#22C3E1'
                                    opacity='.15' />
                                <path
                                    fill='#22C3E1'
                                    d='M20.51 14h6.98c.232 0 .41 0 .566.015 1.108.109 2.015.775 2.4 1.672H17.544c.385-.897 1.292-1.563 2.4-1.672.154-.015.332-.015.566-.015Zm-2.2 2.723c-1.39 0-2.53.84-2.91 1.954a2.886 2.886 0 0 0-.024.07c.398-.12.813-.2 1.232-.253 1.08-.139 2.446-.139 4.032-.139h6.892c1.586 0 2.951 0 4.032.139.42.054.834.132 1.232.253a1.908 1.908 0 0 0-.023-.07c-.38-1.114-1.52-1.954-2.911-1.954H18.31Z' />
                                <path
                                    fill='#22C3E1' fillRule='evenodd'
                                    d='M27.328 19.542h-6.656c-3.374 0-5.062 0-6.01.987-.948.987-.725 2.511-.278 5.56l.422 2.892c.35 2.391.525 3.587 1.422 4.303.898.716 2.22.716 4.867.716h5.81c2.646 0 3.97 0 4.867-.716.897-.716 1.072-1.912 1.422-4.303l.422-2.891c.447-3.05.67-4.574-.278-5.561-.948-.987-2.636-.987-6.01-.987Zm-.747 8.252c.559-.346.559-1.242 0-1.588l-3.371-2.09c-.543-.337-1.21.101-1.21.794v4.18c0 .693.667 1.13 1.21.794l3.371-2.09Z'
                                    clipRule='evenodd' />
                            </svg>
                            <div tw='ml-4'>
                                <div tw='text-base text-white font-medium'>{totalViews}</div>
                                <div tw='mt-1 text-[#cec2f0] text-sm whitespace-nowrap'>total video views</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

StatsOverViewCard.propTypes = {
    anyProp: PropTypes.any
};

export default StatsOverViewCard;