import React from 'react';
import tw, { css } from 'twin.macro';
import { useOnboardState, useProduct } from 'state/User/UserState';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
const FooterContainer = tw.footer`
    relative
    bg-[#342974] 
    flex justify-center items-center

    text-[#aaa] 
    text-[18px] 
    text-center 

    py-5
`;


const Footer = ({ className, hide }) => {
    const onboard = useOnboardState();
    const { show_trial_banner } = onboard;
    const timesTableProduct = useProduct('times-tables');
    const location = useLocation();
    const isSmall = useMedia('(max-width: 640px)');

    const isVideoPage= location.pathname.startsWith('/video');
    const isSettingsPage = location.pathname.startsWith('/settings');

    let style = tw``;
    if (!timesTableProduct) style = tw`mt-16 md:mt-[100px]`;
    if(isVideoPage) style = tw``;
    if(isSettingsPage && isSmall) style = tw``;
    if (!show_trial_banner) style = tw``;


    const copyright = `Copyright © ${new Date().getFullYear()} Muzology, LLC. All rights reserved.`;
    if (hide)
        return null;
    return (
        <FooterContainer className={className} css={[style]}>
            <div className='footer-message'>{copyright}</div>
        </FooterContainer>
    );
};

export default Footer;
