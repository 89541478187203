import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'twin.macro';
import { Listbox } from '@headlessui/react';



const GradeListBox = ({ timesTableProduct, onSelectItem, selected }) => {
    const [selectedItem, setSelectedItem] = useState(null);

    const DTCGrades = [
        { label: 'K-2', value: 'K-2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '6' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10-12+', value: '10-12+' }
    ];

    const TimesTablesGrades = [
        { label: 'PreK', value: 'PreK' },
        { label: 'K', value: 'K' },
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '6' },
        { label: '8', value: '8' },
        { label: '9+', value: '9+' }
    ];

    const items = timesTableProduct ? TimesTablesGrades : DTCGrades;

    React.useEffect(() => {
        if (selected) {
            // find the selected state
            const found = items.find(grade => grade.value === selected);
            if (found) {
                setSelectedItem(found);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const handleChanged = (item) => {
        setSelectedItem(item);
        if (onSelectItem)
            onSelectItem(item.value);
    };

    return (
        <Listbox value={selectedItem} onChange={handleChanged}>
            <div tw='relative mt-1 w-full' id='grades-dropdown'>
                <Listbox.Button
                    tw=' w-full flex items-center justify-between font-Poppins pl-2  py-2 mt-1 sm:mt-2  text-[#000] bg-white border-[#D1D5DB] border-[1.5px] border-solid rounded-md focus:ring-opacity-40 min-h-[32px] sm:min-h-[50px]'
                    style={{ boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 2px 5px 0px rgba(0, 0, 0, 0.08)' }}
                >
                    <span tw='block truncate text-left text-base font-normal text-[#303030]'>
                        {selectedItem ? selectedItem?.label : 'Please select grade'}
                    </span>
                    <span tw='flex items-center pr-2 pointer-events-none'>
                        <svg
                            width='12' height='6' viewBox='0 0 12 6' fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M0.51593 0.415704C0.951931 0.0366037 1.55893 0.00685368 2.09193 0.415704L5.99993 3.60065L9.90793 0.415704C10.4409 0.00685368 11.0489 0.0366037 11.4819 0.415704C11.9179 0.793954 11.8899 1.43315 11.4819 1.78845C11.0759 2.14375 6.78693 5.61515 6.78693 5.61515C6.6848 5.70513 6.56238 5.77669 6.427 5.82557C6.29161 5.87444 6.14602 5.89963 5.99893 5.89963C5.85184 5.89963 5.70625 5.87444 5.57087 5.82557C5.43548 5.77669 5.31307 5.70513 5.21093 5.61515C5.21093 5.61515 0.923931 2.14375 0.51593 1.78845C0.10693 1.43315 0.0799304 0.793954 0.51593 0.415704Z'
                                fill='#5A5353'
                            />
                        </svg>
                    </span>
                </Listbox.Button>

                <Listbox.Options
                    tw='absolute z-20 w-full mt-1 overflow-auto text-base bg-white border-[#D1D5DB] border-[1.5px] border-solid rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm '
                    style={{ height: '230px' }}
                >
                    {items.map((item) => (
                        <Listbox.Option
                            key={item.value}
                            value={item}
                            tw='text-DarkLiver select-none relative py-2 px-4 cursor-pointer hover:bg-GhostWhite focus:outline-none focus:ring-2 focus:ring-GhostWhite focus:ring-opacity-50'
                        >
                            {item.label}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </div>
        </Listbox>
    );
};

GradeListBox.propTypes = {
    anyProp: PropTypes.any
};

export default GradeListBox;

