import React from 'react';
import tw, { styled } from 'twin.macro';
import { useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';
import { useAccount } from 'state/User/UserState';
import Tabs from './Tabs';

export const OverDueDecorator = ({ overDueAssignments }) => {
    return (
        <div tw='absolute -top-3 -right-2 rounded-full bg-OverdueRed z-10 text-white text-base md:text-2xl font-extrabold py-0 md:py-1 px-2 md:px-3 '>
            {overDueAssignments}
        </div>
    );
};

const StyledTabNavigator = styled.div(({ $variant, index }) => [
    tw`flex`,
    $variant === 'enclosed' && tw`space-x-2 lg:space-x-6 my-4 border-0  xsm:border-b-[3px] border-white text-white justify-start`,
    $variant === 'underline' && tw`max-w-[238px] border-b-2 border-white`,
    $variant === 'dtc-child' && tw`flex border-b-0 border-white`
]);

const RoundedWrapper = tw.div`rounded-tl-md rounded-tr-md overflow-hidden`;

const TabNavigator = ({ items, activeTabHandler, activeTab, variant, parent, activeTabPath }) => {
    // console.log("🚀 ~ file: TabNavigator.js:26 ~ TabNavigator ~ variant:", variant)
    const navigate = useNavigate();

    const isPhone = useMedia('(max-width: 1280px)');
    const { is_parent_account } = useAccount(); 

    const handleTabClick = (index, path) => {
        if(activeTabHandler)
            activeTabHandler(index); // Change the active tab
        navigate(path); // Navigate to the route of the clicked tab
    };

    return (
        is_parent_account ? (
            <RoundedWrapper>
                <StyledTabNavigator $variant={variant} data-testid='tab-nav'>
                    {items.map((item, index) => {
                        let isActive;
                        if( activeTabPath ) {
                            isActive = activeTabPath === item.path;
                        } else {
                            isActive = activeTab === index;
                        }

                        return (
                            <Tabs
                                path={item.path}
                                variant={variant}
                                key={index}
                                activeTabHandler={() => handleTabClick(index, item.path)}
                                activeTab={activeTab}
                                index={index}
                                isSelected={activeTab === index}
                                isPhone={isPhone}
                            >
                    
                                {isPhone && !parent ? (
                                    isActive ? (
                                        <>
                                            <div tw='bg-gradient-to-b from-[#46A6F8] to-[#B15AFE] w-20 h-[3px] absolute top-0 m-auto left-0 right-0 rounded-b-sm ' />
                                            <h1 tw='text-sm sm:text-base text-center w-[108px] my-2'>
                                                <span>My</span>
                                                <br />
                                                <span>{item.name.split(' ').slice(1).join(' ')}</span>
                                            </h1> 
                                        </>
                                    ) : (
                                        <h2 tw='text-sm sm:text-base text-center w-[108px] my-2'>
                                            <span>My</span>
                                            <br />
                                            <span>{item.name.split(' ').slice(1).join(' ')}</span>
                                        </h2>
                                    )
                                ) : (
                                    isActive ? (
                                        <>
                                            <div tw='bg-gradient-to-b from-[#46A6F8] to-[#B15AFE] w-20 h-[3px] absolute top-0 m-auto left-0 right-0 rounded-b-sm ' />
                                            <h1 tw='text-[14px] lg:text-lg font-medium text-center '>{item.name}</h1>
                                        </>
                                    ):( <h2 tw='text-[14px] lg:text-lg font-semibold lg:font-medium text-center '>{item.name}</h2>))}

                                {item.decorator}
                            </Tabs>
                        );
                    })}
                </StyledTabNavigator>
            </RoundedWrapper>
        ):(
            <StyledTabNavigator $variant={variant} data-testid='tab-nav'>
                {items.map((item, index) => {
                    let isActive;
                    if( activeTabPath ) {
                        isActive = activeTabPath === item.path;
                    } else {
                        isActive = activeTab === index;
                    }
                    return (
                        <Tabs
                            path={item.path}
                            variant={variant}
                            key={index}
                            activeTabHandler={activeTabHandler}
                            activeTab={activeTab}
                            index={index}
                        >
                            {activeTab ? <h1 tw='text-lg font-bold'>{item.name}</h1> : <h2 tw='text-lg font-bold'>{item.name}</h2>}
                            {item.decorator}
                        </Tabs>
                    );
                })}
            </StyledTabNavigator>
        )
    );
};
export default TabNavigator;
