import React from 'react';
import tw from 'twin.macro';

const Header = ({ timesTableProduct, teacher, header }) => {

    const SubheaderCopy = timesTableProduct ? 'Let’s Go!' : 'One Last Thing.';

    return (
        <div
            tw='flex flex-col items-center w-full max-w-[100%] sm:max-w-[405px] lg:max-w-[405px] mr-auto lg:ml-auto pl-0 md:pl-2.5 lg:pl-0'
            css={[timesTableProduct && tw`sm:max-w-[300px] lg:max-w-[455px]`]}>
            {(teacher || timesTableProduct) && <div tw='hidden md:block font-normal text-xl text-[#4E4E4E] w-full text-left '>{SubheaderCopy}</div>}
            <div
                tw='pt-1.5 w-full text-[#303030] font-semibold text-xl sm:text-xl md:text-2.5xl lg:text-[40px] leading-[1.3] md:[line-height: 47px] max-w-[350px] sm:max-w-full mr-auto '
                css={[timesTableProduct && tw`text-center sm:text-left ml-0 max-w-[100%]`]}
            >
                {header}
            </div>
        </div>
    );
};

export default Header;