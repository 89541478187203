import React, { useMemo } from 'react';
import { css } from 'twin.macro';
import { useVideoStats } from 'state/Student/VideoStats';
import { useParent } from 'state/User/UserState.js';
import { useChild } from 'state/User/UserState.js';
import { addVideosToPlaylist, handleCreateNewPlaylistModal, handlePlaylistDropdown, removeVideoFromPlaylist, usePlaylistState } from 'state/PlaylistState';
import { useLocation, useNavigate } from 'react-router-dom';
import TeacherInfo from './TeacherInfo/TeacherInfo';
import StudentInfo from './StudentInfo/StudentInfo';
import ParentInfo from './ParentInfo/ParentInfo';
import DTCInfo from './DTCInfo/DTCInfo';

const secondaryTextCss = css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-weight: 500;
    font-family: Avenir, serif;
    font-size: 14px;
`;
const VideoCardInfo = (props) => {
    let { video, teacher, showTitle, onClick, setSelectedStudent, selectedStudent, listBoxItems, tour, dtc } = props;
    const parent = useParent();
    let videoStats = useVideoStats(video?.id);
    if (parent)
        videoStats = video?.stats;
    const child = useChild();
    const [{ playlist, isPlaylistDropdownOpen }, playlistDispatch] = usePlaylistState();
    const selectedVideo = useMemo(() => {
        return playlist.selectedVideos.find((playlistVideo) => playlistVideo.videoId === video?.id);
    }, [playlist?.selectedVideos, video?.id]);
    const location = useLocation();
    const navigate = useNavigate();

    const handleAddingVideoToPlaylist = (event) => {
        // prevent clicking through to the parent
        event.stopPropagation();

        if (location.pathname !== '/videos') navigate('/videos');

        if (!playlist.name) {
            handleCreateNewPlaylistModal(playlistDispatch, true);
        }

        handlePlaylistDropdown(playlistDispatch, true);
        // if video has not been selected then allow user to add to playlist
        if (!selectedVideo) {
            addVideosToPlaylist(playlistDispatch, { videoId: video.id, videoName: video.title, videoThumbnail: video.url_thumbnail });
        } else {
            handleCreateNewPlaylistModal(playlistDispatch, false);
            // if the video has been selected when they click the button again it will remove from the playlist
            removeVideoFromPlaylist(playlistDispatch, selectedVideo);
        }
    };

    // view count text
    let viewsText = null;
    const showViewCount = props.showViewCount ?? false;
    if (!props.teacher) {
        viewsText = 'Your Views: 0';
        if (showViewCount && videoStats) {
            viewsText = `Your Views: ${videoStats?.view_count ?? 0}`;
        }
    }



    const HandleUserTypeInfo = () => {

        if (teacher)
            return (
                <TeacherInfo
                    onClick={onClick}
                    video={video}
                    view_count={videoStats?.view_count ?? 0}
                    videoStats={videoStats}
                    showTitle={showTitle}
                    handleAddingVideoToPlaylist={handleAddingVideoToPlaylist}
                    isPlaylistDropdownOpen={isPlaylistDropdownOpen}
                    selectedVideo={selectedVideo}
                    tour={tour}
                    isHovering={props.isHovering}

                />
            );
        // if (child || parent)
        return (
            <DTCInfo
                onClick={onClick}
                view_count={videoStats?.view_count ?? 0}
                video={video}
                showTitle={showTitle}
                videoStats={videoStats}
                props={props}
                isHovering={props.isHovering}
                dtc={dtc}
                parent={parent}
            />
        );

        // return <StudentInfo secondaryText={secondaryText} video={video} showTitle={showTitle} videoStats={videoStats} />;
    };
    return HandleUserTypeInfo();
};

export default VideoCardInfo;
